import * as API from "@app/API";
import { get, isEmpty } from "../lodash";
import { NOOP } from "./common";
import mime from "mime-types";
import { Buffer } from "buffer";

export function addLoader(): void {
  if (!!document && document.body) {
    document.body.classList.add("loading-pointer");
  }
}

export function bytesToSize(bytes: number): string {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export const downloadBackup = (
  backupId: string,
  part: number,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP,
  onDownloadProgress: (progressEvent: any) => void = NOOP
) => {
  addLoader();

  API.downloadBackup(backupId, part, onDownloadProgress)
    .then((response: any) => {
      // Get content
      const blob: Blob = response.data;
      let filename = `${backupId}${part ? `_part_${part}` : ""}`;
      filename = getFilenameFromResponse(response, filename, blob.type);
      // Download content
      downloadContent(blob, filename);

      removeLoader();
      onSuccess();
    })
    .catch(() => {
      removeLoader();
      onFailure();
    });
};

export const downloadDocument = (
  request: {
    documentId: string;
    versionNumber?: number;
    title?: string;
    mimeType?: string;
  },
  onFailure: () => void = NOOP,
  onSuccess: () => void = NOOP
) => {
  const { documentId, versionNumber, title, mimeType } = request;
  addLoader();

  API.download(documentId, versionNumber)
    .then((response) => {
      // Get content
      const content = getContentFromResponse(response);
      const filename = getFilenameFromResponse(response, title, mimeType);
      // Download content
      downloadContent(content, filename);

      removeLoader();
      onSuccess();
    })
    .catch(() => {
      removeLoader();
      onFailure();
    });
};

export const downloadFile = (
  request: {
    fileId: string;
    mimeType?: string;
  },
  onFailure: () => void = NOOP,
  onSuccess: () => void = NOOP
) => {
  const { fileId, mimeType } = request;

  addLoader();

  API.downloadFile(fileId)
    .then((response) => {
      // Get content
      const content = getContentFromResponse(response);
      const filename = getFilenameFromResponse(response, undefined, mimeType);
      // Download content
      downloadContent(content, filename);

      removeLoader();
      onSuccess();
    })
    .catch(() => {
      removeLoader();
      onFailure();
    });
};

export function downloadContent(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.addEventListener("click", (e) => e.stopImmediatePropagation());
  link.click();
  document.body.removeChild(link);

  // For Edge browser
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 1000);
}

export function getContentFromResponse(response: any) {
  const data = get(response, "data") || {};
  const content = data.content || data.bytes || data.body;
  return !isEmpty(content) ? Buffer.from(content, "base64") : data;
}

const fileExtensionRegex = /\.[0-9a-z]+$/i;

export function getFileExtension(filename: string) {
  return ((filename || "").match(fileExtensionRegex) || [])[0];
}

export function getFileExtensionFromFile(file: File) {
  const extension = getFileExtension(file?.name) || "";
  return extension.toLowerCase();
}

export function getFilenameFromResponse(
  response: any,
  title?: string,
  mimeType?: string
) {
  // Get filename using the document title or the current filename
  const data = get(response, "data") || {};
  const name =
    data.name ||
    data.filename ||
    response.headers["x-suggested-filename"] ||
    "";
  let extension = getFileExtension(name);
  if (!extension && !isEmpty(mimeType)) {
    const mimeExtension = mime.extension(mimeType); // the method returns `false` if the extension is not found.
    if (`${mimeExtension}` !== "false") {
      extension = `.${mimeExtension}`;
    }
  }
  let filename = !isEmpty(title)
    ? title.replace(/\W+/gi, "_") // let's only support numbers/letters/underscores in a filename
    : replaceFileExtension(name, "");
  return !!extension ? `${filename}${extension}` : filename;
}

export function removeLoader(): void {
  if (!!document && document.body) {
    document.body.classList.remove("loading-pointer");
  }
}

export function removeFileExtension(filename: string) {
  return replaceFileExtension(filename, "");
}

export function replaceFileExtension(filename: string, replacement: string) {
  return (filename || "").replace(fileExtensionRegex, replacement);
}

// returns true if the provided input is a File instance.
export function isFile(input: any) {
  return "File" in window && input instanceof window.File;
}
