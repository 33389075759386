import axios from "axios";
import constants from "../utils/constants";
import get from "lodash/get";
import { ISortInfo } from "../types/IPageable";
import { Company } from "@app/entities/companies";
import { UpdateUserProfileRequest } from "../types-business/Profile";
import { Api } from "./_base";

/**
 * API call to fetch a list of user accounts by company Id
 * @param companyId
 */
export const fetchUserAccounts = (
  companyId: number,
  page: number = 0,
  sortInfo?: ISortInfo,
  search?: string,
  size: number = 20
): Promise<any> => {
  const sort = get(sortInfo, "hasSort", false)
    ? `&sort=${sortInfo.sortBy},${sortInfo.isDescending ? "DESC" : "ASC"}`
    : "";
  search = !!search ? `&search=${search}` : "";
  return Api.execute({
    url: `/api/proxy/ms-profile/v1/accounts/${companyId}/users?page=${page}&size=${size}${sort}${search}`,
  });
};

/**
 * API call to create a single user account
 * @param companyId
 * @param userId
 * @param update
 */
export const createUserAccount = (companyId: number, accountInfo: any) => {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts/${companyId}/users`,
    accountInfo,
    {
      withCredentials: true,
    }
  );
};

/**
 * API call to export users
 * @param companyId
 */
export const exportUsers = (companyId: number) => {
  return Api.execute({
    url: `/api/proxy/ms-profile/v1/companies/${companyId}/profiles/export`,
  });
};

/**
 * API call to update a single user account
 * @param companyId
 * @param userId
 * @param update
 */
export const updateUserAccount = (
  companyId: number,
  userId: number,
  update: any
) => {
  return axios.put(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts/${companyId}/users/${userId}`,
    update,
    {
      withCredentials: true,
    }
  );
};

export const updateUserAccountStatus = (
  companyId: number,
  userId: number,
  status: string
) => {
  return axios.put(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts/${companyId}/users/${userId}/${status}`,
    {},
    {
      withCredentials: true,
    }
  );
};

export const resendUserAccountInvite = (username: string) => {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts/verify`,
    username,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "text/plain", // ensure that the req.body is sent NOT as json to the BE server via the proxy
      },
    }
  );
};

export const fetchCompanyProfile = (companyId: string = "me") => {
  return axios.get(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/companies/${companyId}`,
    {
      withCredentials: true,
    }
  );
};

export const updateCompanyProfile = (
  company: Company,
  companyId: string = ""
) => {
  const cId = companyId ? `/${companyId}` : ""; // company id is only needed when it's not a change for the user's company, and endpoint url should not have trailing slash if companyId is not present
  return axios.put(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/companies${cId}`,
    company,
    {
      withCredentials: true,
    }
  );
};

export const createCompanyProfile = (company: Company) => {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/companies`,
    company,
    {
      withCredentials: true,
    }
  );
};

export const activateUserAccount = (
  userId: number,
  token: string,
  password: string
) => {
  return axios.put(
    `${constants.BASE_URL}/api/accounts/verify`,
    {
      userId,
      token,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

export const activateFederatedUserAccount = (userId: number, token: string) => {
  return axios.put(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts/federated/verify`,
    {
      userId,
      token,
    },
    {
      withCredentials: true,
    }
  );
};

export const changePassword = (
  userId: number,
  token: string,
  password: string
) => {
  return axios.put(
    `${constants.BASE_URL}/api/accounts/change-password`,
    {
      userId,
      token,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

export const updateMyProfile = (
  updateProfileRequest: UpdateUserProfileRequest
) => {
  return axios.put(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts`,
    updateProfileRequest,
    {
      withCredentials: true,
    }
  );
};

export const sendChangePassword = (email: string) => {
  return axios.put(
    `${constants.BASE_URL}/api/accounts/send-change-password`,
    {
      email,
    },
    {
      withCredentials: true,
    }
  );
};

export const createCompanyAccount = (account: any) => {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/accounts`,
    account,
    {
      withCredentials: true,
    }
  );
};

export const validateOrCreateUser = (
  userInfo: {
    email: string;
    firstName: string;
    lastName: string;
    title?: string;
  },
  companyId: number | string
) => {
  return Api.execute({
    url: `/api/proxy/ms-profile/v1/accounts/${companyId}/users`,
    method: "PUT",
    data: userInfo,
  });
};
