import ticketDetails from "./ticket-details";
import ticketList from "./ticket-list";
import ticketTypes from "./ticket-types";
import ticketHistory from "./ticket-history";

import { combineReducers } from "redux";
import ticketMetrics from "./ticket-metrics";

export default combineReducers({
  ticketDetails,
  ticketHistory,
  ticketList,
  ticketTypes,
  ticketMetrics,
});
