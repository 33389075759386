import moment from "moment-timezone";
import isFinite from "lodash/isFinite";
import queryString, { ParsedQuery } from "query-string";
import difference from "lodash/difference";
import React, { Children } from "react";
import isString from "lodash/isString";
import { get } from "../lodash";
export * from "./common";
export * from "./login";
export * from "./error-response";
export * from "./file";
export * from "./redirect-url";
export * from "./dom";
export * from "./query-string";

/**
 * Given a string, returns the string with any regex characters escaped
 * @param string
 */
export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const getPageNumber = (queryObj: ParsedQuery<String>) =>
  queryObj.page ? +queryObj.page - 1 : 0;
export const getPageSize = (queryObj: ParsedQuery<String>) =>
  queryObj.size ? +queryObj.size : 20;

/**
 * Returns the page-number and page-size values from the location
 * @param location
 */
export const getPaginationData = (location: Location = window.location) => {
  const qs = queryString.parse(location.search);
  const page = getPageNumber(qs);
  const size = getPageSize(qs);
  return {
    page,
    size,
  };
};

/**
 * Function to check if the string ends with more than one space.
 * @param value
 */
export const hasInputManyTrailingSpaces = (value) => {
  const validateRegEx = new RegExp(/\s\s+$/g);
  return isString(value) && value.match(validateRegEx);
};

/**
 * Function to check if the first array parameter is included in the second array
 * @param subset
 * @param superset
 */
export function isArrayIncluded(subset: any[], superset: any[]) {
  return difference(subset, superset).length === 0;
}

/**
 * Determines if a date is after to another one
 * @param nextDate The date to check
 * @param previousDate The previous date
 * @param precision The precision to make the comparisson
 */
export function isDateAfter(
  nextDate: Date,
  previousDate: Date,
  precision: moment.unitOfTime.StartOf = "seconds"
) {
  return moment(nextDate).isAfter(moment(previousDate), precision);
}

/**
 * Function to check if the given value is a number or a number string
 * @param value
 * @returns
 */
export const isNumberValue = (value) =>
  !isNaN(value) && isFinite(parseFloat(value));

/**
 * Takes a function that returns a promise (such as an async xhr call), and returns a new function
 * that ensures only the last invoked caller gets handled. Handy for typeahead scenarios where multiple
 * xhr requests are made, but we only want to handle the last one (via then/catch)
 * @param operation
 */
export const lastPromise = (
  operation: (...args: any[]) => Promise<any>
): (() => Promise<any>) => {
  var latestPromise = null;
  var pending = { then: () => {} };

  return function () {
    var promiseForResult = operation.apply(this, arguments);
    latestPromise = promiseForResult;

    const resolve = (value) =>
      latestPromise === promiseForResult ? value : pending;

    const reject = (reason) => {
      if (latestPromise === promiseForResult) {
        throw reason;
      } else {
        return pending;
      }
    };

    return promiseForResult.then(resolve, reject);
  };
};

/**
 * Moves the position of an item in an array
 * @param items
 * @param fromIndex
 * @param toIndex
 */
export function move(items: any[], fromIndex: number, toIndex: number) {
  const reorderedItems = [...items]; // new array
  const [removed] = reorderedItems.splice(fromIndex, 1);
  reorderedItems.splice(toIndex, 0, removed);
  return reorderedItems;
}

/**
 * Utility function for rendering specified SubComponents, as seen in various Akorda components like `<Card/>`, `<PageLayout/>`, and `<Toolbox/>`
 * @param props
 * @param componentNames
 */
export const processSubcomponents = (
  children,
  componentNames = [],
  props = {}
) => {
  return Children.map(children, (child: any) => {
    return !!child &&
      componentNames.includes(get(child, "type.displayName", null))
      ? React.cloneElement(child, props)
      : null;
  });
};

/**
 * Convenience helper for resolving a company id either from the query string or from user state.
 * If there's a company id in the query string, we'll use that (for Akorda Admin use cases), otherwise
 * resolve from redux state using a selector.
 * @param state
 */
export const resolveCompanyId = (reduxState?: any) => {
  const qs = queryString.parse(window.location.search);
  const getMyCompanyId = (state) =>
    get(state, "login.userContext.company.companyId");
  if (qs.companyId) {
    return +qs.companyId;
  } else if (!!reduxState) {
    return getMyCompanyId(reduxState);
  } else {
    const ReduxStore = require("../../redux/store").ReduxStore;
    if (!!ReduxStore.store) {
      return getMyCompanyId(ReduxStore.store.getState());
    }
  }
  return null;
};
