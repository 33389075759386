import { useAsync } from "@app/utils/react-async-hook";
import { Document } from "../document";
import {
  getLsrDocument,
  getLsrDocumentTypes,
  getLsrOrganization,
} from "@app/API";
import { useLegalSifterReview } from "./hooks";

export enum SiftStatus {
  Sifting = "SIFTING",
  Sifted = "SIFTED",
  Queued = "QUEUED",
  Uploading = "UPLOADING",
  Failed = "FAILED",
  None = "NONE",
}

export interface LsrDocumentState {
  status: SiftStatus;
  documentTypeUuid: string;
  uuid: string;
  document?: LsrDocument;
}

export interface LsrDocument {
  "sifted-document": string;
  name: string;
  "current-export": string;
  "sifted-time": string;
  "document-type": string;
  edited: boolean;
  "external-id": string;
  "created-by": string;
  status: string;
  notes: string;
  "org-uuid": string;
  uuid: string;
  "original-document": string;
}

export interface LsrOrganization {
  "org-uuid": string;
  name: string;
  "sifter-settings": string;
  "sifter-trainer-report": string;
  "all-sifts-report": string;
}

export enum LsrUserRole {
  DocumentSifter = "document-sifter",
  SifterSettingsAdmin = "sifter-settings-admin",
  ClientAdmin = "client-admin",
}

export interface LsrUser {
  email: string;
  name: string;
  initials: string;
  active: boolean;
  roles: LsrUserRole[];
  "job-function": string;
  "org-uuid": string;
}

export function getLsrState(document: Document): LsrDocumentState | null {
  return document?.lsr as LsrDocumentState;
}

export function getSiftStatus(document: Document): SiftStatus {
  return getLsrState(document)?.status ?? SiftStatus.None;
}

export function isQueuedForSifting(document: Document): boolean {
  return getSiftStatus(document) === SiftStatus.Queued;
}

export function isUploadingForSifting(document: Document): boolean {
  return getSiftStatus(document) === SiftStatus.Uploading;
}

export function getLsrPlaybookId(document: Document): string {
  return getLsrState(document)?.documentTypeUuid ?? "";
}

export function getLsrDocumentId(document: Document): string {
  return getLsrState(document)?.uuid ?? "";
}

export function isSifting(document: Document): boolean {
  return (
    getSiftStatus(document) === SiftStatus.Sifting ||
    isQueuedForSifting(document) ||
    isUploadingForSifting(document)
  );
}

export function isSifted(document: Document): boolean {
  return getSiftStatus(document) === SiftStatus.Sifted;
}

export function getSiftedDocumentUrl(document: Document): string {
  return getLsrState(document)?.document?.["sifted-document"] || "";
}

export function getSiftedDocumentExportUrl(document: Document): string {
  return getLsrState(document)?.document?.["current-export"] || "";
}

export function useLsrDocument(lsrDocumentUuid): any {
  const asyncResponse = useAsync<any>(getLsrDocument, [lsrDocumentUuid], {
    setLoading: (state) => {
      return { ...state, loading: true, status: "loading" } as any; // keep previous results while loading new results
    },
  });

  return asyncResponse;
}

export function useLsrDocumentTypes(): any {
  const { result: documentTypes, status } = useAsync<any>(
    getLsrDocumentTypes,
    []
  );
  return [documentTypes, status];
}

export function getSifterSettingsUrl(lsrOrganization: LsrOrganization): string {
  return lsrOrganization?.["sifter-settings"] || "";
}

export function getSifterTrainerReportUrl(
  lsrOrganization: LsrOrganization
): string {
  return lsrOrganization?.["sifter-trainer-report"] || "";
}

export function getAllSiftsReportUrl(lsrOrganization: LsrOrganization): string {
  return lsrOrganization?.["all-sifts-report"] || "";
}

export function useLsrOrganization(): any {
  const [, lsrOrganizationId] = useLegalSifterReview();
  const { result: lsrOrganization, status } = useAsync<any>(
    getLsrOrganization,
    [lsrOrganizationId]
  );
  return [lsrOrganization, status];
}
