import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import constants from "../../utils/constants";
import {
  TOGGLE_COLLAPSE_CARD_PANEL,
  START_CLAUSE_ACTION,
  SELECT_CLAUSE,
  DESELECT_CLAUSE,
  SET_CLAUSE_FILTER,
  RESET_CONTRACT_STATE,
} from "../actions/types";
import {
  CHANGE_NEGOTIATION,
  FINALIZE_DOCUMENT,
  PROCESS_CLAUSE_ISSUE,
} from "@app/redux/data/review";

export interface State {
  // the id of the currently selected clause
  selectedClauseId: number;
  // whether the left column containing the card list/card details is collapsed
  isCardPanelCollapsed: boolean;
  // the filter for the clause cards
  clauseFilter: string;
  // the review action for a clause that is being processed
  activeReviewAction: string;
}

export const initialState: State = {
  activeReviewAction: null,
  clauseFilter: constants.CLAUSE_FILTER.ALL,
  isCardPanelCollapsed: false,
  selectedClauseId: null,
};

export default handleActions(
  {
    // reverts the contract state back to the initial state
    [RESET_CONTRACT_STATE]: () => ({
      ...initialState,
    }),
    // sets the review action so that we can show the card transition
    [START_CLAUSE_ACTION]: (state: any, action) => {
      return {
        ...state,
        activeReviewAction: action.payload,
      };
    },
    [PROCESS_CLAUSE_ISSUE]: (state: any, action) => {
      return handle(state, action, {
        finish: (s) => ({
          ...s,
          activeReviewAction: null,
        }),
      });
    },
    [TOGGLE_COLLAPSE_CARD_PANEL]: (state) => {
      return {
        ...state,
        isCardPanelCollapsed: !state.isCardPanelCollapsed,
      };
    },
    [SELECT_CLAUSE]: (state, action) => ({
      ...state,
      selectedClauseId: action.payload,
    }),
    [DESELECT_CLAUSE]: (state) => ({
      ...state,
      selectedClauseId: null,
    }),
    [SET_CLAUSE_FILTER]: (state, action) => ({
      ...state,
      clauseFilter: action.payload,
    }),
    [FINALIZE_DOCUMENT]: (state) => ({
      ...state,
      selectedClauseId: null,
    }),
    [CHANGE_NEGOTIATION]: (state) => ({
      ...state,
      selectedClauseId: null,
    }),
  },
  initialState
);
