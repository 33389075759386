import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import MomentLocalizer from "react-widgets-moment";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "@app/akordaHistory";
import "./../index.scss";
import moment from "moment";
import Localization from "react-widgets/Localization";
import AppConfig from "./AppConfig";
import NotificationContainer from "@app/utils/notifications/NotificationContainer";
import SvgSpinner from "@app/toolkit/svgSpinner";

moment.locale("en");
const localizer = new (MomentLocalizer as any)(moment);

const helmetContext = {};

interface Props {
  store: any;
}

const App: React.FC<Props> = ({ children, store }) => {
  return (
    <Provider store={store}>
      <Suspense fallback={<SvgSpinner modal />}>
        <HelmetProvider context={helmetContext}>
          <Localization date={localizer}>
            <AppConfig>
              <Router history={history}>{children}</Router>
            </AppConfig>
            <NotificationContainer />
          </Localization>
        </HelmetProvider>
      </Suspense>
    </Provider>
  );
};

export default App;
