export const LOGOUT = "LOGOUT::USER";
export const SET_USER_CONTEXT = "SET_USER_CONTEXT";
export const FETCH_LOGIN_URL = "FETCH_LOGIN_URL";
export const LOAD_MY_PROFILE = "LOAD_MY_PROFILE";
export const REFRESH_MY_PROFILE = "REFRESH_MY_PROFILE";

const types = {
  SET_USER_CONTEXT,
  FETCH_LOGIN_URL,
  LOGOUT,
  LOAD_MY_PROFILE,
  REFRESH_MY_PROFILE,
};

export default types;
