import { AssemblyService } from "@app/types-business/assembly";
import { DocumentService, FileInfo } from "@app/types-business/documents";
import { Api, ApiResponse } from "./_base";

export const assemble = (
  request:
    | AssemblyService.AssembleSendForReviewRequest
    | AssemblyService.AssembleSendForSignatureRequest
    | AssemblyService.AssembleRequest,
  userId?: string | number // used for impersonating a user from external integrations using a client-credentials based token
): Promise<ApiResponse<DocumentService.Document>> => {
  const impersonation = userId ? `?userId=${userId}` : "";
  return Api.execute({
    url: `/api/review/documents/assemble${impersonation}`,
    method: "POST",
    data: request,
  });
};

/**
 * Assembly a document on the fly without filing
 * @param request
 * @returns
 */
export const assembleAndDownload = (
  request: AssemblyService.AssemblePayload
): Promise<
  ApiResponse<{
    fileInfo: FileInfo;
    metadata?: { [key: string]: number | string | string[] };
  }>
> => {
  return Api.execute({
    url: `/api/proxy/ms-project/v3/assembly`,
    method: "POST",
    data: request,
  });
};
