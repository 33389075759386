import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import commonActionTypes, {
  SET_SHOW_TOOLBOX_DIFF,
  SEARCH_TOOLBOX_TAB,
  UPDATE_SEARCHTOOLBOX_MATCH_COUNT,
  UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX,
  TOGGLE_SHOW_TOOLBOX_SEARCH,
  RESET_TOOLBOX,
} from "../../../_Common/actionTypes";
import constants from "../../../utils/constants";
import isEqual from "lodash/isEqual";
import { LOAD_DOCUMENT } from "../../../_Document/actions/types";
import { LOAD_PLAYBOOK_CLAUSE } from "@app/_Playbook/actions/types";
const SET_DOCUMENT_CONTEXT = "DOCUMENT_CONTEXT";

export const initialState = {
  // guidance for selected clause
  guidance: null,
  // shouold only have one item,
  standard: null,
  // whether toolbox is visible
  isShowingToolbox: false,
  // whether toolbox is minimized or maximized
  isToolboxMinimized: true,
  // which tab ID is active on the toolbox
  activeToolboxTabId: constants.TOOLBOX_TAB_ID_MAP.ISSUES,
  // status of the playbook clause status: loading, error, completed
  playbookClauseStatus: null,
  // whether the diff to standard is shown instead of simple text
  isShowingDiffToStandard: true,
  // whether the search is showing/active
  isShowingSearch: false,
  // the search term associated with a toolbox tab (if any)
  search: null,
  // number of search matches found
  searchMatchCount: 0,
  // the index of the current search match in focus
  searchMatchFocusIndex: 0,
  // customer issues for the selected clause
  issues: [],
  //structure settings
  structureSettings: null,
};

// Resets the toolbox state to the initial values, but only if the toolbox state is not
// already in the initial state. The "isEqual" check helps to prevent unnecessary re-renders,
// b/c if we just spread the same initial state into a new object, memoized selectors that depend
// on the state will think the values have changed.
const resetState = (state) =>
  isEqual(state, initialState) ? state : { ...initialState };

export default handleActions(
  {
    [RESET_TOOLBOX]: () => ({
      ...initialState,
    }),
    // Ensure that the toolbox and examples are reset every time a new document is loaded
    [LOAD_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: resetState,
      });
    },
    // Ensure that the toolbox and examples are reset every time the document context is set
    [SET_DOCUMENT_CONTEXT]: resetState,
    [commonActionTypes.TOOLBOX_HIDE]: (state) => {
      return {
        ...state,
        isShowingToolbox: false,
        isToolboxMinimized: true,
      };
    },
    [commonActionTypes.TOOLBOX_SHOW]: (state) => {
      return {
        ...state,
        isShowingToolbox: true,
      };
    },
    [commonActionTypes.TOOLBOX_MAXIMIZE]: (state) => {
      return {
        ...state,
        isToolboxMinimized: false,
      };
    },
    [commonActionTypes.TOOLBOX_MINIMIZE]: (state) => {
      return {
        ...state,
        isToolboxMinimized: true,
      };
    },
    [commonActionTypes.TOOLBOX_CHANGE_TAB]: (state, { payload }) => {
      return {
        ...state,
        activeToolboxTabId: payload,
        searchMatchCount: 0,
        searchMatchFocusIndex: 0,
      };
    },
    [SET_SHOW_TOOLBOX_DIFF]: (state, { payload }) => ({
      ...state,
      isShowingDiffToStandard: payload,
    }),
    [commonActionTypes.VIEW_APPROVALS]: (state) => {
      return {
        ...state,
        isToolboxMinimized: false,
        isShowingToolbox: true,
        activeToolboxTabId: constants.TOOLBOX_TAB_ID_MAP.HISTORY,
      };
    },
    [LOAD_PLAYBOOK_CLAUSE]: (state, action: any) => {
      return handle(state, action, {
        start: (s) => ({ ...s, playbookClauseStatus: "loading" }),
        failure: (s) => ({ ...s, playbookClauseStatus: "error" }),
        success: (s) => {
          const result = action.payload.data;
          return {
            ...s,
            playbookClauseStatus: "completed",
            guidance: result.guidance,
            standard: result.text,
            issues: result.issues,
            structureSettings: result.structureSettings,
          };
        },
      });
    },
    [SEARCH_TOOLBOX_TAB]: (state, action) => {
      return {
        ...state,
        search: action.payload,
        searchMatchCount: 0,
        searchMatchFocusIndex: 0,
      };
    },
    [UPDATE_SEARCHTOOLBOX_MATCH_COUNT]: (state, action) => {
      return {
        ...state,
        searchMatchCount: action.payload,
      };
    },
    [UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX]: (state, action) => {
      return {
        ...state,
        searchMatchFocusIndex: action.payload,
      };
    },
    [TOGGLE_SHOW_TOOLBOX_SEARCH]: (state) => {
      return {
        ...state,
        isShowingSearch: !state.isShowingSearch,
        search: null,
        searchMatchCount: 0,
        searchMatchFocusIndex: 0,
      };
    },
  },
  initialState
);
