import { createSelector } from "reselect";
import constants from "../../utils/constants";
import { get, findIndex, filter } from "@app/utils/lodash";
import { getReviewDocumentClauses } from "@app/redux/data/review";
import { getDocumentFilters } from "@app/redux/data/review/documents";
import { DocumentService } from "@app/types-business/documents";
import { find, forEach } from "@app/utils/lodash";
import { getApprovalQueue } from "@app/_ApprovalCenter/selectors";

const { UNCHANGED } = constants.NEGOTATION_STATUS_MAP;
const { AWAITING_APPROVAL_IN_QUEUE } = constants.APPROVAL_STATUS_MAP;

const contractState = (state) => state.contract;

export const getActiveReviewAction = createSelector(
  contractState,
  (state) => state.activeReviewAction
);

export const isCardPanelCollapsed = createSelector(
  contractState,
  (state) => state.isCardPanelCollapsed
);

export const getSelectedClauseId = createSelector(
  contractState,
  (state) => state.selectedClauseId
);

export const getClausesWithApprovalQueueInfo = createSelector(
  getReviewDocumentClauses,
  getApprovalQueue,
  (issues, approvalQueue) => {
    // Adjust the clauses so that we add approval information for those clauses that are either awaiting approval
    // or are currently in the user's approval queue
    const clausesWithApprovalInfo = issues.map((issue) => {
      const approvalInQueue = approvalQueue.find(
        (approval) => approval.approvals[0].issueId === issue.issueId
      );

      const status = !!approvalInQueue
        ? AWAITING_APPROVAL_IN_QUEUE
        : issue.status;

      return { ...issue, status };
    });

    return clausesWithApprovalInfo;
  }
);

export const getSelectedClause = createSelector(
  getSelectedClauseId,
  getClausesWithApprovalQueueInfo,
  (selectedClauseId, clauses) => {
    const selectedClause = clauses.find(
      (clause) => clause.issueId === selectedClauseId
    );
    return selectedClause || null;
  }
);

export const getSelectedClauseIssuesLog = createSelector(
  getSelectedClause,
  (clause) => (clause && clause.issueLogs) || []
);

export const getSelectedClauseTitle = createSelector(
  getSelectedClause,
  (clause) => (!!clause ? clause.title : null)
);

export const getClauseFilter = createSelector(
  contractState,
  (state) => state.clauseFilter
);

export const getContractClauseId = createSelector(getSelectedClause, (clause) =>
  get(clause, "playbookClauseId", null)
);

/**
 * Returns the next clause in the list, given the current selected clause as context. The next clause will first walk through all
 * "true" clauses
 */
export const getNextClause = createSelector(
  getSelectedClauseId,
  getClausesWithApprovalQueueInfo,
  (selectedClauseId, reviewClauses) => {
    const clauses = [...reviewClauses];
    const currentIndex = findIndex(
      clauses,
      (c: DocumentService.ReviewClause) => {
        return c.issueId === selectedClauseId;
      }
    );
    // get the list of all clauses after this one
    const nextClauses = clauses.slice(currentIndex + 1);
    // and find the next clause that has been changed
    const nextClause = nextClauses.find(
      (clause) => clause.negotiationStatus !== UNCHANGED
    );
    return nextClause || null;
  }
);

/**
 * Returns the id of the next clause, given the current selected clause as context
 */
export const getNextClauseIssueId = createSelector(
  getNextClause,
  (nextClause) => (!!nextClause ? nextClause.issueId : null)
);

/**
 * Returns the previous clause in the list, given the currently selected clause as context.
 */
export const getPreviousClause = createSelector(
  getSelectedClauseId,
  getClausesWithApprovalQueueInfo,
  (selectedClauseId, reviewClauses) => {
    const clauses = [...reviewClauses];
    const currentIndex = findIndex(
      clauses,
      (c: DocumentService.ReviewClause) => c.issueId === selectedClauseId
    );
    // find all clauses before the current one, and reverse them so they are listed with the clauses closest to the current one first
    const previousClauses = clauses.slice(0, currentIndex).reverse();
    // and then find the first clause that has changes
    const previousClause = previousClauses.find(
      (clause) => clause.negotiationStatus !== UNCHANGED
    );
    return previousClause || null;
  }
);

/**
 * Returns the id of the previous clause, given the current selected clause as context
 */
export const getPreviousClauseIssueId = createSelector(
  getPreviousClause,
  (previousClause) => (!!previousClause ? previousClause.issueId : null)
);

/**
 * Returns all clauses that have any changes (including either redlines or comments). We basically filter out the clauses with status: unchanged
 */
export const getChangedClauses = createSelector(
  getClausesWithApprovalQueueInfo,
  (clauses) => clauses.filter((clause) => clause.status !== UNCHANGED)
);

export const getFilteredClauses = createSelector(
  getChangedClauses,
  getClauseFilter,
  (allClauses, clauseFilter) => {
    if (clauseFilter === constants.CLAUSE_FILTER.UNPROCESSED) {
      const openClauses = filter(
        allClauses,
        (c) => c.turnStatus === constants.TURN_STATUS_MAP.OPEN
      );
      return openClauses;
    } else if (clauseFilter === constants.CLAUSE_FILTER.PROCESSED) {
      const processedClauses = filter(
        allClauses,
        (c) => c.turnStatus === constants.TURN_STATUS_MAP.DONE
      );
      return processedClauses;
    }
    return allClauses;
  }
);

// Returns the contract list stats based on status filter response
export const getContractListStats = createSelector(
  getDocumentFilters,
  (filters) => {
    const statusFilter = find(filters, (f) => f.name === "status") || {};
    let stats = {};
    forEach(get(statusFilter, "options") || [], (status) => {
      stats[status.option] = status.count;
    });
    return stats;
  }
);
