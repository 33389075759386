import { FC } from "react";
import { toast, ToastContainer } from "react-toastify";
import constants from "@app/utils/constants";
import Icon from "@app/toolkit/icon";
import s from "./styles.module.scss";

interface Props {}

const CloseButton = ({ closeToast }) => {
  return (
    <Icon
      name="fal-times-circle"
      className="akorda-toast-close"
      onClick={closeToast}
    />
  );
};

const NotificationContainer: FC<Props> = () => {
  return (
    <ToastContainer
      closeButton={CloseButton}
      className={s.notificationContainer}
      autoClose={constants.TOAST_DEFAULT_TIME}
      hideProgressBar={true}
      position={toast.POSITION.TOP_RIGHT}
      data-elm-id="toast-notification"
    />
  );
};

export default NotificationContainer;
