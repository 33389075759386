import { Pageable } from "@app/types/IPageable";
import { get, isEmpty, flattenDeep, keyBy, compact } from "@app/utils/lodash";

export interface CounterpartyFolder {
  folderId: string;
  folderName: string;
  folders: CounterpartyFolder[];
}

export interface Counterparty {
  createdBy: string;
  createdDate: string;
  externalId: string;
  id: string;
  metadata?: Record<string, string | number | string[]>;
  name: string;
  updatedBy: string;
  updatedDate: string;
  folders?: CounterpartyFolder[];
}

export interface CounterpartySearchResults extends Pageable {
  counterparties: Counterparty[];
}

export const getCounterpartyId = (counterparty: Counterparty) =>
  get(counterparty, "id");

export const getCounterpartyName = (counterparty: Counterparty) =>
  get(counterparty, "name");

export const getCounterpartyMetadata = (counterparty: Counterparty) =>
  get(counterparty, "metadata") || {};

export const getCounterpartyFolders = (
  folder: Counterparty | CounterpartyFolder,
): CounterpartyFolder[] => {
  const folders = folder?.folders || [];
  return folders;
};

export const getFlattenCounterpartyFolders = (
  folder: any,
): CounterpartyFolder[] =>
  flattenDeep(
    compact([
      folder.folderId ? folder : null,
      ...(folder?.folders ?? []).map((f) => getFlattenCounterpartyFolders(f)),
    ]),
  );

export const getCounterpartyFoldersMap = (
  counterparty: Counterparty | CounterpartyFolder,
): any => keyBy(getFlattenCounterpartyFolders(counterparty), "folderId");

export const hasAnyCounterpartyFolder = (counterparty: Counterparty) =>
  !isEmpty(getCounterpartyFolders(counterparty));

export const getCurrentCounterpartyFolder = (counterparty: Counterparty) =>
  get(counterparty, "currentFolder") || {};

export const getFolderId = (folder: any) => get(folder, "folderId") || null;

export const getFolderName = (folder: any) => get(folder, "folderName") || null;

export const getFolderPath = (folder: any): { id: string; name: string }[] =>
  get(folder, "folderPath.path") || [];

export const getFullFolderPath = (folder: any): string =>
  getFolderPath(folder)
    .map((f) => f.name)
    .join(" › ");

export const getParentFolder = (folder: any) => {
  const folderPath = getFolderPath(folder);
  return folderPath[folderPath.length - 2] || null;
};

export const isRootFolder = (folderId: any) => folderId === "root";

export const getRelatedCounterparties = (counterparty: Counterparty) =>
  get(counterparty, "relations") || [];
