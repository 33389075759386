import queryString from "query-string";
import {
  Counterparty,
  CounterpartySearchResults,
} from "@app/entities/counterparties";
import { Api, ApiResponse } from "./_base";

export async function searchCounterparties(
  query: Record<string, any>
): Promise<ApiResponse<CounterpartySearchResults>> {
  return Api.execute({
    url: `/api/counterparties/search`,
    params: query,
  });
}

export async function loadCounterparty(
  counterpartyId: string,
  query?: Record<string, any>
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}?${queryString.stringify(
      query
    )}`,
  });
}

export async function deleteCounterparty(
  counterpartyId: string
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/api-documents/v2/counterparties/${counterpartyId}`,
    method: "DELETE",
  });
}

export async function updateCounterparty(
  counterpartyId: string,
  update: { [key: string]: any }
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}`,
    method: "PATCH",
    data: update,
  });
}

export async function createCounterparty(
  name: string
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: "/api/counterparties",
    method: "POST",
    data: { name },
  });
}

export async function createCounterpartyFolder(
  counterpartyId: string,
  folderName: string,
  parent: string
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/folders`,
    method: "POST",
    data: {
      folderName,
      parent,
    },
  });
}

export async function loadCounterpartyFolder(
  counterpartyId: string,
  folderId: string = "root"
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/folders/${folderId}`,
  });
}

export async function moveCounterpartyFolder(
  counterpartyId: string,
  folderId: string,
  newParent: string,
  currentParent: string
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/folders/${folderId}`,
    method: "PUT",
    data: {
      newParent,
      currentParent,
    },
  });
}

export async function removeCounterpartyFolder(
  counterpartyId: string,
  folderId: string,
  parent: string
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/folders/${folderId}`,
    method: "DELETE",
    data: {
      parent,
    },
  });
}

export async function updateCounterpartyFolderName(
  counterpartyId: string,
  folderId: string,
  folderName: string,
  parent: string
): Promise<ApiResponse<Counterparty>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/folders/${folderId}`,
    method: "PATCH",
    data: {
      folderName,
      parent,
    },
  });
}

export function addRelatedCounterparty(
  counterpartyId: string,
  relatedCounterparties: string[],
  folderId: string = "root"
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/add-related`,
    data: {
      relatedCounterparties,
      folderId,
    },
    method: "POST",
  });
}

export function removeRelatedCounterparty(
  counterpartyId: string,
  relatedCounterparties: string[],
  folderId: string = "root"
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/counterparties/${counterpartyId}/remove-related`,
    data: {
      relatedCounterparties,
      folderId,
    },
    method: "POST",
  });
}
