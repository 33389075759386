import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import {
  CREATE_REQUEST,
  HIDE_CONTACT_FORM_MODAL,
  SHOW_CONTACT_FORM_MODAL,
} from "../actions/types";

export interface SupportReduxState {
  showModal: boolean;
  requestStatus: string;
}

export const initialState: SupportReduxState = {
  // whether show contact form modal
  showModal: false,
  // status of the support request: completed, loading, error
  requestStatus: null,
};

export default handleActions(
  {
    [CREATE_REQUEST]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          requestStatus: "loading",
        }),
        failure: (s) => ({ ...s, requestStatus: "error" }),
        success: (s) => {
          return {
            ...s,
            requestStatus: "completed",
          };
        },
      });
    },
    [SHOW_CONTACT_FORM_MODAL]: (state) => ({
      ...state,
      showModal: true,
    }),
    [HIDE_CONTACT_FORM_MODAL]: (state) => ({
      ...state,
      showModal: false,
    }),
  },
  initialState
);
