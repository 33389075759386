import document from "./document";
import documents from "./documents";
import approvals from "./approvals";
import documentHistory from "./document-history";
import documentVersions from "./document-versions";

import { combineReducers } from "redux";
import documentMetrics from "./document-metrics";

export * from "./document";

export default combineReducers({
  approvals,
  documents,
  document,
  documentHistory,
  documentVersions,
  documentMetrics,
});
