import React, { ReactNode } from "react";
import { Card } from "reactstrap";
import BaseButton from "../baseButton";
import s from "./styles.module.scss";
import constants from "../../utils/constants";
import classNames from "classnames";
import {
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import FontIcon from "../FontIcon";
import AppLogo from "@app/_Home/components/AppHeader/AppLogo";

export const BANNER_MESSAGE_ICON_TYPES = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

interface Props {
  buttonText?: string;
  children?: ReactNode;
  className?: string;
  icon?: any; // e.g., <Icon/> component
  iconType?: string;
  showAkordaLogo?: boolean;
  text?: string;
  description?: string;
  secondaryButtonText?: string;
  onButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

class BannerMessage extends React.Component<Props> {
  static defaultProps = {
    showAkordaLogo: true,
    onButtonClick: () => {
      window.location.href = constants.AKORDA_WEBSITE;
    },
  };

  renderIcon = (type: string = this.props.iconType): any => {
    switch (type) {
      case BANNER_MESSAGE_ICON_TYPES.SUCCESS:
        return <FontIcon icon={faCheckCircle} />;
      case BANNER_MESSAGE_ICON_TYPES.WARNING:
        return <FontIcon icon={faExclamationTriangle} />;
      case BANNER_MESSAGE_ICON_TYPES.ERROR:
        return <FontIcon icon={faExclamationCircle} />;
      default:
        return <FontIcon icon={faCheckCircle} />;
    }
  };

  renderLogo() {
    const { showAkordaLogo } = this.props;
    return showAkordaLogo ? <AppLogo className={s.logo} /> : null;
  }

  render() {
    const {
      className,
      icon,
      iconType,
      text,
      onButtonClick,
      buttonText,
      secondaryButtonText,
      onSecondaryButtonClick,
      description,
      children,
    } = this.props;

    const statusIcon = !icon && !!iconType ? this.renderIcon(iconType) : null;
    const bannerIcon = !!icon ? icon : statusIcon;
    const logo = this.renderLogo();

    return (
      <section
        className={classNames(
          "akorda-banner-message",
          s.bannerMessage,
          className
        )}
      >
        {logo}
        <Card
          className={classNames(
            s.messageContainer,
            "col-lg-6 col-md-6 col-sm-8 col-xs-12"
          )}
        >
          {bannerIcon}
          <h3 className={classNames(s.title, "akorda-banner-message-title")}>
            {text}
          </h3>
          {!!description && <p className={s.description}>{description}</p>}
          <div className="d-flex">
            {buttonText && (
              // eslint-disable-next-line
              <a data-elm-id={`banner_message_redirect_button`}>
                <BaseButton
                  className={classNames(s.button)}
                  color="primary"
                  size="lg"
                  onClick={onButtonClick}
                >
                  {buttonText}
                </BaseButton>
              </a>
            )}
            {secondaryButtonText && (
              // eslint-disable-next-line
              <a data-elm-id={`banner_message_secondary_button`}>
                <BaseButton
                  className={classNames(s.button, s.secondary)}
                  color="secondary"
                  size="lg"
                  onClick={onSecondaryButtonClick}
                >
                  {secondaryButtonText}
                </BaseButton>
              </a>
            )}
            {children}
          </div>
        </Card>
      </section>
    );
  }
}

export default BannerMessage;
