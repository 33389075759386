import { FC, ReactNode } from "react";
import Icon from "@app/toolkit/icon";
import s from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  iconName?: string;
  children?: ReactNode;
  className?: string;
}

const Notification: FC<Props> = ({ className, iconName, children }) => {
  return (
    <div className={classNames(s.notification, className)}>
      {iconName && (
        <div className={s.icon}>
          <Icon name={iconName} />
        </div>
      )}
      <div className={s.body}>{children}</div>
    </div>
  );
};

export default Notification;
