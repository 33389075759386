import { FC } from "react";
import classNames from "classnames";
import s from "./styles.module.scss";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { NOOP } from "@app/utils/helpers";

interface Props extends FontAwesomeIconProps {
  id?: string; // html element id
  className?: string;
  title?: string; // alt for images
  dataElmId?: string;
  onClick?: (e) => void;
  disabled?: boolean;
  icon: any;
}

const FontIcon: FC<Props> = ({
  className,
  id,
  dataElmId,
  title,
  disabled,
  children,
  onClick = NOOP,
  ...rest
}) => {
  return (
    <span
      id={id}
      className={classNames("font-icon", s.icon, className, {
        "akorda-disabled": disabled,
      })}
      onClick={(e) => !disabled && !!onClick && onClick(e)}
      data-elm-id={dataElmId}
    >
      <FontAwesomeIcon {...rest} />
      {children}
    </span>
  );
};

export default FontIcon;
