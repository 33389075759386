import moment from "moment-timezone";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get } from "@app/utils/lodash";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataSubmitting,
} from "@app/redux/utils";
import { createSelector } from "@reduxjs/toolkit";
import { IPagination } from "@app/types-business/Project";
import { denormalize, normalize } from "normalizr";
import { ticketListSchema } from "./schemas";
import { getKey, Ticket } from "@app/entities/tickets/tickets";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { NOOP } from "@app/utils/helpers";
import { useSearchHelpers } from "@app/_Search/utils";

// #region ACTION TYPES
export const LOAD_TICKETS = "tickets/load";
export const CREATE_TICKET = "/ticket/create";
export const RESET_TICKETS = "tickets/reset";
export const UPDATE_TICKET_FROM_LIST = "tickets/update";

// #endregion

// #region ACTIONS
export const loadTickets = (
  query: Record<string, any> = {},
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => {
  return {
    type: LOAD_TICKETS,
    promise: API.loadTickets(query),
    meta: {
      onSuccess,
      onFailure,
    },
  };
};

export const createTicket = (
  ticketRequest: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_TICKET,
  promise: API.createTicket(ticketRequest),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const resetTickets = (): IAction => ({
  type: RESET_TICKETS,
});
// #endregion

// #region SELECTORS
export const ticketsState = (state) => state.data.ticket.ticketList;

export const getTicketsMap = createSelector(
  ticketsState,
  (state) => get(state, "tickets") || {}
);

export const getTicketKeys = createSelector(
  ticketsState,
  (state) => get(state, "ticketKeys") || []
);

export const getTickets = createSelector(
  getTicketsMap,
  getTicketKeys,
  (tickets, ids) => {
    return denormalize(ids, ticketListSchema, { tickets });
  }
);

export const getTicketsStatus = createSelector(
  ticketsState,
  (state) => state.status
);

export const areTicketsLoading = createSelector(
  getTicketsStatus,
  isDataLoading
);

export const hasTicketsError = createSelector(getTicketsStatus, hasDataError);

export const hasTicketsLoaded = createSelector(getTicketsStatus, hasDataLoaded);

export const isTicketSubmitting = createSelector(
  getTicketsStatus,
  isDataSubmitting
);

export const getPaginationInfo = createSelector(
  ticketsState,
  (state) => state.paginationInfo
);

export const getAggregations = createSelector(
  ticketsState,
  (state) => state.aggregations || []
);
// #endregion

export const useTickets = (
  kanban: boolean,
  myTickets: boolean,
  myFollowingTickets: boolean
) => {
  const dispatch = useDispatch();
  const { query, loadAggregationsForField } = useSearchHelpers("tickets");

  const baseQuery = useMemo(() => {
    const query = {};
    query["filters.tz"] = moment().utcOffset();

    if (myTickets) {
      query["filters.assignee"] = true;
    } else if (myFollowingTickets) {
      query["filters.followed"] = true;
    }

    return query;
  }, [myTickets, myFollowingTickets]);

  const load = useCallback(() => {
    const qs = { ...query, ...baseQuery, kanban };
    dispatch(loadTickets(qs));
  }, [dispatch, kanban, query, baseQuery]);

  useEffect(() => {
    load();
  }, [load]);

  const tickets = useSelector(getTickets);
  const paginationInfo = useSelector(getPaginationInfo);
  const isLoading = useSelector(areTicketsLoading);
  const isSubmitting = useSelector(isTicketSubmitting);
  const hasError = useSelector(hasTicketsError);
  const hasLoaded = useSelector(hasTicketsLoaded);
  const aggregations = useSelector(getAggregations);

  const actions = useMemo(
    () => ({
      loadTickets: load,
      loadTicketAggregations: loadAggregationsForField(baseQuery),
      resetTicketState: () => dispatch(resetTickets()),
    }),
    [dispatch, load, baseQuery, loadAggregationsForField]
  );

  return {
    tickets,
    aggregations,
    paginationInfo,
    status: { isLoading, hasError, hasLoaded, isSubmitting },
    actions,
  };
};

//#region REDUCER
export interface TicketsReduxState {
  aggregations: any[];
  status: DataStatus;
  tickets: Record<string, Ticket>;
  ticketKeys: string[];
  paginationInfo: IPagination;
}

export const initialState: TicketsReduxState = {
  aggregations: [],
  status: DataStatus.NotLoaded,
  tickets: {},
  ticketKeys: [],
  paginationInfo: {
    page: 0,
    totalSize: 0,
    totalPages: 0,
  },
};

export default handleActions(
  {
    [LOAD_TICKETS]: (state, action) => {
      return handle(state, action, {
        start: (s: TicketsReduxState): TicketsReduxState => ({
          ...s,
          status: DataStatus.Loading,
        }),
        failure: (s: TicketsReduxState): TicketsReduxState => ({
          ...s,
          status: DataStatus.Error,
        }),
        success: (s: TicketsReduxState): TicketsReduxState => {
          const {
            aggregations = [],
            tickets,
            page,
            pageSize,
            totalSize,
            totalPages,
          }: any = get(action, "payload.data") || {};

          const { entities, result } = normalize(
            tickets || [],
            ticketListSchema
          );
          return {
            ...s,
            aggregations,
            tickets: entities.tickets,
            ticketKeys: result,
            paginationInfo: {
              page,
              pageSize,
              totalSize,
              totalPages,
            },
            status: DataStatus.Done,
          };
        },
      });
    },
    [UPDATE_TICKET_FROM_LIST]: (state, action) => {
      return handle(state, action, {
        start: (s: TicketsReduxState): TicketsReduxState => ({
          ...s,
          status: DataStatus.Submitting,
        }),
        failure: (s: TicketsReduxState): TicketsReduxState => ({
          ...s,
          status: DataStatus.Done,
        }),
        success: (s: TicketsReduxState): TicketsReduxState => {
          const ticket = get(action, "payload.data");
          return {
            ...s,
            tickets: {
              ...s.tickets,
              [getKey(ticket)]: ticket,
            },
            status: DataStatus.Done,
          };
        },
      });
    },
    [RESET_TICKETS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
