import { handleActions } from "redux-actions";
import {
  SET_COMMENTS,
  RESET_DOCUMENT_STATE,
  SHOW_COMMENT,
  HIDE_COMMENT,
  SHOW_COMMENTS_PANEL,
  HIDE_COMMENTS_PANEL,
  TOGGLE_COMMENTS_PANEL,
  SELECT_COMMENT,
  DESELECT_COMMENT,
  SET_COMMENT_INPUT_ID,
  RESET_COMMENT_INPUT_ID,
  COMMENT_ADDED,
  COMMENT_DISCARDED,
  TOGGLE_PIN_COMMENT,
} from "../actions/types";
import cloneDeep from "lodash/cloneDeep";
import { getNewCommentInputId } from "@app/_Contract/components/ContractComments/utils";
import { get, xor } from "@app/utils/lodash";

export interface DocumentCommentsReduxState {
  isShowingCommentsPanel: boolean;
  comments: any[];
  shownCommentId: number;
  elementRect: any;
  selectedCommentId: number;
  commentInputId: any; // comment id, REPLY_INPUT_ID value or new-${comment id}
  canBeDiscarded: boolean;
  pinned: string[];
}

export const initialState: DocumentCommentsReduxState = {
  // all comments in a document
  comments: [],
  shownCommentId: null,
  elementRect: null,
  isShowingCommentsPanel: false,
  selectedCommentId: null,
  commentInputId: null,
  canBeDiscarded: false,
  pinned: [],
};

export default handleActions(
  {
    [RESET_DOCUMENT_STATE]: () => {
      return {
        ...initialState,
      };
    },
    [SET_COMMENTS]: (state: any, action) => {
      return {
        ...state,
        comments: cloneDeep(action.payload),
      };
    },
    // todo: only used by external approvals; replace with akordaComment plugin (which has tooltip)
    [SHOW_COMMENT]: (state, action) => {
      return {
        ...state,
        shownCommentId: action.payload.commentId,
        elementRect: action.payload.elementRect,
      };
    },
    // todo: only used by external approvals; replace with akordaComment plugin (which has tooltip)
    [HIDE_COMMENT]: (state) => {
      return {
        ...state,
        shownCommentId: null,
        canBeDiscarded: false,
      };
    },
    [SHOW_COMMENTS_PANEL]: (state) => {
      return {
        ...state,
        isShowingCommentsPanel: true,
      };
    },
    [HIDE_COMMENTS_PANEL]: (state) => {
      return {
        ...state,
        isShowingCommentsPanel: false,
        newCommentTopPosition: null,
        newCommentText: null,
        selectedCommentId: null,
        commentInputId: null,
        canBeDiscarded: false,
        pinned: [],
      };
    },
    [TOGGLE_COMMENTS_PANEL]: (state) => ({
      ...state,
      isShowingCommentsPanel: !state.isShowingCommentsPanel,
    }),
    [SELECT_COMMENT]: (state, action) => ({
      ...state,
      selectedCommentId: action.payload,
    }),
    [DESELECT_COMMENT]: (state) => ({
      ...state,
      selectedCommentId: null,
      commentInputId: null,
    }),
    [SET_COMMENT_INPUT_ID]: (state, action) => ({
      ...state,
      commentInputId: action.payload,
    }),
    [RESET_COMMENT_INPUT_ID]: (state) => ({
      ...state,
      commentInputId: null,
    }),
    [COMMENT_ADDED]: (state, action) => {
      const commentId = get(action.payload, "commentId");
      return {
        ...state,
        isShowingCommentsPanel: true,
        selectedCommentId: commentId,
        commentInputId: getNewCommentInputId(commentId),
        canBeDiscarded: get(action.payload, "canBeDiscarded", false),
      };
    },
    [COMMENT_DISCARDED]: (state) => ({
      ...state,
      selectedCommentId: null,
      commentInputId: null,
      canBeDiscarded: false,
    }),
    [TOGGLE_PIN_COMMENT]: (state, action) => {
      return {
        ...state,
        pinned: xor(state.pinned, [action.payload]),
      };
    },
  },
  initialState
);
