import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";

import * as API from "@app/API";
import { createSelector } from "reselect";
import { RESET_PLAYBOOK_LIST } from "./playbook-list";
import { DataStatus } from "@app/redux/utils";

export interface PlaybookSchemasState {
  playbookSchema: any;
  playbookSchemaStatus: DataStatus;
  structureTypes: any;
  structureTypesStatus: DataStatus;
}

//#region ACTION TYPES
export const LOAD_PLAYBOOK_CLAUSE_SCHEMAS =
  "PLAYBOOK::LOAD_PLAYBOOK_CLAUSE_SCHEMAS";
export const LOAD_PLAYBOOK_SCHEMA = "PLAYBOOK::LOAD_PLAYBOOK_SCHEMA";

//#endregion

//#region ACTIONS
export const loadPlaybookClauseSchemas = (): any => ({
  type: LOAD_PLAYBOOK_CLAUSE_SCHEMAS,
  promise: API.getPlaybookClauseSchemas(),
});

export const loadPlaybookSchema = (): any => ({
  type: LOAD_PLAYBOOK_SCHEMA,
  promise: API.getPlaybookSchema(),
});
//#endregion

//#region SELECTORS
export const playbookSchemasState: (state: any) => PlaybookSchemasState = (
  state
) => state.data.playbook.playbookSchemas;

export const getPlaybookClauseSchemas = createSelector(
  playbookSchemasState,
  (state) => get(state, "structureTypes") || {}
);

export const getPlaybookClauseSchemasStatus = createSelector(
  playbookSchemasState,
  (state) => get(state, "structureTypesStatus", null)
);

export const isPlaybookClauseSchemasLoading = createSelector(
  getPlaybookClauseSchemasStatus,
  (status) => ![DataStatus.Done, DataStatus.Error].includes(status)
);

export const hasPlaybookClauseSchemasError = createSelector(
  getPlaybookClauseSchemasStatus,
  (status) => status === DataStatus.Error
);

export const hasPlaybookClauseSchemasLoaded = createSelector(
  getPlaybookClauseSchemasStatus,
  (status) => status === DataStatus.Done
);

export const getPlaybookSchema = createSelector(
  playbookSchemasState,
  (state) => get(state, "playbookSchema.schema") || {}
);

export const getPlaybookSchemaStatus = createSelector(
  playbookSchemasState,
  (state) => get(state, "playbookSchemaStatus", null)
);

export const isPlaybookSchemaLoading = createSelector(
  getPlaybookSchemaStatus,
  (status) => ![DataStatus.Done, DataStatus.Error].includes(status)
);

export const hasPlaybookSchemaError = createSelector(
  getPlaybookSchemaStatus,
  (status) => status === DataStatus.Error
);

export const hasPlaybookSchemaLoaded = createSelector(
  getPlaybookSchemaStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region REDUCER

export const initialState: PlaybookSchemasState = {
  // the playbook schema
  playbookSchema: null,
  // status of the playbook schema request: loading, completed, error
  playbookSchemaStatus: null,
  // the schemas for each clause structure type
  structureTypes: null,
  // status of the playbook clause schemas request: loading, completed, error
  structureTypesStatus: null,
};

export default handleActions(
  {
    [LOAD_PLAYBOOK_CLAUSE_SCHEMAS]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookSchemasState): PlaybookSchemasState => ({
          ...s,
          structureTypesStatus: DataStatus.Loading,
        }),
        failure: (s: PlaybookSchemasState): PlaybookSchemasState => ({
          ...s,
          structureTypesStatus: DataStatus.Error,
        }),
        success: (s: PlaybookSchemasState): PlaybookSchemasState => {
          return {
            ...s,
            structureTypesStatus: DataStatus.Done,
            structureTypes: get(action, "payload.data", null),
          };
        },
      });
    },
    [LOAD_PLAYBOOK_SCHEMA]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookSchemasState): PlaybookSchemasState => ({
          ...s,
          playbookSchemaStatus: DataStatus.Loading,
        }),
        failure: (s: PlaybookSchemasState): PlaybookSchemasState => ({
          ...s,
          playbookSchemaStatus: DataStatus.Error,
        }),
        success: (s: PlaybookSchemasState): PlaybookSchemasState => {
          return {
            ...s,
            playbookSchemaStatus: DataStatus.Done,
            playbookSchema: get(action, "payload.data", null),
          };
        },
      });
    },
    [RESET_PLAYBOOK_LIST]: () => ({
      ...initialState,
    }),
  },
  initialState
);
//#endregion
