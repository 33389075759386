import { User } from "../users";
import { get } from "@app/utils/lodash";

export enum BackupStatus {
  Unspecifed = "STATUS_UNSPECIFIED",
  Pending = "PENDING",
  Running = "RUNNING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Expired = "EXPIRED",
}

export interface Backup {
  backupId: string;
  companyId: string;
  bulkJobId: string;
  status: BackupStatus;
  query?: {
    filters?: any;
    search?: string;
  };
  name?: string;
  createdDate: string;
  endDate: string;
  createdBy: User;
}

export interface CreateBackupRequest {
  companyId?: string;
  query?: {
    filters?: any;
    search?: string;
  };
  objectIds?: { values: Array<string> };
}

export const getBackupStatus = (backup: Backup) => get(backup, "status");

export const isBackupCompleted = (backup: Backup) =>
  getBackupStatus(backup) === BackupStatus.Completed;

export const getBackupParts = (backup: Backup) => get(backup, "parts") ?? 0;

export const hasBackupMultipleParts = (backup: Backup) =>
  getBackupParts(backup) > 1;
