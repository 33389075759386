import axios from "axios";
import constants from "../utils/constants";

export function loadInsightsDiffView(documentId: number): Promise<any> {
  return axios.get(
    `${constants.BASE_URL}/api/doc-view/insights-diff-view/${documentId}`,
    {
      withCredentials: true,
    }
  );
}
