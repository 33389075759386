/** PLAYBOOK SUMMARY ACTION TYPES */
export const TOGGLE_CLAUSE_MODAL = "PLAYBOOK::TOGGLE_CLAUSE_MODAL";
export const SHOW_DELETE_CLAUSE_DIALOG = "PLAYBOOK::SHOW_DELETE_CLAUSE_DIALOG";
export const HIDE_DELETE_CLAUSE_DIALOG = "PLAYBOOK::HIDE_DELETE_CLAUSE_DIALOG";

export const CREATE_FALLBACK = "PLAYBOOK::CREATE_FALLBACK";
export const UPDATE_FALLBACK = "PLAYBOOK::UPDATE_FALLBACK";
export const DELETE_FALLBACK = "PLAYBOOK::DELETE_FALLBACK";
export const MOVE_FALLBACK = "PLAYBOOK::MOVE_FALLBACK";

export const SHOW_FALLBACK_MODAL = "PLAYBOOK::SHOW_FALLBACK_MODAL";
export const HIDE_FALLBACK_MODAL = "PLAYBOOK::HIDE_FALLBACK_MODAL";

export const SELECT_PLAYBOOK_CLAUSE = "PLAYBOOK::SELECT_PLAYBOOK_CLAUSE";
export const UPDATE_PLAYBOOK_CLAUSE = "PLAYBOOK::UPDATE_PLAYBOOK_CLAUSE";
export const DELETE_PLAYBOOK_CLAUSE = "PLAYBOOK::DELETE_PLAYBOOK_CLAUSE";
export const CREATE_PLAYBOOK_CLAUSE = "PLAYBOOK::CREATE_PLAYBOOK_CLAUSE";
export const LOAD_PLAYBOOK_CLAUSE = "PLAYBOOK::LOAD_PLAYBOOK_CLAUSE";
export const TOGGLE_EXPAND_CLAUSE = "PLAYBOOK::TOGGLE_EXPAND_CLAUSE";
export const EXPAND_CLAUSE = "PLAYBOOK::EXPAND_CLAUSE";
export const MOVE_PLAYBOOK_CLAUSE = "PLAYBOOK::MOVE_PLAYBOOK_CLAUSE";
export const INDENT_PLAYBOOK_CLAUSE = "PLAYBOOK::INDENT_PLAYBOOK_CLAUSE";
export const UPDATE_STRUCTURE_TYPE = "PLAYBOOK::UPDATE_STRUCTURE_TYPE";
export const UPDATE_OPTIONAL_CLAUSE = "PLAYBOOK::UPDATE_OPTIONAL_CLAUSE";

export const EXPORT_PLAYBOOK = "PLAYBOOK::EXPORT_PLAYBOOK";
export const SHOW_PLAYBOOK_ISSUE_MODAL = "PLAYBOOK::SHOW_PLAYBOOK_ISSUE_MODAL";
export const HIDE_PLAYBOOK_ISSUE_MODAL = "PLAYBOOK::HIDE_PLAYBOOK_ISSUE_MODAL";

export const CREATE_PLAYBOOK_ISSUE = "PLAYBOOK::CREATE_PLAYBOOK_ISSUE";
export const UPDATE_PLAYBOOK_ISSUE = "PLAYBOOK::UPDATE_PLAYBOOK_ISSUE";
export const DELETE_PLAYBOOK_ISSUE = "PLAYBOOK::DELETE_PLAYBOOK_ISSUE";
export const MOVE_PLAYBOOK_ISSUE = "PLAYBOOK::MOVE_PLAYBOOK_ISSUE";

export const LOAD_PLAYBOOK_SEARCH_RESULTS =
  "PLAYBOOK::LOAD_PLAYBOOK_SEARCH_RESULTS";
export const SELECT_RESULT = "PLAYBOOK::SELECT_RESULT";
export const LOAD_PLAYBOOK_CLAUSE_SEARCH_RESULTS =
  "PLAYBOOK::LOAD_PLAYBOOK_CLAUSE_SEARCH_RESULTS";
export const CLEAR_PLAYBOOK_SEARCH = "PLAYBOOK::CLEAR_PLAYBOOKSEARCH";
export const SET_SHOW_PLAYBOOK_DIFF = "PLAYBOOK::SET_SHOW_PLAYBOOK_DIFF";
export const TOGGLE_OPTIONAL_CLAUSES = "PLAYBOOK::TOGGLE_OPTIONAL_CLAUSES";
export const TRACK_EDITS = "PLAYBOOK::TRACK_EDITS";
export const UPDATE_PLAYBOOK_LIST_UI_STATE =
  "PLAYBOOK::UPDATE_PLAYBOOK_LIST_UI_STATE";
