import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get } from "@app/utils/lodash";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
} from "@app/redux/utils";
import { DocumentService } from "@app/types-business/documents";
import { createSelector } from "@reduxjs/toolkit";
import constants from "@app/utils/constants";
import { ApproveRequest, DenyRequest } from "@app/API";
import { NOOP } from "@app/utils/helpers";
const { INVALID_ACTION_TOKEN } = constants.ERRORS;
const { APPROVE, DENY } = constants.CLAUSE_ACTION_MAP;

// #region ACTION TYPES
export const LOAD_APPROVALS = "review/approvals/load";
export const LOAD_APPROVALS_FOR_DOCUMENT = "review/approvals/load-for-document";
export const LOAD_EXTERNAL_APPROVALS = "review/approvals/load-external";
export const RESET_APPROVALS_STATE = "review/approvals/reset";
export const PROCESS_APPROVAL = "review/approvals/process_approval";
// #endregion

// #region ACTIONS

/**
 * Fetches the approvals for the user
 */
export const loadApprovals = (): IAction => ({
  type: LOAD_APPROVALS,
  promise: API.getApprovals(),
});

/**
 * Fetches the approvals for the given document
 * @param documentId The document's ID
 */
export const loadApprovalsForDocument = (documentId: string): IAction => ({
  type: LOAD_APPROVALS_FOR_DOCUMENT,
  promise: API.getApprovalsForDocument(documentId),
  meta: {
    documentId,
  },
});

/**
 * Loads approval requests (awaiting approval) using a token to allow a non-
 * logged in user to access the information.
 *
 * Included in the response is the entire document content (transformation)
 * @param token
 */
export const loadApprovalsWithToken = (token: string): IAction => ({
  type: LOAD_EXTERNAL_APPROVALS,
  promise: API.getApprovalsWithToken(token),
});

export const approve = (
  documentId: string,
  request: ApproveRequest,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: PROCESS_APPROVAL,
  promise: API.approve(documentId, request),
  meta: {
    action: APPROVE,
    onSuccess,
    onFailure,
  },
});

export const deny = (
  documentId: string,
  request: DenyRequest,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: PROCESS_APPROVAL,
  promise: API.deny(documentId, request),
  meta: {
    action: DENY,
    onSuccess,
    onFailure,
  },
});

export const resetApprovalsState = () => ({
  type: RESET_APPROVALS_STATE,
});

// #endregion

//#region SELECTORS

export const approvalsDocumentState = (state) => state.data.review.approvals;

export const getPendingApprovals = createSelector(
  approvalsDocumentState,
  (state) => state.pending
);

export const getProcessedApprovals = createSelector(
  approvalsDocumentState,
  (state) => state.processed
);

export const getApprovalsStatus = createSelector(
  approvalsDocumentState,
  (state) => state.approvalsStatus
);

export const areApprovalsLoading = createSelector(
  getApprovalsStatus,
  (status) => isDataLoading(status)
);

export const hasApprovalsError = createSelector(getApprovalsStatus, (status) =>
  hasDataError(status)
);

export const hasApprovalsLoaded = createSelector(getApprovalsStatus, (status) =>
  hasDataLoaded(status)
);

export const getApprovalsMetadata = createSelector(
  approvalsDocumentState,
  (state) => state.metadata || {}
);

// #endregion

//#region REDUCER
export interface ApprovalsReduxState {
  pending: DocumentService.ReviewApproval[];
  processed: DocumentService.ReviewApproval[];
  approvalsStatus: string;
  metadata?: any;
}

export const initialState: ApprovalsReduxState = {
  approvalsStatus: DataStatus.NotLoaded,
  pending: [],
  processed: [],
  metadata: null,
};

const handleLoadApprovals = (state, action: any) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      approvalsStatus: DataStatus.Loading,
      pending: [],
      processed: [],
    }),
    failure: (s) => ({ ...s, approvalsStatus: DataStatus.Error }),
    success: (s) => ({
      ...s,
      approvalsStatus: DataStatus.Done,
      pending: get(action, "payload.data.pending"),
      processed: get(action, "payload.data.processed"),
    }),
  });
};

const handleLoadExternalApprovals = (state, action: any) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      approvalsStatus: DataStatus.Loading,
      pending: [],
      processed: [],
      metadata: null,
    }),
    failure: (s) => {
      const error = get(
        action,
        "payload.response.data.err.error.message",
        "error"
      );
      const approvalsStatus = error.includes("expired")
        ? INVALID_ACTION_TOKEN
        : DataStatus.Error;
      return { ...s, approvalsStatus };
    },
    success: (s) => ({
      ...s,
      approvalsStatus: DataStatus.Done,
      pending: get(action, "payload.data.pending"),
      processed: get(action, "payload.data.processed"),
      metadata: get(action, "payload.data.metadata", null),
    }),
  });
};

const handleProcessApproval = (state, action: any) => {
  return handle(state, action, {
    success: (s) => ({
      ...s,
      pending: get(action, "payload.data.pending"),
      processed: get(action, "payload.data.processed"),
    }),
  });
};

export default handleActions(
  {
    [LOAD_APPROVALS]: handleLoadApprovals,
    [LOAD_APPROVALS_FOR_DOCUMENT]: handleLoadApprovals,
    [LOAD_EXTERNAL_APPROVALS]: handleLoadExternalApprovals,
    [PROCESS_APPROVAL]: handleProcessApproval,
    [RESET_APPROVALS_STATE]: () => ({ ...initialState }),
  },
  initialState
);

// #endregion
