import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { DataStatus } from "@app/redux/utils";
import IAction from "@app/types/IAction";

//#region ACTION TYPES
export const LOAD_TICKET_METRICS = "ticket/metrics/load";
export const RESET_TICKET_METRICS = "ticket/metrics/reset";
//#endregion

//#region ACTIONS
/**
 * Loads the metrics information related to a ticket.
 * @param ticketId
 */
export const loadTicketMetrics = (ticketId: string): IAction => ({
  type: LOAD_TICKET_METRICS,
  promise: API.getTicketMetrics(ticketId),
});

export const resetTicketMetrics = (): IAction => ({
  type: RESET_TICKET_METRICS,
});
//#endregion

//#region SELECTORS
export const ticketMetricsState: (state: any) => TicketMetricsReduxState = (
  state
) => state.data.ticket.ticketMetrics;

export const getTicketMetricsStatus = createSelector(
  ticketMetricsState,
  (state) => state.metricsStatus
);

export const isTicketMetricsLoading = createSelector(
  getTicketMetricsStatus,
  (status) => status === DataStatus.Loading
);

export const hasTicketMetricsError = createSelector(
  getTicketMetricsStatus,
  (status) => status === DataStatus.Error
);

export const getTicketMetrics = createSelector(
  ticketMetricsState,
  (state) => state.metrics || {}
);

//#region REDUCER
export interface TicketMetricsReduxState {
  metrics: any;
  metricsStatus: string;
}

export const initialMetricsState: TicketMetricsReduxState = {
  metrics: {},
  metricsStatus: null,
};

export default handleActions(
  {
    [LOAD_TICKET_METRICS]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          metricsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({ ...s, metricsStatus: DataStatus.Error }),
        success: (s) => {
          return {
            ...s,
            metricsStatus: DataStatus.Done,
            metrics: get(action, "payload.data", {}),
          };
        },
      });
    },
    [RESET_TICKET_METRICS]: () => ({ ...initialMetricsState }),
  },
  initialMetricsState
);
//#endregion
