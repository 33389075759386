import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { ADD_DOCUMENT, UPLOAD_NEW_DOCUMENT } from "../actions/types";
import { RESET_COUNTERPARTY } from "@app/redux/data/counterparty";

export interface DocumentProjectReduxState {
  uploadDocumentStatus: string;
}

export const initialState: DocumentProjectReduxState = {
  // status of the document upload
  uploadDocumentStatus: null,
};

const uploadDocument = (state, action) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      uploadDocumentStatus: "uploading",
    }),
    failure: (s) => ({
      ...s,
      uploadDocumentStatus: "error",
    }),
    success: (s) => ({
      ...s,
      uploadDocumentStatus: "completed",
    }),
  });
};

export default handleActions(
  {
    [UPLOAD_NEW_DOCUMENT]: uploadDocument,
    [ADD_DOCUMENT]: uploadDocument,
    [RESET_COUNTERPARTY]: () => ({ ...initialState }),
  },
  initialState
);
