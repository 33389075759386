import axios, { AxiosResponse } from "axios";
import constants from "../utils/constants";
import { SavedSearch, SavedSearchACL } from "@app/types-business/Search";
import queryString from "query-string";

export function loadSavedSearches(
  domainObject: string,
): Promise<AxiosResponse<SavedSearch[]>> {
  const query = {
    domainObject: domainObject || undefined,
  };
  return axios.get(
    `${
      constants.BASE_URL
    }/api/proxy/api-settings/v1/saved_search?${queryString.stringify(query)}`,
    {
      withCredentials: true,
    },
  );
}

export function saveSearch(search: SavedSearch): Promise<AxiosResponse<any>> {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search`,
    search,
    {
      withCredentials: true,
    },
  );
}

export function loadSavedSearch(
  id: string,
): Promise<AxiosResponse<SavedSearch>> {
  return axios.get(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search/${id}`,
    {
      withCredentials: true,
    },
  );
}

export function updateSavedSearch(
  id: string,
  update: SavedSearch,
): Promise<AxiosResponse<SavedSearch>> {
  return axios.patch(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search/${id}`,
    update,
    {
      withCredentials: true,
    },
  );
}

export function deleteSavedSearch(id: string): Promise<AxiosResponse<any>> {
  return axios.delete(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search/${id}`,
    {
      withCredentials: true,
    },
  );
}

export function updateSavedSearchPermissions(
  id: string,
  share: SavedSearchACL[],
): Promise<AxiosResponse<any>> {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search/${id}/share`,
    { id, share },
    {
      withCredentials: true,
    },
  );
}

export function deleteSavedSearchPermission(
  id: string,
  acl: SavedSearchACL,
): Promise<AxiosResponse<any>> {
  return axios.delete(
    `${constants.BASE_URL}/api/proxy/api-settings/v1/saved_search/${id}/share`,
    {
      data: { id, share: [acl] },
      withCredentials: true,
    },
  );
}
