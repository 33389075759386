import axios from "axios";
import constants from "../utils/constants";
import {
  IConfirmContractTypeRequest,
  IConfirmCounterpartyTypeRequest,
} from "../types-business/Project";

/**
 * Confirms the document type sending a signal to workflows service
 * @param documentId
 * @param payload
 * @param type
 */
export function confirmDocumentType(
  documentId: string,
  payload: IConfirmContractTypeRequest | IConfirmCounterpartyTypeRequest,
  type: string = "insights"
): Promise<any> {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/workflows/workflows/${type}/${documentId}/signal`,
    {
      signalName: "confirm-document-type",
      payload,
    },
    {
      withCredentials: true,
    }
  );
}
