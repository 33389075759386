import { DocumentService } from "@app/types-business/documents";
import constants from "@app/utils/constants";
import { get } from "@app/utils/lodash";
import { ReviewStatus } from ".";
import { getUserEmail, getUserFullName, getUserId, User } from "../users";

/**
 * Function to get status whether turnStatus, approvalStatus or negotiationStatus
 * @param clause
 */
export const getClauseStatus = (
  clause: DocumentService.ReviewClause
): string => {
  const { negotiationStatus, turnStatus, status } = clause;
  const isDone = turnStatus === constants.TURN_STATUS_MAP.DONE;
  return isDone ? negotiationStatus : status;
};

export const getRedlineAuthors = (
  clause: DocumentService.ReviewClause
): DocumentService.Author[] => get(clause, "authors", []);

export const getApproval = (
  clause: DocumentService.ReviewClause
): DocumentService.Approval => {
  return clause?.approval;
};

export const hasExternalApprover = (approval: DocumentService.Approval) => {
  return !!get(approval, "approver.external");
};

export const getApprover = (
  issue: DocumentService.ReviewClause
): { name: string; email: string; userId?: number } | null => {
  const approval = getApproval(issue);
  if (!approval) return null;

  if (hasExternalApprover(approval))
    return (approval.approver as DocumentService.ExternalApprover).external;

  const user = get(approval.approver, "user") as User;

  if (!user) return null;

  return {
    name: getUserFullName(user),
    email: getUserEmail(user),
    userId: getUserId(user),
  };
};

export const isAwaitingApproval = (
  clause: DocumentService.ReviewClause
): boolean => {
  return getClauseStatus(clause) === ReviewStatus.AwaitingApproval;
};
