import { Api, ApiResponse } from "./_base";

/**
 * Load the ml-data clause variables information
 * @param playbookId: number
 */
export function loadClauseVariables(
  playbookId: number,
  clauseId: number,
  clauseText: string,
  documentId?: string
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/ml-data/clause-variables`,
    method: "POST",
    data: {
      playbookId,
      clauses: [{ clauseId, text: clauseText }],
      documentId,
    },
  });
}
