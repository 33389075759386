import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import { get, without } from "@app/utils/lodash";
import { questionsSchema, questionSchema } from "./schemas";
import { denormalize, normalize } from "normalizr";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import {
  AssemblyQuestion,
  AssemblyQuestionOption,
} from "@app/entities/assembly-questions";

/* #region  ACTION TYPES */
export const LOAD_ASSEMBLY_QUESTIONS = "ASSEMBLY::LOAD_ASSEMBLY_QUESTIONS";
export const DELETE_ASSEMBLY_QUESTION = "ASSEMBLY::DELETE_ASSEMBLY_QUESTION";
export const UPDATE_ASSEMBLY_QUESTION = "ASSEMBLY::UPDATE_ASSEMBLY_QUESTION";
export const CREATE_ASSEMBLY_QUESTION = "ASSEMBLY::CREATE_ASSEMBLY_QUESTION";
export const MOVE_ASSEMBLY_QUESTION = "ASSEMBLY::MOVE_ASSEMBLY_QUESTION";
export const ADD_ASSEMBLY_QUESTION_OPTION =
  "ASSEMBLY::ADD_ASSEMBLY_QUESTION_OPTION";
export const DELETE_ASSEMBLY_QUESTION_OPTION =
  "ASSEMBLY::DELETE_ASSEMBLY_QUESTION_OPTION";
export const UPDATE_ASSEMBLY_QUESTION_OPTION =
  "ASSEMBLY::UPDATE_ASSEMBLY_QUESTION_OPTION";
export const CREATE_ASSEMBLY_QUESTION_ACTION =
  "ASSEMBLY::CREATE_ASSEMBLY_QUESTION_ACTION";
export const DELETE_ASSEMBLY_QUESTION_ACTION =
  "ASSEMBLY::DELETE_ASSEMBLY_QUESTION_ACTION";
export const RESET_ASSEMBLY = "ASSEMBLY::RESET_ASSEMBLY";
/* #endregion */

/* #region ACTIONS */
export const loadQuestions = (playbookId: number) => ({
  type: LOAD_ASSEMBLY_QUESTIONS,
  promise: API.loadAssemblyQuestions(playbookId),
  meta: {
    key: playbookId,
  },
});

export const deleteQuestion = (
  questionId: number,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: DELETE_ASSEMBLY_QUESTION,
  promise: API.deleteAssemblyQuestion(questionId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateQuestion = (
  questionId: number,
  update: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_ASSEMBLY_QUESTION,
  promise: API.updateAssemblyQuestion(questionId, update),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const moveQuestion = (
  playbookId: number,
  questionId: number,
  position: number,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: MOVE_ASSEMBLY_QUESTION,
  promise: API.moveAssemblyQuestion(playbookId, questionId, position),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const createQuestion = (
  playbookId: number,
  newQuestion: any,
  onSuccess: (response: AxiosResponse<any>) => void = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_ASSEMBLY_QUESTION,
  promise: API.createAssemblyQuestion(playbookId, newQuestion),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const addQuestionOption = (
  questionId: number,
  option: string,
  onSuccess: (response: AxiosResponse<any>) => void = NOOP,
  onFailure = NOOP
) => ({
  type: ADD_ASSEMBLY_QUESTION_OPTION,
  promise: API.addAssemblyQuestionOption(questionId, option),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteQuestionOption = (
  option: AssemblyQuestionOption,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: DELETE_ASSEMBLY_QUESTION_OPTION,
  promise: API.deleteAssemblyQuestionOption(option.id),
  meta: {
    option,
    onSuccess,
    onFailure,
  },
});

export const updateQuestionOption = (
  optionId: number,
  update: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_ASSEMBLY_QUESTION_OPTION,
  promise: API.updateAssemblyQuestionOption(optionId, update),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const createQuestionAction = (
  optionId: number,
  action: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_ASSEMBLY_QUESTION_ACTION,
  promise: API.createAssemblyQuestionAction(optionId, action),
  meta: {
    optionId,
    onSuccess,
    onFailure,
  },
});

export const deleteQuestionAction = (
  actionId: number,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: DELETE_ASSEMBLY_QUESTION_ACTION,
  promise: API.deleteAssemblyQuestionAction(actionId),
  meta: {
    actionId,
    onSuccess,
    onFailure,
  },
});

export const resetAssembly = () => ({
  type: RESET_ASSEMBLY,
});
/* #endregion */

/* #region SELECTORS  */
export const assemblyQuestionsState = (state) =>
  state.data.assembly.assemblyQuestions;

export const getQuestionIds = createSelector(
  assemblyQuestionsState,
  (state) => state.questionIds
);

export const getQuestions = createSelector(
  assemblyQuestionsState,
  (state) => state.questions
);

export const getQuestionOptions = createSelector(
  assemblyQuestionsState,
  (state) => state.options
);

export const getQuestionActions = createSelector(
  assemblyQuestionsState,
  (state) => state.actions
);

export const getQuestionsList = createSelector(
  getQuestionIds,
  getQuestions,
  getQuestionOptions,
  getQuestionActions,
  (questionIds, questions, options, actions) => {
    return denormalize(questionIds, questionsSchema, {
      questions,
      options,
      actions,
    });
  }
);

export const getQuestionsStatus = createSelector(
  assemblyQuestionsState,
  (state) => state.questionsStatus
);

export const isQuestionsLoading = createSelector(
  getQuestionsStatus,
  (status) => status === "loading"
);

export const hasQuestionsError = createSelector(
  getQuestionsStatus,
  (status) => status === "error"
);

export const hasQuestionsLoaded = createSelector(
  getQuestionsStatus,
  (status) => status === "completed"
);

/**
 * Returns an array of clause IDs that are in use by assembly questions
 */
export const getUsedAssemblyClauseIds = createSelector(
  getQuestionActions,
  (actionsMap) => {
    return Object.values(actionsMap || {}).map(
      (action: any) => action.playbookClauseId
    );
  }
);

const getResourceKey = createSelector(
  assemblyQuestionsState,
  (state) => state.key
);
/* #endregion  */

/* #region  HOOKS */
export const useAssemblyQuestions = (playbookId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getQuestionsStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || (!!key && key !== playbookId)) {
      dispatch(loadQuestions(playbookId));
    }
  }, [playbookId, dispatch]);

  const questions: AssemblyQuestion[] = useSelector(getQuestionsList);
  const isLoading: boolean = useSelector(isQuestionsLoading);
  const hasError: boolean = useSelector(hasQuestionsError);
  const hasLoaded: boolean = useSelector(hasQuestionsLoaded);

  const status: any = { isLoading, hasError, hasLoaded };

  const actions = {
    loadQuestions,
    deleteQuestion,
    updateQuestion,
    createQuestion,
    moveQuestion,
  };
  return [questions, status, actions] as const;
};

export const useAssemblyQuestion = (questionId: number) => {
  const questions: AssemblyQuestion[] = useSelector(getQuestionsList);
  return (questions || []).find((q) => q.id === questionId);
};

/* #endregion */

/* #region  REDUCER  */
export interface AssemblyQuestionsReducerState {
  actions: any;
  options: any;
  questions: any[];
  questionIds: number[];
  questionsStatus: DataStatus;
  key: number; // resource key (playbookId) to help us keep track of which playbook's questions are loaded
}

export const initialState: AssemblyQuestionsReducerState = {
  actions: null,
  options: null,
  questions: null,
  questionIds: [],
  questionsStatus: DataStatus.NotLoaded,
  key: null,
};

const handleLoadQuestions = (action) => (s) => {
  const { entities, result } = normalize(
    get(action, "payload.data", []),
    questionsSchema
  );
  const key = get(action, "meta.key", s.key);
  return {
    ...s,
    questions: entities.questions,
    questionIds: result,
    options: entities.options,
    actions: entities.actions,
    questionsStatus: DataStatus.Done,
    key,
  };
};

const handleUpdateStatus = (status: string) => (s) => ({
  ...s,
  questionsStatus: status,
});

export default handleActions(
  {
    [LOAD_ASSEMBLY_QUESTIONS]: (state, action) => {
      return handle(state, action, {
        start: handleUpdateStatus(DataStatus.Loading),
        failure: handleUpdateStatus(DataStatus.Error),
        success: handleLoadQuestions(action),
      });
    },
    [CREATE_ASSEMBLY_QUESTION]: (state, action) => {
      return handle(state, action, {
        start: handleUpdateStatus("loading"),
        failure: handleUpdateStatus(null),
        success: (s) => {
          const { entities, result } = normalize(
            get(action, "payload.data"),
            questionSchema
          );

          const { questions, options } = entities;

          return {
            ...s,
            questionsStatus: DataStatus.Done,
            questions: {
              ...s.questions,
              ...questions,
            },
            options: {
              ...s.options,
              ...options,
            },
            questionIds: [...s.questionIds, result],
          };
        },
      });
    },
    [UPDATE_ASSEMBLY_QUESTION]: (state, action) => {
      return handle(state, action, {
        start: handleUpdateStatus("loading"),
        failure: handleUpdateStatus(null),
        success: (s) => {
          const { entities } = normalize(
            get(action, "payload.data"),
            questionSchema
          );

          return {
            ...s,
            questions: {
              ...s.questions,
              ...entities.questions,
            },
            questionsStatus: DataStatus.Done,
          };
        },
      });
    },
    [DELETE_ASSEMBLY_QUESTION]: (state, action: any) => {
      return handle(state, action, {
        start: handleUpdateStatus("loading"),
        failure: handleUpdateStatus(null),
        success: (s) => {
          const deleteQuestionId = action.meta.questionId;
          const questions = { ...s.questions };
          delete questions[deleteQuestionId];
          return {
            ...s,
            questionsStatus: DataStatus.Done,
            questions,
            questionIds: without(s.questionIds, deleteQuestionId),
          };
        },
      });
    },
    [MOVE_ASSEMBLY_QUESTION]: (state, action) => {
      return handle(state, action, {
        start: handleUpdateStatus(DataStatus.Loading),
        failure: handleUpdateStatus(null),
        success: handleLoadQuestions(action),
      });
    },
    [ADD_ASSEMBLY_QUESTION_OPTION]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          const [option] = get(action, "payload.data") || [];
          const question = s.questions[option.questionId];
          return {
            ...s,
            options: {
              ...s.options,
              [option.id]: option,
            },
            questions: {
              ...s.questions,
              [question.id]: {
                ...question,
                options: [...question.options, option.id],
              },
            },
          };
        },
      });
    },
    [DELETE_ASSEMBLY_QUESTION_OPTION]: (state, action: any) => {
      return handle(state, action, {
        success: (s) => {
          const deleteOptionId = action.meta.option.id;
          const options = { ...s.options };
          delete options[deleteOptionId];
          const question = s.questions[action.meta.option.questionId];

          // remove actions associated with the deleted option
          const actions = { ...(s.actions || {}) };
          const actionIdsToDelete = Object.values(s.actions || {})
            .filter((action: any) => action?.optionId === deleteOptionId)
            .map((action: any) => action.id);
          actionIdsToDelete.forEach((actionId) => {
            delete actions[actionId];
          });

          return {
            ...s,
            actions,
            options,
            questions: {
              ...s.questions,
              [question.id]: {
                ...question,
                options: without(question.options, deleteOptionId),
              },
            },
          };
        },
      });
    },
    [UPDATE_ASSEMBLY_QUESTION_OPTION]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          const option: any = get(action, "payload.data") || {};
          return {
            ...s,
            options: {
              ...s.options,
              [option.id]: option,
            },
          };
        },
      });
    },
    [CREATE_ASSEMBLY_QUESTION_ACTION]: (state, action: any) => {
      return handle(state, action, {
        start: handleUpdateStatus("loading"),
        failure: handleUpdateStatus(null),
        success: (s) => {
          const [newAction] = action.payload.data;
          const option = s.options[newAction.optionId];
          return {
            ...s,
            questionsStatus: DataStatus.Done,
            actions: {
              ...s.actions,
              [newAction.id]: newAction,
            },
            options: {
              ...s.options,
              [option.id]: {
                ...option,
                actions: [...option.actions, newAction.id],
              },
            },
          };
        },
      });
    },
    [DELETE_ASSEMBLY_QUESTION_ACTION]: (state, action: any) => {
      return handle(state, action, {
        start: handleUpdateStatus("loading"),
        failure: handleUpdateStatus(null),
        success: (s) => {
          const deleteAction = s.actions[action.meta.actionId];
          const actions = { ...s.actions };
          delete actions[deleteAction.id];

          const option = s.options[deleteAction.optionId];
          return {
            ...s,
            questionsStatus: DataStatus.Done,
            actions,
            options: {
              ...s.options,
              [option.id]: {
                ...option,
                actions: without(option.actions, deleteAction.id),
              },
            },
          };
        },
      });
    },
    [RESET_ASSEMBLY]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
