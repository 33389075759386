import { createSelector } from "reselect";

const downloaderState = (state) => state.downloader;

export const isDownloading = createSelector(
  downloaderState,
  (state) => state.downloadFileStatus === "loading"
);

export const getFileName = createSelector(
  downloaderState,
  (state) => state.fileName || ""
);
