import { DocumentService } from "@app/types-business/documents";
import { Api, ApiResponse } from "./_base";
import { LsrUser } from "@app/entities/legalsifter-review";

export async function getLsrDocument(
  lsrDocumentUuid: string
): Promise<ApiResponse<DocumentService.Document>> {
  if (!lsrDocumentUuid) return Promise.resolve({ data: null });
  const response: any = await Api.execute({
    url: `/api/lsr/documents/${lsrDocumentUuid}`,
  });
  return response.data;
}

export async function getLsrDocumentTypes(): Promise<ApiResponse<any>> {
  const response: any = await Api.execute({
    url: "/api/lsr/document-types",
  });
  return response.data;
}

export async function publishToLsr(
  documentId: string,
  lsrDocumentType: string
): Promise<ApiResponse<any>> {
  const response: any = await Api.execute({
    url: `/api/review/documents/${documentId}/sift`,
    data: { documentTypeUuid: lsrDocumentType },
    method: "POST",
  });
  return response.data;
}

export async function getLsrOrganization(
  lsrOrganizationId: string
): Promise<ApiResponse<any>> {
  const response: any = await Api.execute({
    url: `/api/lsr/organization/${lsrOrganizationId}`,
  });

  return response.data;
}

export async function shareLsrDocument(
  lsrDocumentUuid: string,
  email: string
): Promise<ApiResponse<any>> {
  const response: any = await Api.execute({
    url: `/api/lsr/documents/${lsrDocumentUuid}/share`,
    data: { email },
    method: "POST",
  });
  return response.data;
}

export async function getLsrUsers(lsrOrganizationId: string) {
  const response: any = await Api.execute({
    url: `/api/lsr/organization/${lsrOrganizationId}/users`,
  });
  return response.data;
}

export async function getLsrUser(userEmail: string) {
  const response: any = await Api.execute({
    url: `/api/lsr/users/${encodeURIComponent(userEmail)}`,
  });
  return response.data;
}

export async function updateLsrUser(
  userEmail: string,
  update: Partial<LsrUser>
): Promise<LsrUser> {
  const response: any = await Api.execute({
    url: `/api/lsr/users/${encodeURIComponent(userEmail)}`,
    data: update,
    method: "PATCH",
  });
  return response.data;
}
