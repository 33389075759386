export const LOAD_DOCUMENT = "AKORDA::LOAD_DOCUMENT";
export const RESET_DOCUMENT_STATE = "AKORDA::RESET_DOCUMENT_STATE";
export const SET_COMMENTS = "AKORDA::SET_COMMENTS";
export const SHOW_COMMENT = "AKORDA::SHOW_COMMENT";
export const HIDE_COMMENT = "AKORDA::HIDE_COMMENT";
export const TOGGLE_COMMENTS_PANEL = "AKORDA::TOGGLE_COMMENTS_PANEL";
export const SHOW_COMMENTS_PANEL = "AKORDA::SHOW_COMMENTS_PANEL";
export const HIDE_COMMENTS_PANEL = "AKORDA::HIDE_COMMENTS_PANEL";
export const SELECT_COMMENT = "AKORDA::SELECT_COMMENT";
export const DESELECT_COMMENT = "AKORDA::DESELECT_COMMENT";
export const ADD_DOCUMENT = "AKORDA::ADD_DOCUMENT";
export const UPLOAD_NEW_DOCUMENT = "AKORDA::UPLOAD_NEW_DOCUMENT";
export const SET_COMMENT_INPUT_ID = "AKORDA::SET_COMMENT_INPUT_ID";
export const RESET_COMMENT_INPUT_ID = "AKORDA::RESET_COMMENT_INPUT_ID";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const COMMENT_ADDED = "AKORDA-REVIEW::COMMENT_ADDED";
export const COMMENT_DISCARDED = "AKORDA-REVIEW::COMMENT_DISCARDED";
export const ASSIGN_DOCUMENT = "ASSIGN_DOCUMENT";
export const REVERT_DOCUMENT = "REVERT_DOCUMENT";
export const TOGGLE_PIN_COMMENT = "AKORDA-REVIEW::TOGGLE_PIN_COMMENT";
