import { get, isInteger } from "@app/utils/lodash";
import moment from "moment-timezone";
import constants from "./constants";
import { isNumberValue } from "./helpers";

const numSecondsInDay = 24 * 60 * 60;
const numMillisecondsInDay = numSecondsInDay * 1000;
const epochAsSerial = 25569;

export type FormatDateOptions = {
  format?: string | undefined;
  showTime?: boolean;
  isAbsolute?: boolean;
  isRelative?: boolean;
};

const { DEFAULT_DATE_FORMAT } = constants;

const timeFormat = "h:mm a";

const optionDefaults: FormatDateOptions = {
  format: undefined,
  showTime: false,
  isAbsolute: false,
  isRelative: false,
};

/**
 *
 * @param date
 * @param options
 * @returns a formatted string value
 */
export const formatDate = (
  date: string | number | Date,
  options: FormatDateOptions = {}
): string => {
  if (!date) {
    return null;
  }

  // If the number is an integer and it's not in milliseconds (default), convert it to milliseconds
  // The rationale here is that moment() auto handles a millisecond value, but you must use moment().unix() for
  // a seconds-based value. The logic is therefore simpler if we convert to milliseconds.
  if (isNumberValue(date)) {
    date = ensureMillsecondsTimestamp(+date as number);
  }

  const opts = {
    ...optionDefaults,
    ...options,
  };

  if (!opts.format) {
    opts.format = opts.showTime
      ? `${DEFAULT_DATE_FORMAT} ${timeFormat}`
      : DEFAULT_DATE_FORMAT;
  }

  const momentDate = opts.isAbsolute ? moment.utc(date) : moment(date);

  return opts.isRelative
    ? momentDate.fromNow()
    : momentDate.format(opts.format);
};

/**
 * This utility converts an date (string or epoch timestamp) to a JS Date object.
 * @param value (string or integer)
 * @param isAbsolute defaults to false. When true, this indicates that the value should be treated as a UTC value and converted to JS
 * Date object that is absolute from a local perspective. For example: Feb 22 2019 00:00:00 UTC would normally become the date object
 * February 21, 2019 4:00:00 PM GMT-08:00 for California users, which is the exact same time as UTC, but in local CA PST timezone.
 *
 * But there are times when we want a JS object that is still Feb 22 2019 00:00:00, but as local, meaning the actual UTC
 * value would be Feb 22, 2019 08:00:00).
 *
 * This is primarily useful for controls (like date pickers) that depend on a JS Date object. Here we trick the date
 * picker control by pre-converting the date into a "local absolute date" (i.e., same time/day/month/year as UTC, but local). Then
 * typically we convert it again outbound to be UTC midnight.
 * @returns Date or null
 */
export const convertToJSDate = (
  value: string | number,
  isAbsolute: boolean = false
) => {
  if (!value || !moment(value).isValid()) {
    return null;
  }

  if (isAbsolute) {
    const utc = moment.utc(value);
    // create a new js date instance that is the absolute date (from a local perspective)
    return new Date(utc.year(), utc.month(), utc.date(), 0, 0, 0);
  } else {
    return moment(value).toDate();
  }
};

/**
 * Converts a value to a unix timestamp (milliseconds).
 * @param value Either a date, a timestamp (in ms) or a date string
 * @param options Provides for converting the timestamp to start of given day or end of given day using
 * `{ startOfDay: true }` or `{ endOfDay: true }`, as well as for treating the date as an absolute date
 * meaning we treat it as a UTC value converted to midnight (unless endOfDay is supplied as true)
 */
export function convertToUnixTimestamp(
  value: Date | number | string,
  options: {
    startOfDay?: boolean;
    endOfDay?: boolean;
    isAbsolute?: boolean;
  } = {}
) {
  if (!value) {
    return null;
  }

  let momentDate = moment(value);

  if (options.isAbsolute) {
    momentDate = moment.utc().set({
      year: momentDate.year(),
      month: momentDate.month(),
      date: momentDate.date(),
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  if (get(options, "startOfDay", false)) {
    momentDate = momentDate.startOf("day");
  } else if (get(options, "endOfDay", false)) {
    momentDate = momentDate.endOf("day");
  }
  return momentDate.valueOf();
}

/**
 * We often encounter two different types of timestamps (seconds and milliseconds).
 * It's relatively safe to assume that any timestamp with greater than or equal to 1 trillion is a millisecond timestamp,
 * and anything less is not a timestamp or not in milliseconds.
 * @param timestamp Either a seconds or milliseconds based timestamp
 * @returns true if a millisecond unix timestamp, otherwise false
 */
export const isUnixTimestampMilliseconds = (timestamp: number) => {
  if (!isInteger(timestamp)) return false;
  return timestamp > 100000000000; // most unix timestamps in milliseconds will be greater than this value, which is March 3, 1973.
};

/**
 * Converts a timestamp to milliseconds, if necessary
 * @param timestamp Either a seconds or milliseconds based timestamp
 * @returns a milliseconds based timestamp
 */
export const ensureMillsecondsTimestamp = (timestamp: number): number => {
  return isUnixTimestampMilliseconds(timestamp) ? timestamp : timestamp * 1000;
};

/**
 * Serial dates are numbers that represent the number of days since January 1, 1900. They are
 * often used in spreadsheets as date value.
 * @param timestamp
 * @returns a serial date
 */
export const convertTimestampToSerialDate = (timestamp: number) => {
  const msTimestamp = ensureMillsecondsTimestamp(timestamp);
  const daysSinceEpoch = msTimestamp / numMillisecondsInDay;
  const serial = epochAsSerial + daysSinceEpoch;
  return serial;
};

export function daysUntil(date: string | number): number {
  const utcDate = convertToUnixTimestamp(date, { isAbsolute: true });
  const now = moment.utc().valueOf();
  const diff = utcDate - now;
  return Math.ceil(diff / numMillisecondsInDay);
}

export const formatTimeString = (timeStr: string) => {
  return timeStr.replace(/([dhm])/g, "$1 ").trim();
};
