import * as API from "@app/API";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import queryString from "query-string";
import { get, isEmpty, forEach } from "@app/utils/lodash";
import {
  PlaybookSearchResult,
  PlaybookSearchResultSummary,
} from "@app/entities/playbook/playbook-search-results";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// #region ACTION TYPES
export const LOAD_PLAYBOOK_SEARCH_RESULTS = "playbook/search/load";
export const RESET_PLAYBOOK_SEARCH = "playbook/search/reset";

// #region ACTIONS
export const loadPlaybookSearchResults = (
  playbookId: number,
  search: string
): any => {
  return {
    type: LOAD_PLAYBOOK_SEARCH_RESULTS,
    promise: API.loadSearchResults(
      playbookId,
      queryString.stringify({ search })
    ),
    meta: {
      dataKey: `${playbookId}-${search}`,
    },
  };
};

export const resetPlaybookSearchResults = () => ({
  type: RESET_PLAYBOOK_SEARCH,
});
// #endRegion

// #region SELECTORS
const playbookSearchState = (state) => state.data.playbook.playbookSearch;

export const getPlaybookSearchStatus = createSelector(
  playbookSearchState,
  (state) => state.playbookSearchStatus
);

export const isPlaybookSearchLoading = createSelector(
  getPlaybookSearchStatus,
  (status) => status === DataStatus.Loading
);

export const hasPlaybookSearchError = createSelector(
  getPlaybookSearchStatus,
  (status) => status === DataStatus.Error
);

export const hasPlaybookSearchLoaded = createSelector(
  getPlaybookSearchStatus,
  (status) => status === DataStatus.Done
);

export const getPlaybookSearchResultSummary = createSelector(
  playbookSearchState,
  (state) =>
    state.playbookSearchResultSummary || {
      issues: [],
      clauses: [],
      hitTerms: [],
      totalIssues: 0,
      totalClauses: 0,
    }
);

export const getDataKey = createSelector(
  playbookSearchState,
  (state) => state.dataKey
);

// #endRegion

// #region HOOKS
export const usePlaybookSearch = (playbookId: number, search: string) => {
  const dispatch = useDispatch();

  const resultsSummary = useSelector(getPlaybookSearchResultSummary);
  const isLoading = useSelector(isPlaybookSearchLoading);
  const hasError = useSelector(hasPlaybookSearchError);
  const hasLoaded = useSelector(hasPlaybookSearchLoaded);

  useEffect(() => {
    if (playbookId && search) {
      const status = runSelector(getPlaybookSearchStatus);
      const key = runSelector(getDataKey);
      if (isDataNotLoaded(status) || key !== playbookId) {
        dispatch(loadPlaybookSearchResults(playbookId, search));
      }
    } else {
      dispatch(resetPlaybookSearchResults());
    }
  }, [playbookId, search, dispatch]);

  return [resultsSummary, { isLoading, hasError, hasLoaded }];
};
// #endRegion

//#region REDUCER

// converts a search result into a consistent/normalized format
const normalizeSearchResults = (
  results,
  isClauseType
): PlaybookSearchResult => {
  return (results || []).map((result: any) => {
    const clauseNumber = result.listNumber || result.clauseListNumber;
    let clauseName = result.name || result.clauseName;
    clauseName = !isEmpty(clauseNumber)
      ? `${clauseNumber}. ${clauseName}`
      : clauseName;
    const id = isClauseType ? result.id : result.issueId;
    return {
      ...result,
      id,
      title: isClauseType ? clauseName : result.title,
      subTitle: isClauseType ? null : clauseName,
      text: (result.hits || []).join(" ..... "),
      isOptional: result.optional || result.clauseOptional,
    };
  });
};

const hitRegex = /<em>(.*?)<\/em>/g;
const registerHitsTerms = (hits = [], terms = []) => {
  (hits || []).forEach((hit: string) => {
    hit
      .match(hitRegex)
      .map((val) => val.replace(/<\/?em>/g, "").toLowerCase())
      .forEach((hitText) => {
        if (!terms.includes(hitText)) {
          terms.push(hitText);
        }
      });
  });
};

const findHitTerms = (resultList) => {
  if (!resultList) return [];
  const terms = [];
  const { clauses, issues } = resultList;
  forEach(clauses, ({ hits }) => registerHitsTerms(hits, terms));
  forEach(issues, ({ hits }) => registerHitsTerms(hits, terms));
  return terms;
};

export interface PlaybookSearchState {
  dataKey: string;
  playbookSearchStatus: DataStatus;
  playbookSearchResultSummary: PlaybookSearchResultSummary;
}

export const initialState: PlaybookSearchState = {
  dataKey: null,
  playbookSearchStatus: DataStatus.NotLoaded,
  playbookSearchResultSummary: null,
};

export default handleActions(
  {
    [RESET_PLAYBOOK_SEARCH]: () => ({
      ...initialState,
    }),
    [LOAD_PLAYBOOK_SEARCH_RESULTS]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookSearchState): PlaybookSearchState => ({
          ...s,
          playbookSearchStatus: DataStatus.Loading,
        }),
        failure: (s: PlaybookSearchState): PlaybookSearchState => ({
          ...s,
          playbookSearchStatus: DataStatus.Error,
        }),
        success: (s: PlaybookSearchState): PlaybookSearchState => {
          const data: any = get(action, "payload.data");
          return {
            ...s,
            dataKey: get(action, "meta.dataKey"),
            playbookSearchStatus: DataStatus.Done,
            playbookSearchResultSummary: {
              ...data,
              issues: normalizeSearchResults(data.issues, false),
              clauses: normalizeSearchResults(data.clauses, true),
              hitTerms: findHitTerms(data),
            },
          };
        },
      });
    },
  },
  initialState
);
//#endregion
