export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";

const types = {
  PUSH_EVENT: "PUSH_EVENT",
  POP_EVENT: "POP_EVENT",
  POP_EVENTS: "POP_EVENTS",
  PURGE_EVENTS: "PURGE_EVENTS",
  UPDATE_MY_PROFILE,
};

export default types;
