import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { JSONSchema7 } from "json-schema";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";

const ORDER_FORM_CONFIGURATION = "ORDER_TABLES";

// #region ACTION TYPES
export const LOAD_ORDER_TABLES_SCHEMA = "order-tables-schema/load";
export const RESET_ORDER_TABLES_SCHEMA = "order-tables-schema/reset";
// #endregion

//#region ACTIONS
export const loadOrderTablesSchema = (): any => ({
  type: LOAD_ORDER_TABLES_SCHEMA,
  promise: API.loadSchema(ORDER_FORM_CONFIGURATION),
});

export const resetOrderTablesSchema = (): IAction => ({
  type: RESET_ORDER_TABLES_SCHEMA,
});
//#endregion

//#region SELECTORS
export const orderTablesSchemaState = (state) =>
  state.data.orderTables.orderTablesSchema;

export const getOrderTablesSchema = createSelector(
  orderTablesSchemaState,
  (state) => get(state, "orderTablesSchema") || {}
);

export const getOrderTablesSchemaStatus = createSelector(
  orderTablesSchemaState,
  (state) => state.orderTablesSchemaStatus
);

export const isOrderTablesSchemaLoading = createSelector(
  getOrderTablesSchemaStatus,
  (status) => status === DataStatus.Loading
);

export const hasOrderTablesSchemaError = createSelector(
  getOrderTablesSchemaStatus,
  (status) => status === DataStatus.Error
);

export const hasOrderTablesSchemaLoaded = createSelector(
  getOrderTablesSchemaStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region HOOKS
export const useOrderTablesSchema = () => {
  const dispatch = useDispatch();

  const orderTablesSchema = useSelector(getOrderTablesSchema);
  const isLoading = useSelector(isOrderTablesSchemaLoading);
  const hasError = useSelector(hasOrderTablesSchemaError);
  const hasLoaded = useSelector(hasOrderTablesSchemaLoaded);

  useEffect(() => {
    const status = runSelector(getOrderTablesSchemaStatus);
    if (isDataNotLoaded(status)) {
      dispatch(loadOrderTablesSchema());
    }
  }, [dispatch]);

  return [
    orderTablesSchema,
    {
      isLoading,
      hasError,
      hasLoaded,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface OrderTablesSchemaState {
  orderTablesSchema: JSONSchema7;
  orderTablesSchemaStatus: DataStatus;
}

export const initialState: OrderTablesSchemaState = {
  orderTablesSchema: null,
  orderTablesSchemaStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_ORDER_TABLES_SCHEMA]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          orderTablesSchemaStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          orderTablesSchemaStatus: DataStatus.Error,
        }),
        success: (s) => {
          const orderTablesSchema = get(action, "payload.data.schema");
          return {
            ...s,
            orderTablesSchemaStatus: DataStatus.Done,
            orderTablesSchema,
          };
        },
      }),
    [RESET_ORDER_TABLES_SCHEMA]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
