import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get, orderBy } from "@app/utils/lodash";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
} from "@app/redux/utils";
import { createSelector } from "@reduxjs/toolkit";
import { denormalize, normalize } from "normalizr";
import { ticketTypeListSchema } from "./schemas";
import { Ticket } from "@app/entities/tickets/tickets";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { NOOP } from "@app/utils/helpers";
import { CustomTicketTypeConfiguration } from "@app/entities/tickets/customTypes";
import { SettingsEntity } from "@app/entities/settings";

// #region ACTION TYPES
export const LOAD_TICKET_TYPES = "ticket-types/load";
export const RESET_TICKET_TYPES = "ticket-types/reset";
export const CREATE_TICKET_TYPE = "ticket-types/create";
export const UPDATE_TICKET_TYPE = "ticket-types/update";
export const DELETE_TICKET_TYPE = "ticket-types/delete";
// #endregion

// #region ACTIONS
export const loadTicketTypes = (): IAction => {
  return {
    type: LOAD_TICKET_TYPES,
    promise: API.loadEntities("TICKET_TYPE"),
  };
};

export const resetTicketTypes = (): IAction => ({
  type: RESET_TICKET_TYPES,
});

export const createTicketType = (
  ticketType: CustomTicketTypeConfiguration,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_TICKET_TYPE,
  promise: API.createEntity("TICKET_TYPE", ticketType, true),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateTicketType = (
  ticketTypeEntityId: string,
  ticketType: Partial<SettingsEntity<CustomTicketTypeConfiguration>>,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_TICKET_TYPE,
  promise: API.updateEntity(ticketTypeEntityId, ticketType),
  meta: {
    onSuccess,
    onFailure,
  },
});

// #endregion

// #region SELECTORS
export const ticketsState = (state) => state.data.ticket.ticketTypes;

export const getTicketTypesMap = createSelector(
  ticketsState,
  (state) => get(state, "ticketTypes") || {}
);

export const getTicketTypeIds = createSelector(
  ticketsState,
  (state) => get(state, "ticketTypeIds") || []
);

export const getTicketTypes = createSelector(
  getTicketTypesMap,
  getTicketTypeIds,
  (ticketTypes, ids) => {
    return denormalize(ids, ticketTypeListSchema, { ticketTypes });
  }
);

export const getTicketTypesStatus = createSelector(
  ticketsState,
  (state) => state.status
);

export const areTicketTypesLoading = createSelector(
  getTicketTypesStatus,
  isDataLoading
);

export const hasTicketTypesError = createSelector(
  getTicketTypesStatus,
  hasDataError
);

export const hasTicketTypesLoaded = createSelector(
  getTicketTypesStatus,
  hasDataLoaded
);
// #endregion

export const useTicketTypes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTicketTypes());
  }, [dispatch]);

  const isLoading = useSelector(areTicketTypesLoading);
  const hasError = useSelector(hasTicketTypesError);
  const hasLoaded = useSelector(hasTicketTypesLoaded);
  const ticketTypes = useSelector(getTicketTypes);
  const ticketTypesMap = useSelector(getTicketTypesMap);
  return useMemo(
    () => [ticketTypes, ticketTypesMap, { isLoading, hasError, hasLoaded }],
    [ticketTypes, ticketTypesMap, isLoading, hasError, hasLoaded]
  );
};

//#region REDUCER
export interface TicketTypesReduxState {
  status: DataStatus;
  ticketTypes: Record<string, Ticket>;
  ticketTypeIds: string[];
}

export const initialState: TicketTypesReduxState = {
  status: DataStatus.NotLoaded,
  ticketTypes: {},
  ticketTypeIds: [],
};

export default handleActions(
  {
    [LOAD_TICKET_TYPES]: (state, action) => {
      return handle(state, action, {
        start: (s: TicketTypesReduxState): TicketTypesReduxState => ({
          ...s,
          status: DataStatus.Loading,
        }),
        failure: (s: TicketTypesReduxState): TicketTypesReduxState => ({
          ...s,
          status: DataStatus.Error,
        }),
        success: (s: TicketTypesReduxState): TicketTypesReduxState => {
          const data = orderBy(
            get(action, "payload.data.entities") || [],
            ["data.position", "createdDate"],
            ["asc"]
          );
          const { entities, result } = normalize(
            data || [],
            ticketTypeListSchema
          );
          return {
            ...s,
            ticketTypes: entities.ticketTypes,
            ticketTypeIds: result,
            status: DataStatus.Done,
          };
        },
      });
    },
    [CREATE_TICKET_TYPE]: (state, action) =>
      handle(state, action, {
        start: (s): TicketTypesReduxState => ({
          ...s,
          status: DataStatus.Submitting,
        }),
        failure: (s): TicketTypesReduxState => ({
          ...s,
          status: DataStatus.Done,
        }),
        success: (s): TicketTypesReduxState => {
          const newTicketType: any = get(action, "payload.data");
          return {
            ...s,
            status: DataStatus.Done,
            ticketTypeIds: [...s.ticketTypeIds, newTicketType.id],
            ticketTypes: {
              ...s.ticketTypes,
              [newTicketType.id]: newTicketType,
            },
          };
        },
      }),
    [UPDATE_TICKET_TYPE]: (state, action) =>
      handle(state, action, {
        success: (s): TicketTypesReduxState => {
          const ticketType: any = get(action, "payload.data");
          const ticketTypes = {
            ...s.ticketTypes,
            [ticketType.id]: ticketType,
          };
          return {
            ...s,
            ticketTypes,
          };
        },
      }),
    [RESET_TICKET_TYPES]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
