import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";

// #region ACTION TYPES
export const LOAD_ASSEMBLY_DATA = "assembly-data/load";
export const RESET_ASSEMBLY_DATA = "order-forms/reset";
// #endregion

//#region ACTIONS
export const loadAssemblyData = (entityId: string): any => ({
  type: LOAD_ASSEMBLY_DATA,
  promise: API.loadEntity(entityId),
});

export const resetAssemblyData = (): IAction => ({
  type: RESET_ASSEMBLY_DATA,
});

//#endregion

//#region SELECTORS
export const assemblyDataState = (state) => state.data.assembly.assemblyData;

export const getAssemblyData = createSelector(assemblyDataState, (state) =>
  get(state, "assemblyData.data")
);

export const getAssemblyDataStatus = createSelector(
  assemblyDataState,
  (state) => state.assemblyDataStatus
);

export const isAssemblyDataLoading = createSelector(
  getAssemblyDataStatus,
  (status) => status === DataStatus.Loading
);

export const hasAssemblyDataError = createSelector(
  getAssemblyDataStatus,
  (status) => status === DataStatus.Error
);

export const hasAssemblyDataLoaded = createSelector(
  getAssemblyDataStatus,
  (status) => status === DataStatus.Done
);

export const getResourceKey = createSelector(
  assemblyDataState,
  (state) => state.key
);

//#endregion

//#region HOOKS
export const useAssemblyData = (entityId: string) => {
  const dispatch = useDispatch();

  const assemblyData = useSelector(getAssemblyData);
  const isLoading = useSelector(isAssemblyDataLoading);
  const hasError = useSelector(hasAssemblyDataError);
  const hasLoaded = useSelector(hasAssemblyDataLoaded);

  useEffect(() => {
    if (!entityId) return;
    const status = runSelector(getAssemblyDataStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || key !== entityId) {
      dispatch(loadAssemblyData(entityId));
    }
  }, [entityId, dispatch]);

  return [
    assemblyData,
    {
      isLoading,
      hasError,
      hasLoaded,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface AssemblyDataState {
  assemblyData: any;
  assemblyDataStatus: DataStatus;
  key: string;
}

export const initialState: AssemblyDataState = {
  assemblyData: null,
  assemblyDataStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [LOAD_ASSEMBLY_DATA]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          assemblyDataStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          assemblyDataStatus: DataStatus.Error,
        }),
        success: (s) => {
          const assemblyData = get(action, "payload.data") || null;
          return {
            ...s,
            assemblyDataStatus: DataStatus.Done,
            assemblyData,
          };
        },
      }),
    [RESET_ASSEMBLY_DATA]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
