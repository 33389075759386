import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { DOWNLOAD_FILE } from "../../actionTypes";
import get from "lodash/get";

export const initialState = {
  // url of download file
  url: null,
  // status of download file
  downloadFileStatus: null,
  // file name
  fileName: null,
};

export default handleActions(
  {
    [DOWNLOAD_FILE]: (state, action: any) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          url: action.meta,
          downloadFileStatus: "loading",
          fileName: null,
        }),
        success: (s, action) => ({
          ...s,
          url: null,
          downloadFileStatus: "success",
          fileName: get(
            action,
            "payload.headers.x-suggested-filename",
            get(action, "payload.data.name", "")
          ),
        }),
        failure: (s) => ({
          ...s,
          url: null,
          downloadFileStatus: "error",
        }),
      });
    },
  },
  initialState
);
