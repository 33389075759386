import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { LOAD_COMPANIES, RESET_COMPANY_STATE } from "../actions/types";
import commonActionTypes from "../../_Home/actionTypes";
import { Company } from "@app/entities/companies";
import get from "lodash/get";
import pick from "lodash/pick";
import { IInsightsPagination } from "../../types-business/Insights";

const defaultPagination = {
  page: 0,
  size: 20,
  totalPages: 0,
  totalElements: 0,
};

export interface State {
  companies: Company[];
  companiesStatus: string;
  paginationInfo: IInsightsPagination;
}

export const initialState: State = {
  companies: [],
  companiesStatus: null,
  paginationInfo: null,
};

export default handleActions(
  {
    [LOAD_COMPANIES]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          companiesStatus: "loading",
        }),
        failure: (s) => ({
          ...s,
          companiesStatus: "error",
        }),
        success: (s) => {
          const paginationInfo: any = pick(
            get(action, "payload.data", defaultPagination),
            ["size", "totalPages", "totalElements"]
          );
          paginationInfo.page = get(action, "payload.data.number", 0);
          return {
            ...s,
            companiesStatus: "completed",
            companies: get(action, "payload.data.content", null),
            paginationInfo: paginationInfo,
          };
        },
      });
    },
    [RESET_COMPANY_STATE]: () => {
      return { ...initialState };
    },
    [commonActionTypes.RESET_APP]: () => {
      return { ...initialState };
    },
  },
  initialState
);
