import xorBy from "lodash/xorBy";
import actionTypes from "../actionTypes";
import commonActionTypes from "../../_Home/actionTypes";
import IEvent from "../../types/IEvent";
import { handleActions } from "redux-actions";

export interface State {
  events: IEvent[];
}

const initialState: State = {
  events: [],
};

export default handleActions(
  {
    [actionTypes.PUSH_EVENT]: (state, action) => {
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    },
    [actionTypes.POP_EVENTS]: (state, action: any) => {
      return {
        ...state,
        events: xorBy(state.events, action.payload, "name"),
      };
    },
    [actionTypes.PURGE_EVENTS]: (state) => ({
      ...state,
      events: [],
    }),
    [commonActionTypes.RESET_APP]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);

export { initialState };
