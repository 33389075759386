import quickView from "./quick-view";
import savedSearches from "./saved-searches";
import documents from "./documents";

import { combineReducers } from "redux";

export default combineReducers({
  documents,
  quickView,
  savedSearches,
});
