import constants from "@app/utils/constants";
import axios, { AxiosResponse } from "axios";
import { Api, ApiResponse } from "./_base";

/**
 * Fetches all integration options (these are the supported integration options not the actual configurations)
 */
export function loadIntegrations(): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/companies/integrations`,
  });
}

/**
 * Fetches the company's configurations
 * @param query
 */
export function loadConfigurations(
  companyId: string | number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/companies/integrations/configurations`,
    params: {
      companyId,
    },
  });
}

/**
 * Updates the company's configuration
 * @param configuration
 */
export function updateConfiguration(
  configurationId: number,
  configuration: any
) {
  return Api.execute({
    method: "PATCH",
    url: `/api/companies/integrations/configurations/${configurationId}`,
    data: configuration,
  });
}

/**
 * Adds the company's configuration
 * @param configuration
 */
export function addConfiguration(
  configuration: any
): Promise<AxiosResponse<any>> {
  return axios.post(
    `${constants.BASE_URL}/api/proxy/ms-profile/v1/configurations`,
    configuration,
    { withCredentials: true }
  );
}

export function deleteConfiguration(configurationId: number | string) {
  return Api.execute({
    method: "DELETE",
    url: `/api/proxy/ms-profile/v1/configurations/${configurationId}`,
  });
}
