import { AssemblyQuestion } from "@app/entities/assembly-questions";
import { Api, ApiResponse } from "./_base";

/**
 * Load the assembly questions associated with a playbook
 * @param playbookId: number
 */
export function loadAssemblyQuestions(
  playbookId: number
): Promise<ApiResponse<AssemblyQuestion[]>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/${playbookId}/questions`,
  });
}

/**
 * Delete's a single assembly question
 * @param playbookId: number
 */
export function deleteAssemblyQuestion(
  questionId: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/questions/${questionId}`,
    method: "DELETE",
  });
}

/**
 * Updates a single assembly question
 * @param questionId: number
 */
export function updateAssemblyQuestion(
  questionId: number,
  update: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/questions/${questionId}`,
    data: update,
    method: "PATCH",
  });
}

/**
 * Move position of a single assembly question
 * @param playbookId: number
 * @param questionId: number
 * @param position: number
 */
export function moveAssemblyQuestion(
  playbookId: number,
  questionId: number,
  position: number
): Promise<ApiResponse<AssemblyQuestion[]>> {
  return new Promise((resolve, reject) => {
    updateAssemblyQuestion(questionId, { position })
      .then(() => resolve(loadAssemblyQuestions(playbookId)))
      .catch((e) => reject(e));
  });
}

export function createAssemblyQuestion(
  playbookId: number,
  newQuestion: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/${playbookId}/questions`,
    data: newQuestion,
    method: "POST",
  });
}

export function updateAssemblyQuestionAction(
  actionId: number,
  update: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/actions/${actionId}`,
    data: update,
    method: "PATCH",
  });
}

export function createAssemblyQuestionAction(
  optionId: number,
  data: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/options/${optionId}/actions`,
    data,
    method: "POST",
  });
}

export function deleteAssemblyQuestionAction(
  actionId: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/actions/${actionId}`,
    method: "DELETE",
  });
}

export function addAssemblyQuestionOption(
  questionId: number,
  option: string
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/questions/${questionId}/options`,
    data: [option],
    method: "POST",
  });
}

export function deleteAssemblyQuestionOption(
  optionId: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/options/${optionId}`,
    method: "DELETE",
  });
}

export function updateAssemblyQuestionOption(
  optionId: number,
  data: any
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/ms-project/v1/playbook-wizard/options/${optionId}`,
    data,
    method: "PATCH",
  });
}
