import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { RESET_SEARCH, CHANGE_QUICKVIEW_VIEWER } from "../actions/types";

import {
  LOAD_QUICKVIEW_DOCUMENT,
  RESET_QUICKVIEW_STATE,
} from "@app/redux/data/search/quick-view";
import { ViewerType } from "../components/QuickView/QuickViewDocument";

export interface IQuickViewReduxState {
  selectedViewer: ViewerType;
}

export const initialState: IQuickViewReduxState = {
  // whether the document should be shown with in html, native pdf  or image view
  selectedViewer: null,
};

export default handleActions(
  {
    [CHANGE_QUICKVIEW_VIEWER]: (state: any, action) => ({
      ...state,
      selectedViewer: action.payload,
    }),
    [LOAD_QUICKVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          selectedViewer: null,
        }),
      });
    },
    [RESET_QUICKVIEW_STATE]: () => ({
      ...initialState,
    }),
    [RESET_SEARCH]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);
