import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { Playbook } from "@app/entities/playbook";
import { denormalize, normalize } from "normalizr";
import { playbooksSchema } from "./schemas";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";
import { NOOP, resolveCompanyId } from "@app/utils/helpers";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

type Status = "loading" | "error" | "completed";

export interface PlaybookListState {
  playbooks: { [key: number]: Playbook };
  playbookIds: number[];
  playbooksStatus: Status;
}

// #region ACTION TYPES
export const LOAD_PLAYBOOK_LIST = "PLAYBOOK::LOAD_PLAYBOOK_LIST";
export const LOAD_PLAYBOOK_STATS = "PLAYBOOK::LOAD_PLAYBOOK_STATS";
export const RESET_PLAYBOOK_LIST = "PLAYBOOK::RESET_PLAYBOOK_LIST";
export const DELETE_PLAYBOOK = "PLAYBOOK::DELETE_PLAYBOOK";
// #endregion

//#region ACTIONS
export const loadPlaybookList = (): any => {
  return (dispatch, getState) => {
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: LOAD_PLAYBOOK_LIST,
      promise: API.getPlaybooks(companyId),
    });
  };
};

// requires the playbook list scope and uses the corresponding restricted bff endpoint
export const loadPlaybookListRestricted = (): any => {
  return (dispatch, getState) => {
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: LOAD_PLAYBOOK_LIST,
      promise: API.getPlaybookList(companyId),
    });
  };
};

export const resetPlaybookList = (): IAction => ({
  type: RESET_PLAYBOOK_LIST,
});

export const deletePlaybook = (
  playbookId: number,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: DELETE_PLAYBOOK,
  promise: API.deletePlaybook(playbookId),
  meta: {
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const playbookListState: (state: any) => PlaybookListState = (state) =>
  state.data.playbook.playbookList;

export const getPlaybooks = createSelector(
  playbookListState,
  (state) => state.playbooks || {}
);

export const getPlaybookIds = createSelector(
  playbookListState,
  (state) => state.playbookIds || []
);

export const getPlaybooksList = createSelector(
  getPlaybookIds,
  getPlaybooks,
  (playbookIds, playbooks) => {
    return denormalize(playbookIds, playbooksSchema, {
      playbooks,
    });
  }
);

export const getPlaybooksStatus = createSelector(
  playbookListState,
  (state) => state.playbooksStatus
);

export const isPlaybookListLoading = createSelector(
  getPlaybooksStatus,
  (status) => status === "loading"
);

export const hasPlaybookListError = createSelector(
  getPlaybooksStatus,
  (status) => status === "error"
);

export const hasPlaybookListLoaded = createSelector(
  getPlaybooksStatus,
  (status) => status === "completed"
);
//#endregion

//#region HOOKS
export const usePlaybooks = () => {
  const dispatch = useDispatch();
  const playbooksList = useSelector(getPlaybooksList);
  const playbooksMap = useSelector(getPlaybooks);

  const isLoading = useSelector(isPlaybookListLoading);
  const hasError = useSelector(hasPlaybookListError);
  const hasLoaded = useSelector(hasPlaybookListLoaded);

  useEffect(() => {
    if (!hasLoaded && !hasError && !isLoading) {
      dispatch(loadPlaybookList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = {
    loadPlaybookList,
    resetPlaybookList,
    deletePlaybook,
  };

  return [
    playbooksList,
    playbooksMap,
    { isLoading, hasError, hasLoaded },
    actions,
  ];
};
//#endregion

//#region REDUCER
export const initialState: PlaybookListState = {
  // map of playbooks key'd by ID
  playbooks: null,
  // ids of the playbooks in their original load order
  playbookIds: [],
  // status of the playbooks load: completed, loading, error
  playbooksStatus: null,
};

export default handleActions(
  {
    [RESET_PLAYBOOK_LIST]: () => ({
      ...initialState,
    }),
    [LOAD_PLAYBOOK_LIST]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookListState): PlaybookListState => ({
          ...s,
          playbooksStatus: "loading",
          playbooks: null,
          playbookIds: [],
        }),
        failure: (s: PlaybookListState): PlaybookListState => ({
          ...s,
          playbooksStatus: "error",
        }),
        success: (s: PlaybookListState): PlaybookListState => {
          const { entities, result } = normalize(
            get(
              action,
              "payload.data.content",
              get(action, "payload.data", []) // for restricted endpoint, which returns only the array of playbooks and no pagination info
            ),
            playbooksSchema
          );
          return {
            ...s,
            playbooksStatus: "completed",
            playbooks: entities.playbooks,
            playbookIds: result,
          };
        },
      });
    },
    [DELETE_PLAYBOOK]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookListState): PlaybookListState => ({
          ...s,
          playbooksStatus: "loading",
        }),
        failure: (s: PlaybookListState): PlaybookListState => ({
          ...s,
          playbooksStatus: "completed",
        }),
        success: (s: PlaybookListState): PlaybookListState => {
          return {
            ...s,
            playbooksStatus: null,
          };
        },
      });
    },
  },
  initialState
);
//#endregion
