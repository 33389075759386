import {
  SchedulerNotification,
  SchedulerSystemSetting,
  SchedulerSystemAction,
  SchedulerSystemName,
} from "@app/entities/scheduler";
import queryString from "query-string";
import { Api, ApiResponse } from "./_base";

export type CreateSchedulerNotificationRequest = Pick<
  SchedulerNotification,
  | "objectId"
  | "objectType"
  | "cron"
  | "taskName"
  | "eventName"
  | "userId"
  | "arguments"
  | "nextExecutionTime"
>;

export type UpdateSchedulerNotificationRequest = Pick<
  SchedulerNotification,
  "cron" | "nextExecutionTime" | "iterations" | "arguments"
>;

export type UpdateSchedulerSystemSettingRequest = {
  name: SchedulerSystemName;
  action: SchedulerSystemAction;
  companyId?: string;
};

export function createSchedulerNotification(
  request: CreateSchedulerNotificationRequest,
): Promise<ApiResponse<SchedulerNotification>> {
  return Api.execute({
    url: `/api/proxy/automator/v1/scheduler`,
    method: "POST",
    data: request,
  });
}

export function updateSchedulerNotification(
  id: string,
  update: UpdateSchedulerNotificationRequest,
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/automator/v1/scheduler/${id}`,
    method: "PATCH",
    data: update,
  });
}

export function fetchSchedulerNotifications(
  objectId?: string,
  objectType?: string,
): Promise<ApiResponse<{ notifications: SchedulerNotification[] }>> {
  const query = {
    objectId: objectId || undefined,
    objectType: objectType || undefined,
  };
  return Api.execute({
    url: `/api/scheduler?${queryString.stringify(query)}`,
  });
}

export function deleteSchedulerNotification(
  id: string,
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/automator/v1/scheduler/${id}`,
    method: "DELETE",
  });
}

export function updateSchedulerSystemSetting(
  update: UpdateSchedulerSystemSettingRequest,
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/proxy/automator/v1/scheduler/system`,
    method: "POST",
    data: update,
  });
}

export function fetchSchedulerSystemSettings(
  companyId?: string,
): Promise<ApiResponse<{ schedules: SchedulerSystemSetting[] }>> {
  const query = {
    companyId: companyId || undefined,
  };
  return Api.execute({
    url: `/api/proxy/automator/v1/scheduler/system?${queryString.stringify(query)}`,
  });
}
