import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { contractTypesSchema } from "./schemas";
import { denormalize, normalize } from "normalizr";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
} from "@app/redux/utils";

export interface ContractTypesState {
  types: { [key: string]: any };
  typeIds: string[];
  typesStatus: DataStatus;
}

//#region ACTION TYPES
export const LOAD_CONTRACT_TYPES = "PLAYBOOK::LOAD_CONTRACT_TYPES";
//#endregion

//#region ACTIONS
export const loadContractTypes = (): any => ({
  type: LOAD_CONTRACT_TYPES,
  promise: API.loadContractTypes(),
});
//#endregion

//#region SELECTORS
export const contractTypesState: (state: any) => ContractTypesState = (state) =>
  state.data.playbook.contractTypes;

export const getContractTypes = createSelector(
  contractTypesState,
  (state) => state.types || {},
);

export const getContractTypeIds = createSelector(
  contractTypesState,
  (state) => state.typeIds || [],
);

export const getContractTypesList = createSelector(
  getContractTypeIds,
  getContractTypes,
  (ids, types) => {
    return denormalize(ids, contractTypesSchema, {
      types,
    });
  },
);

export const getContractTypesStatus = createSelector(
  contractTypesState,
  (state) => state.typesStatus,
);

export const isContractTypesLoading = createSelector(
  getContractTypesStatus,
  (status) => isDataLoading(status),
);

export const hasContractTypesError = createSelector(
  getContractTypesStatus,
  (status) => hasDataError(status),
);

export const hasContractTypesLoaded = createSelector(
  getContractTypesStatus,
  (status) => hasDataLoaded(status),
);
//#endregion

//#region HOOKS
export const useContractTypes = () => {
  const dispatch = useDispatch();
  const contractTypesMap = useSelector(getContractTypes);
  const contractTypesList = useSelector(getContractTypesList);

  const isLoading = useSelector(isContractTypesLoading);
  const hasError = useSelector(hasContractTypesError);
  const hasLoaded = useSelector(hasContractTypesLoaded);

  useEffect(() => {
    const status = runSelector(getContractTypesStatus);
    if (isDataNotLoaded(status) || hasDataError(status)) {
      dispatch(loadContractTypes());
    }
  }, [dispatch]);

  const actions = {
    loadContractTypes,
  };

  return [
    contractTypesList,
    contractTypesMap,
    { isLoading, hasError, hasLoaded },
    actions,
  ];
};
//#endregion

//#region REDUCER
export const initialState: ContractTypesState = {
  types: null,
  typeIds: [],
  typesStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_CONTRACT_TYPES]: (state, action) => {
      return handle(state, action, {
        start: (s: ContractTypesState): ContractTypesState => ({
          ...s,
          typesStatus: DataStatus.Loading,
        }),
        failure: (s: ContractTypesState): ContractTypesState => ({
          ...s,
          typesStatus: DataStatus.Error,
        }),
        success: (s: ContractTypesState): ContractTypesState => {
          const { entities, result } = normalize(
            get(action, "payload.data", []),
            contractTypesSchema,
          );
          const { types } = entities;
          return {
            ...s,
            types,
            typeIds: result,
            typesStatus: DataStatus.Done,
          };
        },
      });
    },
  },
  initialState,
);
//#endregion
