import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { createSelector } from "reselect";
import * as API from "@app/API";
import { get, forOwn } from "@app/utils/lodash";
import constants from "@app/utils/constants";
import { DataStatus } from "@app/redux/utils";
import { useSelector } from "react-redux";
import { getMyCompanyId } from "@app/_Login/reducers/selectors";
import { isArrayIncluded } from "@app/utils/helpers";

//#region TYPES
export const LOAD_APP_CONFIG = "AKORDA::LOAD_APP_CONFIG";
//#endregion

//#region ACTIONS
export const loadAppConfig = (
  onSuccess?: () => void,
  onFailure?: () => void
) => ({
  type: LOAD_APP_CONFIG,
  promise: API.loadAppConfig(),
  meta: {
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const configState = (state) => state.data.config;

export const getConfig = createSelector(configState, (state) =>
  get(state, "config")
);

export const getFeatureFlags = createSelector(
  getConfig,
  (config) => get(config, "features") || {}
);

export const getTranslationOverrides = createSelector(
  getConfig,
  (config) => get(config, "translationOverrides") || {}
);

export const getIntercomAppId = createSelector(
  getConfig,
  (config) => get(config, "intercom.appId") || ""
);

export const getIntercomSecretKey = createSelector(
  getConfig,
  (config) => get(config, "intercom.secretKey") || ""
);

export const getConfigStatus = createSelector(
  configState,
  (state) => state.configStatus
);

export const isLoadingConfig = createSelector(
  getConfigStatus,
  (status) => status === DataStatus.Loading
);

export const hasLoadedConfig = createSelector(
  getConfigStatus,
  (status) => status === DataStatus.Done
);

export const hasConfigError = createSelector(
  getConfigStatus,
  (status) => status === DataStatus.Error
);

/**
 * The `enabledFeatures` selector iterates through the config's features list, looking for any features
 * with the boolean value of true, indicating that it is enabled.
 *
 * There is additional support for company specific feature flags, which allow enabling or disabling a feature for one
 * company. If the feature flag list has an entry for the [feature]_[companyId], and the user is associated
 * with that company id, the feature will be considered enabled/disabled for the user based on whether the value is true
 * or false.
 */
export const enabledFeatures = createSelector(
  getFeatureFlags,
  getMyCompanyId,
  (featureFlags, companyId) => {
    let result = [];
    forOwn(featureFlags, (isActive, featureName) => {
      const companySpecificFlag = featureFlags[`${featureName}_${companyId}`];
      // if the feature is considered active
      if (isActive) {
        // explicit "false" check to see if the company specific flag disables the feature for the user's company
        const isInactiveForCompany = companySpecificFlag === false;
        // if it's not disabled for the specific company, add it to the list of enabled features
        if (!isInactiveForCompany) {
          result.push(featureName);
        }
      } else {
        // if this is not an active feature, do explicit "true" check to see if there's a company specific flag that would activate the feature
        const isActiveForCompany = companySpecificFlag === true;
        if (isActiveForCompany) {
          result.push(featureName);
        }
      }
    });
    return result;
  }
);

/*
  Selector to get the default subdomain to check if it is a valid alias
*/
export const getDefaultSubdomain = createSelector(
  getConfig,
  (config) => get(config, "defaultSubdomain") || ""
);

export const hasApprovalsFeature = createSelector(
  enabledFeatures,
  (features) => {
    const { APPROVAL_CENTER, GET_APPROVAL } = constants.FEATURES_TOGGLE;
    return isArrayIncluded([APPROVAL_CENTER, GET_APPROVAL], features);
  }
);

//#endregion

//#region  HOOKS
/**
 * Hooks that returns a tuple containing a boolean if the feature is active and a string array of all active features
 * @param featureKey name/key of the feature
 */
export const useFeatureFlags = (featureKey: string): [boolean, string[]] => {
  const flags: string[] = useSelector(enabledFeatures) || [];
  return [flags.includes(featureKey), flags];
};
//#endregion

///#region REDUCER

export interface ConfigState {
  config: { [key: string]: any };
  configStatus: DataStatus;
}

export const initialState: ConfigState = {
  config: null,
  configStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_APP_CONFIG]: (state: ConfigState, action: any) => {
      return handle(state, action, {
        start: (s: ConfigState) => ({
          ...s,
          configStatus: DataStatus.Loading,
        }),
        failure: (s: ConfigState) => ({
          ...s,
          configStatus: DataStatus.Error,
        }),
        success: (s: ConfigState) => ({
          ...s,
          configStatus: DataStatus.Done,
          config: get(action, "payload.data") || null,
        }),
      });
    },
  },
  initialState
);
//#endregion
