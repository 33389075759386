import { ISortInfo } from "../types/IPageable";
import get from "lodash/get";
import { Api, ApiResponse } from "./_base";

export function loadCompanies(
  page: number = 0,
  sortInfo?: ISortInfo,
  size: number = 20,
  search?: string,
  name?: string // returns only companies whose name includes this companyName value
): Promise<ApiResponse<any>> {
  const sort = get(sortInfo, "hasSort", false)
    ? `&sort=${sortInfo.sortBy},${sortInfo.isDescending ? "DESC" : "ASC"}`
    : "";
  search = !!search ? `&search=${search}` : "";
  name = !!name ? `&companyName=${name}` : "";
  return Api.execute({
    url: `/api/proxy/ms-profile/v1/companies?page=${page}&size=${size}${sort}${name}${search}`,
  });
}

export function loadSwitchableCompanies(
  userId: number
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/companies/${userId}/switchable-companies`,
  });
}

export function updateSwitchableCompanies(
  userId: number,
  companyList: number[]
): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/companies/${userId}/switchable-companies`,
    method: "PUT",
    data: {
      companyList,
    },
  });
}

export function switchCompany(companyId: number): Promise<ApiResponse<any>> {
  return Api.execute({
    url: `/api/companies/switch/${companyId}`,
    method: "POST",
  });
}
