import { get, isEmpty, isNil } from "@app/utils/lodash";
import constants from "@app/utils/constants";

const { RISK_MAP } = constants;

export enum StructureType {
  Section = "SECTION",
  Paragraph = "PARAGRAPH",
  List = "LIST",
}

export interface PlaybookClauseFallback {
  caption?: string;
  id: number;
  position: number;
  status: string;
  text: string;
  title: string;
}

export interface PlaybookClauseIssue {
  acceptable: boolean;
  customerComment?: string;
  fallbacks?: PlaybookClauseFallback[];
  guidance?: string;
  id: number;
  position: number;
  title: string;
}

export interface PlaybookClause {
  clauses: PlaybookClause[];
  createdBy: number;
  createdDate: string;
  displayName: string;
  guidance: string;
  id: number;
  issues?: any[];
  listNumber: string;
  name: string;
  optional?: boolean;
  parentClauseId?: number;
  position: number;
  recommendations?: FallbackProposal[];
  revisionNumber: number;
  status: string;
  structureType: StructureType;
  structureSettings?: any;
  text?: string;
  type: string;
  updatedBy: number;
  updatedDate: string;
  weightProvider: number;
  weightProvidee: number;
  weightMutual: number;
}

interface FallbackProposal {
  count: number;
  percentage: number;
  text: string;
  metadata: {
    count: number;
    documents: string[];
    percentage: number;
    clusterId: number;
    clusterSize: number;
    clusterPercentage: number;
    clusterDocuments: string[];
  };
}

export interface FlattenedPlaybookClause extends PlaybookClause {
  nestLevel: number;
  isLastItem: boolean;
  parentClause: FlattenedPlaybookClause;
}

export const getClauseId = (clause: PlaybookClause) => get(clause, "id");

export const getParentClauseId = (clause: PlaybookClause) =>
  get(clause, "parentClauseId");

export const getSubClauses = (clause: PlaybookClause) =>
  get(clause, "clauses") || [];

export const hasSubClauses = (clause: PlaybookClause) =>
  !isEmpty(getSubClauses(clause));

export const isSubClause = (clause: PlaybookClause) =>
  !isNil(getParentClauseId(clause));

export const getStructureType = (clause: PlaybookClause) =>
  get(clause, "structureType");

export const isSectionClause = (clause: PlaybookClause) =>
  getStructureType(clause) === StructureType.Section;

export const isParagraphClause = (clause: PlaybookClause) =>
  getStructureType(clause) === StructureType.Paragraph;

export const isListClause = (clause: PlaybookClause) =>
  getStructureType(clause) === StructureType.List;

export const getListNumber = (clause: PlaybookClause) => {
  const listNumber = get(clause, "listNumber") || "";
  return !isEmpty(listNumber) ? `${listNumber}.` : "";
};

export const getIssues = (clause: PlaybookClause): PlaybookClauseIssue[] =>
  get(clause, "issues") || [];

export const hasIssues = (clause: PlaybookClause): boolean =>
  !isEmpty(getIssues(clause));

export const getFallbacks = (
  clause: PlaybookClause
): PlaybookClauseFallback[] =>
  getIssues(clause).reduce((result, issue) => {
    if (isEmpty(issue?.fallbacks)) return result;
    return [...result, ...issue.fallbacks];
  }, []);

export const getClauseName = (clause: PlaybookClause) =>
  (get(clause, "displayName") || get(clause, "name") || "").replace(
    /\s+/g,
    " "
  );

export const getRisk = (riskWeight: number) => {
  const { LOW, MEDIUM, HIGH } = RISK_MAP;
  if (!riskWeight) {
    return HIGH; // return high risk when there is no weighting
  }
  if (riskWeight <= 4) {
    return LOW;
  } else if (riskWeight <= 7) {
    return MEDIUM;
  } else {
    return HIGH;
  }
};

export const getClauseStandard = (clause: PlaybookClause) =>
  get(clause, "text");

export const getClauseGuidance = (clause: PlaybookClause) =>
  get(clause, "guidance");

export const isOptional = (clause: PlaybookClause) => !!get(clause, "optional");

export const getNumberedClauseName = (clause: PlaybookClause) =>
  isListClause(clause)
    ? `${getListNumber(clause)} ${getClauseName(clause)}`
    : getClauseName(clause);

export const getFallbackProposals = (clause: PlaybookClause) =>
  get(clause, "recommendations") ?? [];

export const hasFallbackProposals = (clause: PlaybookClause) =>
  !!getFallbackProposals(clause).length;

export const getNestLevel = (clause: PlaybookClause) =>
  get(clause, "nestLevel") || 0;

/**
 * Returns the structure types which a clause can be maked
 */
export const getMakeableStructureTypes = (clause, parentClause) => {
  const types = [];
  /** SECTION structure type when:
   * It is a top level (no parent)
   * It doesn't have any children
   * It is not a section clause
   */

  if (!parentClause && !hasSubClauses(clause) && !isSectionClause(clause)) {
    types.push(StructureType.Section);
  }
  /** LIST structure type when:
   * It is at the top level or it is not a child of paragraph clause
   * It is not a list clause
   */
  if (
    (!parentClause || !isParagraphClause(parentClause)) &&
    !isListClause(clause)
  ) {
    types.push(StructureType.List);
  }
  /** PARAGRAPH structure type when:
   * It is at the top level or it is a child of section clause
   * It is not a paragraph clause
   */
  if (
    (!parentClause || isSectionClause(parentClause)) &&
    !hasSubClauses(clause) &&
    !isParagraphClause(clause)
  ) {
    types.push(StructureType.Paragraph);
  }

  return types;
};

const getDefaultStructureSettings = (clause: PlaybookClause, schemas?: any) => {
  const structureType = getStructureType(clause);
  const schema = getConfigurationSchema(structureType, schemas);
  let defaultSchema = get(schema, "default");
  if (!defaultSchema) {
    if (isListClause(clause)) {
      defaultSchema = {
        clauseIncludeTitle: true,
        formatAsHeading: false,
        formatChildrenAsDefinitions: false,
        includeInTableOfContent: true,
        titleSeparator: ".",
      };
    } else if (isParagraphClause(clause)) {
      defaultSchema = {
        clauseIncludeTitle: false,
        formatAsHeading: false,
        leftIndent: 0,
        rightIndent: 0,
        titleSeparator: ".",
      };
    } else if (isSectionClause(clause)) {
      defaultSchema = {
        generateTableOfContent: false,
        includeTitle: false,
        isExhibit: false,
        listConfiguration: [
          {
            bold: true,
            hanging: 400,
            indLeft: 360,
            level: 0,
            lvlText: "%1. ",
            numFmt: "Decimal",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 860,
            indLeft: 860,
            level: 1,
            lvlText: "%1.%2 ",
            numFmt: "Decimal",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 660,
            indLeft: 1460,
            level: 2,
            lvlText: "%1.%2.%3 ",
            numFmt: "Decimal",
            suff: "Tab",
            tabLeft: 660,
          },
          {
            bold: true,
            hanging: 400,
            indLeft: 1728,
            level: 3,
            lvlText: "(%4) ",
            numFmt: "Lower Letter",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 860,
            indLeft: 2088,
            level: 4,
            lvlText: "(%5) ",
            numFmt: "Lower Roman",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 860,
            indLeft: 2448,
            level: 5,
            lvlText: "%6. ",
            numFmt: "Lower Letter",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 860,
            indLeft: 2808,
            level: 6,
            lvlText: "%7)",
            numFmt: "Lower Roman",
            suff: "Tab",
            tabLeft: 400,
          },
          {
            bold: true,
            hanging: 860,
            indLeft: 3168,
            level: 7,
            lvlText: "%7.",
            numFmt: "Lower Letter",
            suff: "Tab",
            tabLeft: 400,
          },
        ],
        pageBreak: true,
        twoColumnsLayout: false,
      };
    }
  }
  return defaultSchema || {};
};

export const getStructureSettings = (clause: PlaybookClause, schemas?: any) =>
  get(clause, "structureSettings") ||
  getDefaultStructureSettings(clause, schemas);

export const getTitleSeparator = (structureSettings: any) =>
  get(structureSettings, "titleSeparator") || ".";

export const hasToFormatAsHeading = (structureSettings: any) =>
  get(structureSettings, "formatAsHeading") || false;

export const hasToFormatChildrenAsDefinitions = (structureSettings: any) =>
  get(structureSettings, "formatChildrenAsDefinitions") || false;

export const hasToIncludeClauseTitle = (structureSettings: any) =>
  get(structureSettings, "clauseIncludeTitle", true);

export const hasToIncludeTitle = (structureSettings: any) =>
  get(structureSettings, "includeTitle", false);

export const hasPageBreak = (structureSettings: any) =>
  get(structureSettings, "pageBreak", true);

export const getConfigurationSchema = (structureType: string, schemas?: any) =>
  get(schemas, `${structureType}.schema`) || {};
