import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { denormalize, normalize } from "normalizr";
import { orderTablesListSchema } from "./schemas";
import { get, orderBy, without } from "@app/utils/lodash";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { OrderTableConfiguration } from "@app/entities/order-tables";
import { SettingsEntity } from "@app/entities/settings";

const ORDER_FORM_CONFIGURATION = "ORDER_TABLES";

// #region ACTION TYPES
export const LOAD_ORDER_TABLES = "order-tables/load";
export const RESET_ORDER_TABLES = "order-tables/reset";
export const CREATE_ORDER_TABLE = "order-tables/create";
export const UPDATE_ORDER_TABLE = "order-tables/update";
export const DELETE_ORDER_TABLE = "order-tables/delete";

// #endregion

//#region ACTIONS
export const loadOrderTables = (): any => ({
  type: LOAD_ORDER_TABLES,
  promise: API.loadEntities(ORDER_FORM_CONFIGURATION),
});

export const resetOrderTables = (): IAction => ({
  type: RESET_ORDER_TABLES,
});

export const createOrderTable = (
  orderTable: OrderTableConfiguration,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_ORDER_TABLE,
  promise: API.createEntity(ORDER_FORM_CONFIGURATION, orderTable, true),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateOrderTable = (
  formId: string,
  data: Partial<SettingsEntity<OrderTableConfiguration>>,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_ORDER_TABLE,
  promise: API.updateEntity(formId, data),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteOrderTable = (
  formId: string,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: DELETE_ORDER_TABLE,
  promise: API.deleteEntity(formId),
  meta: {
    formId,
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const orderTableState = (state) => state.data.orderTables.orderTables;

export const getOrderTables = createSelector(
  orderTableState,
  (state) => get(state, "orderTables") || {}
);

export const getOrderTableIds = createSelector(
  orderTableState,
  (state) => state.orderTableIds
);

export const getOrderTablesList = createSelector(
  getOrderTables,
  getOrderTableIds,
  (orderTables, ids) => {
    return orderBy(
      denormalize(ids, orderTablesListSchema, {
        orderTables,
      }),
      ["createdDate"],
      ["desc"]
    );
  }
);

export const getOrderTablesStatus = createSelector(
  orderTableState,
  (state) => state.orderTablesStatus
);

export const isOrderTablesLoading = createSelector(
  getOrderTablesStatus,
  (status) => status === DataStatus.Loading
);

export const isOrderTableSubmitting = createSelector(
  getOrderTablesStatus,
  (status) => status === DataStatus.Submitting
);

export const hasOrderTableError = createSelector(
  getOrderTablesStatus,
  (status) => status === DataStatus.Error
);

export const hasOrderTableListLoaded = createSelector(
  getOrderTablesStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region HOOKS
export const useOrderTables = () => {
  const dispatch = useDispatch();

  const orderTables = useSelector(getOrderTablesList);
  const orderTablesMap = useSelector(getOrderTables);
  const isLoading = useSelector(isOrderTablesLoading);
  const hasError = useSelector(hasOrderTableError);
  const hasLoaded = useSelector(hasOrderTableListLoaded);
  const isSubmitting = useSelector(isOrderTableSubmitting);

  useEffect(() => {
    const status = runSelector(getOrderTablesStatus);
    if (isDataNotLoaded(status)) {
      dispatch(loadOrderTables());
    }
  }, [dispatch]);

  return [
    orderTables,
    orderTablesMap,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface OrderTablesState {
  orderTables: { [key: string]: SettingsEntity<OrderTableConfiguration> };
  orderTableIds: string[];
  orderTablesStatus: DataStatus;
}

export const initialState: OrderTablesState = {
  orderTables: {},
  orderTableIds: [],
  orderTablesStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_ORDER_TABLES]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          orderTablesStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          orderTablesStatus: DataStatus.Error,
        }),
        success: (s) => {
          const data = get(action, "payload.data.entities", []);
          const { entities, result } = normalize(data, orderTablesListSchema);
          const { orderTables } = entities;

          return {
            ...s,
            orderTablesStatus: DataStatus.Done,
            orderTableIds: result,
            orderTables,
          };
        },
      }),
    [CREATE_ORDER_TABLE]: (state, action) =>
      handle(state, action, {
        start: (s): OrderTablesState => ({
          ...s,
          orderTablesStatus: DataStatus.Submitting,
        }),
        failure: (s): OrderTablesState => ({
          ...s,
          orderTablesStatus: DataStatus.Done,
        }),
        success: (s): OrderTablesState => {
          const newOrderTable: any = get(action, "payload.data");

          return {
            ...s,
            orderTablesStatus: DataStatus.Done,
            orderTableIds: [...s.orderTableIds, newOrderTable.id],
            orderTables: {
              ...s.orderTables,
              [newOrderTable.id]: newOrderTable,
            },
          };
        },
      }),
    [DELETE_ORDER_TABLE]: (state, action) =>
      handle(state, action, {
        start: (s): OrderTablesState => ({
          ...s,
          orderTablesStatus: DataStatus.Submitting,
        }),
        failure: (s): OrderTablesState => ({
          ...s,
          orderTablesStatus: DataStatus.Done,
        }),
        success: (s): OrderTablesState => {
          const orderTableId: string = get(action, "meta.formId") || "";
          const orderTables = {
            ...s.orderTables,
          };
          delete orderTables[orderTableId];
          return {
            ...s,
            orderTablesStatus: DataStatus.Done,
            orderTableIds: without(s.orderTableIds, orderTableId),
            orderTables,
          };
        },
      }),
    [UPDATE_ORDER_TABLE]: (state, action) =>
      handle(state, action, {
        success: (s): OrderTablesState => {
          const orderTable: any = get(action, "payload.data");
          const orderTables = {
            ...s.orderTables,
            [orderTable.id]: orderTable,
          };
          return {
            ...s,
            orderTables,
          };
        },
      }),
    [RESET_ORDER_TABLES]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
