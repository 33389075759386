export const LOAD_FILING_DOCUMENT = "FILING-CONTRACT::LOAD_DOCUMENT";
export const LOAD_FILING_METADATA = "FILING-CONTRACT::LOAD_FILING_METADATA";
export const RESET_FILING_WIZARD = "FILING-CONTRACT::RESET_FILING_WIZARD";
export const UPDATE_FILING_DOCUMENT_TYPE =
  "FILING-CONTRACT::UPDATE_FILING_DOCUMENT_TYPE";
export const UPLOAD_FINAL_WORD_FILE = "FILING-CONTRACT::UPLOAD_FINAL_WORD_FILE";
export const UPDATE_DOCUMENT_ACCESS = "FILING-CONTRACT::UPDATE_DOCUMENT_ACCESS";
export const UPDATE_DOCUMENT_FOLDER = "FILING-CONTRACT::UPDATE_DOCUMENT_FOLDER";
export const RELATE_DOCUMENT = "FILING-CONTRACT::RELATE_DOCUMENT";
export const REFRESH_DOCUMENT = "FILING-CONTRACT::REFRESH_DOCUMENT";
