import assemblyQuestions from "./assembly-questions";
import assemblyData from "./assembly-data";
import customFields from "./custom-fields";
import { combineReducers } from "redux";

export default combineReducers({
  assemblyData,
  assemblyQuestions,
  customFields,
});
