import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { DataStatus } from "@app/redux/utils";
import IAction from "@app/types/IAction";
import queryString from "query-string";
import { IPagination } from "@app/types-business/Project";
import { omit } from "@app/utils/lodash";

//#region ACTION TYPES
export const LOAD_PLAYBOOK_HISTORY = "playbook/history/load";
export const RESET_PLAYBOOK_HISTORY = "playbook/history/reset";
//#endregion

//#region ACTIONS
/**
 * Loads the historical information related to the playbook, specifically information about CRUD operations performed during playbook edits.
 * @param playbookId
 */
export const loadPlaybookHistory = (playbookId: number): IAction => {
  const { page } = queryString.parse(window.location.search);
  const query: any = {};
  query.page = page || 0;
  query.size = 20;
  return {
    type: LOAD_PLAYBOOK_HISTORY,
    promise: API.getPlaybookHistory(playbookId, queryString.stringify(query)),
  };
};

export const resetPlaybookHistory = (): IAction => ({
  type: RESET_PLAYBOOK_HISTORY,
});
//#endregion

//#region SELECTORS
export const playbookHistoryState: (state: any) => PlaybookHistoryReduxState = (
  state
) => state.data.playbook.playbookHistory;

export const getPlaybookHistoryStatus = createSelector(
  playbookHistoryState,
  (state) => state.historyStatus
);

export const isPlaybookHistoryLoading = createSelector(
  getPlaybookHistoryStatus,
  (status) => status === DataStatus.Loading
);

export const hasPlaybookHistoryError = createSelector(
  getPlaybookHistoryStatus,
  (status) => status === DataStatus.Error
);

export const getPlaybookHistory = createSelector(
  playbookHistoryState,
  (state) => state.history
);

export const getPlaybookHistoryPaginationInfo = createSelector(
  playbookHistoryState,
  (state) => state.paginationInfo || {}
);
//#endregion

//#region HOOKS
//#endregion

//#region REDUCER
export interface PlaybookHistoryReduxState {
  history: any[];
  historyStatus: string;
  paginationInfo: IPagination;
}

export const initialState: PlaybookHistoryReduxState = {
  // the history of the playbook
  history: [],
  // status of the playbook history load: completed, loading, error
  historyStatus: null,
  // pagination information
  paginationInfo: null,
};

export default handleActions(
  {
    [LOAD_PLAYBOOK_HISTORY]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          historyStatus: DataStatus.Loading,
          history: [],
        }),
        failure: (s) => ({ ...s, historyStatus: DataStatus.Error }),
        success: (s) => {
          return {
            ...s,
            historyStatus: DataStatus.Done,
            history: get(action, "payload.data.content", []),
            paginationInfo: omit(get(action, "payload.data", null), "content"),
          };
        },
      });
    },
    [RESET_PLAYBOOK_HISTORY]: () => ({ ...initialState }),
  },
  initialState
);

//#endregion
