import axios, { AxiosRequestConfig } from "axios";

type ApiInfo = {
  url: string;
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  data?: any;
  params?: any;
  config?: AxiosRequestConfig;
};

export type ApiSuccessResponse<T> = { data: T };
export type ApiFailureResponse = { error: { status?: number; data: any } };

export type ApiResponse<T> = ApiSuccessResponse<T> | ApiFailureResponse;

type ApiHandler<T> = (info: ApiInfo) => Promise<ApiResponse<T>>;

const execute: ApiHandler<any> = async (info: ApiInfo) => {
  const { url, method = "get", data, params, config = {} } = info;
  return axios({
    url,
    method,
    data,
    params,
    ...config,
    withCredentials: true,
  });
};

export const Api = {
  execute,
};

export const updateApiHandler = (handler: ApiHandler<any>) => {
  Api.execute = handler;
};
