import { combineReducers } from "redux";
import admin from "@app/_Administration/reducers";
import login from "@app/_Login/reducers/login";
import company from "@app/_Company/reducers";
import user from "@app/_User/reducers";
import toolbox from "@app/_Common/reducers/examplesToolbox";
import exportModal from "@app/_Common/reducers/exportModal";
import approvals from "@app/_ApprovalCenter/reducers";
import playbooks from "@app/_Playbook/reducers";
import document from "@app/_Document/reducers";
import versions from "@app/_Versions/reducers";
import downloader from "@app/_Common/reducers/downloader";
import insights from "@app/_Insights/reducers";
import search from "@app/_Search/reducers";
import contract from "@app/_Contract/reducers";
import filing from "@app/_FilingContract/reducers";
import support from "@app/_Support/reducers";
import data from "@app/redux/data";
import { LOGOUT } from "@app/_Login/actionTypes";

const appReducer = combineReducers({
  data,
  admin,
  contract,
  approvals,
  company,
  document,
  downloader,
  exportModal,
  filing,
  insights,
  login,
  playbooks,
  toolbox,
  user,
  versions,
  search,
  support,
});

const rootReducer = (state, action) => {
  // in the event of a user logout, we will pass 'undefined' as the state, which
  // forces all reducers to return to initial state.
  if (action.type === LOGOUT) {
    return appReducer({ data: { config: state.data.config } } as any, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
