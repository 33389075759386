import { handleActions } from "redux-actions";
import {
  SELECT_RESULT,
  SET_SHOW_PLAYBOOK_DIFF,
  SELECT_PLAYBOOK_CLAUSE,
} from "../actions/types";

export interface PlaybookSearchReduxState {
  selectedResultId: number;
  isShowingDiffToStandard: boolean;
}

export const initialState: PlaybookSearchReduxState = {
  selectedResultId: null,
  isShowingDiffToStandard: true,
};

export default handleActions(
  {
    [SELECT_RESULT]: (state, action) => ({
      ...state,
      selectedResultId: action.payload,
    }),
    [SELECT_PLAYBOOK_CLAUSE]: (state, action) => ({
      ...state,
      selectedResultId: action.payload,
    }),
    [SET_SHOW_PLAYBOOK_DIFF]: (state: any, action) => ({
      ...state,
      isShowingDiffToStandard: action.payload,
    }),
  },
  initialState
);
