import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { isNil, keyBy, without, get } from "@app/utils/lodash";
import { RESET_SEARCH } from "@app/_Search/actions/types";
import {
  SavedSearch,
  SavedSearchACL,
  SavedSearchType,
} from "@app/types-business/Search";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";

/* #region  ACTION TYPES */
export const LOAD_SAVED_SEARCHES = "SEARCH::LOAD_SAVED_SEARCHES";
export const LOAD_SAVED_SEARCH_DETAILS = "SEARCH::LOAD_SAVED_SEARCH_DETAILS";
export const CREATE_SAVED_SEARCH = "SEARCH::CREATE_SAVED_SEARCH";
export const UPDATE_SAVED_SEARCH = "SEARCH::UPDATE_SAVED_SEARCH";
export const DELETE_SAVED_SEARCH = "SEARCH::DELETE_SAVED_SEARCH";
export const UPDATE_SAVED_SEARCH_PERMISSIONS =
  "SEARCH::UPDATE_SAVED_SEARCH_PERMISSIONS";
export const DELETE_SAVED_SEARCH_PERMISSION =
  "SEARCH::DELETE_SAVED_SEARCH_PERMISSION";
export const SET_SAVED_SEARCH_SOURCE_TYPES_FILTER =
  "SEARCH::SET_SAVED_SEARCH_SOURCE_TYPES_FILTER";
export const RESET_SAVED_SEARCH = "SEARCH::RESET_SAVED_SEARCH";

/* #endregion */

/* #region  ACTIONS  */
export const loadSavedSearches = (
  savedSearchType: SavedSearchType = SavedSearchType.Document,
) => ({
  type: LOAD_SAVED_SEARCHES,
  promise: API.loadSavedSearches(savedSearchType),
  meta: {
    savedSearchType,
  },
});

export const loadSavedSearchDetails = (id: string) => ({
  type: LOAD_SAVED_SEARCH_DETAILS,
  promise: API.loadSavedSearch(id),
});

export const createSavedSearch = (
  saveSearchRequest: SavedSearch,
  onSuccess: any = NOOP,
  onFailure: any = NOOP,
) => ({
  type: CREATE_SAVED_SEARCH,
  promise: API.saveSearch(saveSearchRequest),
  meta: {
    request: saveSearchRequest,
    onSuccess,
    onFailure,
  },
});

export const updateSavedSearch = (
  id: string,
  saveSearchRequest: SavedSearch,
  onSuccess: any = NOOP,
  onFailure: any = NOOP,
) => ({
  type: UPDATE_SAVED_SEARCH,
  promise: API.updateSavedSearch(id, saveSearchRequest),
  meta: {
    id,
    request: saveSearchRequest,
    onSuccess,
    onFailure,
  },
});

export const deleteSavedSearch = (
  id: string,
  onSuccess: any = NOOP,
  onFailure: any = NOOP,
) => ({
  type: DELETE_SAVED_SEARCH,
  promise: API.deleteSavedSearch(id),
  meta: {
    id,
    onSuccess,
    onFailure,
  },
});

export const updateSavedSearchPermissions = (
  id: string,
  share: SavedSearchACL[],
  onSuccess = NOOP,
  onFailure = NOOP,
) => ({
  type: UPDATE_SAVED_SEARCH_PERMISSIONS,
  promise: API.updateSavedSearchPermissions(id, share),
  meta: {
    share,
    onSuccess,
    onFailure,
  },
});

export const deleteSavedSearchPermissions = (
  id: string,
  acl: SavedSearchACL,
  onSuccess = NOOP,
  onFailure = NOOP,
) => ({
  type: DELETE_SAVED_SEARCH_PERMISSION,
  promise: API.deleteSavedSearchPermission(id, acl),
  meta: {
    acl,
    onSuccess,
    onFailure,
  },
});

export const setSavedSearchSourceTypesFilter = (
  id: string,
  sourceTypes: string[],
) => ({
  type: SET_SAVED_SEARCH_SOURCE_TYPES_FILTER,
  payload: {
    savedSearchId: id,
    filter: sourceTypes,
  },
});

export const resetSavedSearch = () => ({
  type: RESET_SAVED_SEARCH,
});

/* #endregion */

/* #region  SELECTORS */
export const savedSearchesState = (state) => state.data.search.savedSearches;

export const getSavedSearches = createSelector(
  savedSearchesState,
  (state) => state.savedSearches || {},
);

export const getSavedSearchesIds = createSelector(
  savedSearchesState,
  (state) => state.savedSearchesIds || [],
);

export const getSavedSearchesStatus = createSelector(
  savedSearchesState,
  (state) => state.savedSearchesStatus,
);

export const getSavedSearchType = createSelector(
  savedSearchesState,
  (state) => state.savedSearchType,
);

export const isSavedSearchesLoading = createSelector(
  getSavedSearchesStatus,
  (status) => status === "loading",
);

export const hasSavedSearchesError = createSelector(
  getSavedSearchesStatus,
  (status) => status === "error",
);

export const hasSavedSearchesLoaded = createSelector(
  getSavedSearchesStatus,
  (status) => status === "completed",
);

export const getSavedSearchDetails = createSelector(
  savedSearchesState,
  (state) => state.savedSearchDetails,
);

export const getSavedSearchDetailsStatus = createSelector(
  savedSearchesState,
  (state) => state.savedSearchDetailsStatus,
);

export const isSavedSearchDetailsSubmitting = createSelector(
  getSavedSearchDetailsStatus,
  (status) => status === "submitting",
);

export const getSavedSearchSourceTypesFilter = createSelector(
  savedSearchesState,
  (state) => state.sourceTypesFilter || null,
);

export const getSavedSearchId = createSelector(
  savedSearchesState,
  (state) => state.key || null,
);

/* #endregion */

/* #region  HOOKS */
export const useSavedSearches = (
  savedSearchType: SavedSearchType = SavedSearchType.Document,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getSavedSearchesStatus);
    const type = runSelector(getSavedSearchType);

    if (isDataNotLoaded(status) || type !== savedSearchType) {
      dispatch(loadSavedSearches(savedSearchType));
    }
  }, [dispatch, savedSearchType]);

  const savedSearches = useSelector(getSavedSearches);
  const isLoading = useSelector(isSavedSearchesLoading);
  const hasError = useSelector(hasSavedSearchesError);
  const hasLoaded = useSelector(hasSavedSearchesLoaded);

  const actions = {
    createSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    loadSavedSearches,
  };
  return { savedSearches, status: { isLoading, hasError, hasLoaded }, actions };
};

export const useSavedSearch = (
  id: string,
  savedSearchType?: SavedSearchType,
) => {
  const dispatch = useDispatch();
  const { status, savedSearches } = useSavedSearches(savedSearchType);
  const savedSearch = !!id && status.hasLoaded ? savedSearches[id] : null;

  const actions = useMemo(() => {
    if (!savedSearch)
      return {
        updateSavedSearch: NOOP,
        deleteSavedSearch: NOOP,
      };
    return {
      updateSavedSearch: (
        update: SavedSearch,
        onSuccess: any,
        onFailure: any,
      ) => dispatch(updateSavedSearch(id, update, onSuccess, onFailure)),
      deleteSavedSearch,
    };
  }, [dispatch, id, savedSearch]);

  return { savedSearch, actions, status };
};

export const useSavedSearchDetails = (id: string, load: boolean) => {
  const dispatch = useDispatch();
  const savedSearch = useSelector(getSavedSearchDetails);
  const savedSearchStatus = useSelector(getSavedSearchDetailsStatus);

  useEffect(() => {
    if (id && load) {
      dispatch(loadSavedSearchDetails(id));
    }
  }, [id, load, dispatch]);

  const status = {
    isLoading: savedSearchStatus === "loading",
    hasError: savedSearchStatus === "error",
    hasLoaded: savedSearchStatus === "completed",
  };

  const actions = {
    loadSavedSearchDetails,
    updateSavedSearch,
    deleteSavedSearch,
  };

  return [savedSearch, status, actions];
};
/* #endregion */

/* #region  REDUCER  */
export interface SavedSearchesState {
  savedSearches: { [key: string]: SavedSearch };
  savedSearchesIds: number[];
  savedSearchesStatus: DataStatus;
  savedSearchDetails: SavedSearch;
  savedSearchDetailsStatus: DataStatus;
  savedSearchType: SavedSearchType;
  sourceTypesFilter: string[];
  key: string;
}

export const initialState: SavedSearchesState = {
  // map of saved searches available to the user
  savedSearches: {},
  // ordered list of ids for saved searches
  savedSearchesIds: [],
  // status of the saved searches: loading, error, completed
  savedSearchesStatus: DataStatus.NotLoaded,
  // full details of a single saved search
  savedSearchDetails: null,
  // status of saved search details
  savedSearchDetailsStatus: DataStatus.NotLoaded,
  // saved searches type
  savedSearchType: null,
  // saved search sourceTypesFilter
  sourceTypesFilter: null,
  // saved search id
  key: null,
};

export default handleActions(
  {
    [LOAD_SAVED_SEARCHES]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          savedSearchesStatus: DataStatus.Loading,
          savedSearchType: get(action, "meta.savedSearchType") || null,
        }),
        failure: (s) => ({
          ...s,
          savedSearchesStatus: DataStatus.Error,
          savedSearchType: null,
        }),
        success: (s) => {
          const savedSearches = get(action, "payload.data.items") || [];
          return {
            ...s,
            savedSearchesStatus: DataStatus.Done,
            savedSearchesIds: savedSearches.map((search) => search.id),
            savedSearches: keyBy(savedSearches, "id"),
            sourceTypesFilter: null,
            key: null,
          };
        },
      }),
    [LOAD_SAVED_SEARCH_DETAILS]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Error,
        }),
        success: (s) => {
          const savedSearchDetails = get(action, "payload.data");
          return {
            ...s,
            savedSearchDetailsStatus: DataStatus.Done,
            savedSearchDetails,
          };
        },
      }),
    [CREATE_SAVED_SEARCH]: (state, action: any) =>
      handle(state, action, {
        success: (s) => {
          const savedSearchId = get(action, "payload.data.id", null);
          return isNil(savedSearchId)
            ? { ...s }
            : {
                ...s,
                savedSearchesStatus: DataStatus.Done,
                savedSearchesIds: [...s.savedSearchesIds, savedSearchId],
                savedSearches: {
                  ...s.savedSearches,
                  [savedSearchId]: {
                    id: savedSearchId,
                    ...action.meta.request,
                  },
                },
              };
        },
      }),
    [UPDATE_SAVED_SEARCH]: (state, action: any) =>
      handle(state, action, {
        success: (s) => {
          const { id, request } = action.meta;
          const detailsId = get(s, "savedSearchDetails.id", null);
          return {
            ...s,
            sourceTypesFilter: null,
            // update details if it has the id
            savedSearchDetails:
              detailsId === id
                ? { ...s.savedSearchDetails, ...request }
                : s.savedSearchDetails,
            savedSearches: {
              ...s.savedSearches,
              // replace the item in the map
              [id]: {
                ...s.savedSearches[id],
                ...request,
              },
            },
          };
        },
      }),
    [SET_SAVED_SEARCH_SOURCE_TYPES_FILTER]: (state, action: any) => {
      const { savedSearchId, filter } = action.payload;
      return {
        ...state,
        sourceTypesFilter: filter,
        key: savedSearchId,
      };
    },
    [RESET_SAVED_SEARCH]: (state, action: any) => {
      return {
        ...state,
        sourceTypesFilter: null,
        key: null,
      };
    },
    [UPDATE_SAVED_SEARCH_PERMISSIONS]: (state, action: any) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Submitting,
        }),
        finish: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Done,
        }),
        success: (s) => {
          const { share } = action.meta;
          return {
            ...s,
            savedSearchDetailsStatus: DataStatus.Done,
            savedSearchDetails: {
              ...s.savedSearchDetails,
              share: [...share],
            },
          };
        },
      }),
    [DELETE_SAVED_SEARCH_PERMISSION]: (state, action: any) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Submitting,
        }),
        finish: (s) => ({
          ...s,
          savedSearchDetailsStatus: DataStatus.Done,
        }),
        success: (s) => {
          const { acl } = action.meta;
          return {
            ...s,
            savedSearchDetailsStatus: DataStatus.Done,
            savedSearchDetails: {
              ...s.savedSearchDetails,
              share: without(s.savedSearchDetails.share, acl),
            },
          };
        },
      }),
    [DELETE_SAVED_SEARCH]: (state, action: any) =>
      handle(state, action, {
        success: (s) => {
          const { id } = action.meta;
          const savedSearches = { ...s.savedSearches };
          delete savedSearches[id];
          const detailsId = get(s, "savedSearchDetails.id", null);
          return {
            ...s,
            savedSearches,
            savedSearchesIds: without(s.savedSearchesIds, id),
            savedSearchDetails: detailsId === id ? null : s.savedSearchDetails,
            sourceTypesFilter: null,
          };
        },
      }),
    [RESET_SEARCH]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);
/* #endregion */
