import { isDifferent } from "./common";
import queryString, { ParsedQuery } from "query-string";
import { get, isNil } from "../lodash";
import { ISortInfo } from "@app/types/IPageable";

/**
 * Given an url and an qs object, append the keys and values as query string returning as new url
 * @param url string url
 * @param qsObject object that will be converted to qs parameters
 */
export function addQueryStringToUrl(url: string, qsObject: any) {
  Object.keys(qsObject).forEach((key, index) => {
    url = `${url}${index === 0 ? "?" : "&"}${key}=${encodeURI(qsObject[key])}`;
  });
  return url;
}

export const convertSortInfoToQueryString = (sortInfo: ISortInfo) => {
  return get(sortInfo, "hasSort", false)
    ? `${sortInfo.sortBy},${sortInfo.isDescending ? "DESC" : "ASC"}`
    : null;
};

/**
 * Performs a lookup against the curent location.search and returns the value. If a `type` value
 * is provided, then the value is converted to that type (`number` or `boolean`). If the type param is supplied
 * but the converted value is not a number or not a boolean, then we return null
 * @param paramName The query string parameter name
 * @param type The expected type value (string, boolean, number)
 * @param locationSearch (optional) Used in place of the global location.search, if desired
 */
export const getQueryParamValue = (
  paramName: string,
  type: string = "string",
  locationSearch?: string
) => {
  const qs = queryString.parse(
    !isNil(locationSearch) ? locationSearch : window.location.search
  );
  const value = get(qs, paramName, null);
  if (type === "number") {
    const num = Number(value);
    return isNaN(num) || value === "" ? null : num;
  } else if (type === "boolean") {
    return ["true", "false"].includes(value) ? value === "true" : null;
  } else {
    return value;
  }
};

/**
 * Retrieves the sort information from the current location
 * @param query Either a query string or a query object
 * @param defaultSort If provided, this value will be returned if no sort is found in the locationSearch
 */
export function getSortInfo(
  query: string | ParsedQuery<string>,
  defaultSort?: ISortInfo,
  options: { sortKey: string; sortSeparator: string } = {
    sortKey: "orderBy",
    sortSeparator: " ",
  }
): ISortInfo {
  const { sortKey, sortSeparator } = options;
  const qs = typeof query === "string" ? queryString.parse(query) : query;
  const sort = get(qs, sortKey) || "";
  if (!sort) {
    return !!defaultSort ? defaultSort : { hasSort: false };
  } else {
    const [sortBy, direction] = sort.toString().split(sortSeparator);
    const isDescending = direction === "DESC";
    return {
      hasSort: true,
      sortBy,
      isDescending,
    };
  }
}

/**
 * Convenience function for determining whether a query string parameter has changed
 * Note: requires the use of the HOC withRouter
 */
export const hasQueryParamChanged = (
  queryParamName: string,
  propsA: any,
  propsB: any
): boolean => {
  const qsA = queryString.parse(get(propsA, "location.search", {}));
  const qsB = queryString.parse(get(propsB, "location.search", {}));
  return isDifferent(qsA[queryParamName], qsB[queryParamName]);
};
