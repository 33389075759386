import IAction from "../../types/IAction";
import { Profile, UserContext } from "../../types-business/Profile";
import actionTypes, { LOGOUT } from "../actionTypes";
import { getSubdomain, isValidAlias } from "@app/utils/helpers";
import axios from "axios";
import constants from "../../utils/constants";
import { getMyInfo, logout } from "../../API";
import { resetApp } from "../../_Home/actions";
import { getMyCompanyId, getMyCompanyAlias } from "../reducers/selectors";
import get from "lodash/get";
import { getDefaultSubdomain } from "@app/redux/data/config";

const NOOP = () => {};

export const loadMyProfile = () => ({
  type: actionTypes.LOAD_MY_PROFILE,
  promise: getMyInfo(),
});

export const refreshMyProfile = () => ({
  type: actionTypes.REFRESH_MY_PROFILE,
  promise: getMyInfo(),
});

export const setUserContext = (
  userContext: UserContext | Profile
): IAction => ({
  type: actionTypes.SET_USER_CONTEXT,
  payload: userContext,
});

export const fetchLoginUrl = (forceAlias?: string) => (dispatch, getState) => {
  const alias = getSubdomain();
  const defaultSubdomain = getDefaultSubdomain(getState());
  const companyId = getMyCompanyId(getState());
  const companyAlias = getMyCompanyAlias(getState());
  let loginUrl = constants.DEFAULT_LOGIN_URL;

  if (forceAlias || isValidAlias(alias, defaultSubdomain)) {
    // Check if the user is logged and the company is different
    if (!!companyId && alias !== companyAlias) {
      // The user will be redirected to the proper company hostname due the SSO logout will not work
      const href = window.location.href;
      window.location.href = href.replace(
        alias,
        companyAlias || defaultSubdomain
      );
    } else {
      axios
        .get(
          `${constants.BASE_URL}/api/companies/federated/alias/${
            forceAlias || alias
          }`
        )
        .then((response) => {
          // Set federation login url
          loginUrl = get(response, "data.federationUrl") || loginUrl;
          dispatch(getLoginUrlAction(loginUrl));
        });
    }
  } else {
    // Set the default login url
    dispatch(getLoginUrlAction(loginUrl));
  }
};

const getLoginUrlAction = (url) => ({
  type: actionTypes.FETCH_LOGIN_URL,
  payload: url,
});

export const logoutUser = (onSuccess = NOOP, onFailure = NOOP) => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
      promise: logout(),
      meta: {
        onSuccess: () => {
          dispatch(setUserContext(null));
          dispatch(resetApp());
          onSuccess();
        },
        onFailure: () => {
          onFailure();
        },
      },
    });
  };
};
