import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get } from "@app/utils/lodash";
import { resolveCompanyId } from "@app/utils/helpers";
import * as API from "@app/API";
import { denormalize, normalize } from "normalizr";
import { usersSchema } from "./schemas";
import { User } from "@app/entities/users";
import { createSelector } from "reselect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_USER } from "@app/_Administration/actions/types";

//#region TYPES
const USER_PROFILES_LOAD = "users/load";
const CREATE_USER = "users/create";
const UPDATE_USER_STATUS = "users/update-status";
//#endregion

//#region ACTIONS
export const loadAllUsers = (company?: number) => {
  return (dispatch, getState) => {
    const companyId = company || resolveCompanyId(getState());
    let sortInfo = {
      hasSort: true,
      sortBy: "lastName",
      isDescending: false,
    };
    dispatch({
      type: USER_PROFILES_LOAD,
      promise: API.fetchUserAccounts(companyId, 0, sortInfo, undefined, 500),
      meta: {
        companyId,
      },
    });
  };
};
//#endregion

//#region SELECTORS
export const getUserState = (state) => state.data.users;

export const getUsers = createSelector(
  getUserState,
  (state) => state.users || {}
);

export const getUserIds = createSelector(
  getUserState,
  (state) => state.userIds
);

export const getUsersList = createSelector(
  getUserIds,
  getUsers,
  (userIds, users) => {
    return denormalize(userIds, usersSchema, {
      users,
    });
  }
);

export const getUsersStatus = createSelector(
  getUserState,
  (state) => state.usersStatus
);

export const hasUsersError = createSelector(
  getUsersStatus,
  (status) => status === DataStatus.Error
);

export const isLoadingUsers = createSelector(
  getUsersStatus,
  (status) => status === DataStatus.Loading
);

const getResourceKey = createSelector(getUserState, (state) => state.key);
//#endregion

//#region
export const useUsers = (companyId: number = resolveCompanyId()) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getUsersStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || (!!key && key !== companyId)) {
      dispatch(loadAllUsers(companyId));
    }
  }, [companyId, dispatch]);

  const status = useSelector(getUsersStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);
  const isSubmitting = isDataSubmitting(status);
  const usersList = useSelector(getUsersList);
  const usersMap = useSelector(getUsers);

  return [
    usersList,
    usersMap,
    { isLoading, hasError, hasLoaded, isSubmitting },
    { loadUsers: loadAllUsers },
  ];
};

export const useUser = (userId: number | string) => {
  const [, userMap, status, actions] = useUsers();
  return [get(userMap, `[${userId}]`), status, actions];
};
//#endregion

export interface UserState {
  users: { [key: number]: User };
  userIds: number[];
  usersStatus: DataStatus;
  key: number;
}

export const initialState: UserState = {
  users: null,
  userIds: [],
  usersStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [USER_PROFILES_LOAD]: (state, action: any) => {
      return handle(state, action, {
        start: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Loading,
        }),
        failure: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Error,
        }),
        success: (s: UserState): UserState => {
          const { entities, result } = normalize(
            get(action, "payload.data.content", []),
            usersSchema
          );
          const { users } = entities;
          return {
            ...s,
            users,
            userIds: result,
            usersStatus: DataStatus.Done,
            key: action.meta.companyId,
          };
        },
      });
    },
    [UPDATE_USER]: (state, action) => {
      return handle(state, action, {
        start: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Submitting,
        }),
        success: (s: UserState): UserState => {
          const updatedProfile: any = get(action, "payload.data");
          return {
            ...s,
            usersStatus: DataStatus.Done,
            users: {
              ...s.users,
              [updatedProfile.userId]: updatedProfile,
            },
          };
        },
        failure: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Done, // failure reverts to Done (since error is reserved for load errors)
        }),
      });
    },
    [UPDATE_USER_STATUS]: (state, action) => {
      return handle(state, action, {
        start: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Submitting,
        }),
        failure: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Done,
        }), // failure reverts to "done" (since error is reserved for load errors)
        success: (s: UserState): UserState => {
          const userId = get(action, "meta.userId", 0);
          const profile = s.users[userId];
          const updatedProfile = {
            ...profile,
            status: get(action, "meta.status") || profile.status,
          };
          return {
            ...s,
            users: {
              ...s.users,
              [userId]: updatedProfile,
            },
          };
        },
      });
    },
    [CREATE_USER]: (state, action) => {
      return handle(state, action, {
        start: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Submitting,
        }),
        failure: (s: UserState): UserState => ({
          ...s,
          usersStatus: DataStatus.Done,
        }), // failure reverts to "done" (since error is reserved for load errors)
        success: (s: UserState): UserState => {
          const newUser: any = get(action, "payload.data");
          return {
            ...s,
            users: {
              ...s.users,
              [newUser.userId]: newUser,
            },
            userIds: [...s.userIds, newUser.userId],
            usersStatus: DataStatus.Done,
          };
        },
      });
    },
  },
  initialState
);
