import { get } from "@app/utils/lodash";
import { getUserId, User } from "../users";

// previously known as "chat" but moving to use word "comment(s)", since "chat" implies something more akin to real-time communication
export interface Comment {
  id: string | number;
  chatCode: string;
  text: string;
  createdBy: User;
  createdDate: string;
  updatedBy: User;
  updatedDate: string;
}

export interface CommentReference {
  id: string;
  title: string;
  url: string;
}

export enum CommentType {
  Document = "DOCUMENT",
  Playbook = "PLAYBOOK",
  Clause = "CLAUSE",
  Ticket = "TICKET",
}

export const getCommentId = (comment: Comment) => get(comment, "id");

export const getCommentKey = (type: string, entityId: number | string) =>
  `${type}-${entityId}`;

export const getCreatedByUser = (comment: Comment) => get(comment, "createdBy");

export const getCreatedById = (comment: Comment) =>
  getUserId(getCreatedByUser(comment));

export const getCommentContent = (comment: Comment) =>
  get(comment, "text") ?? "";
