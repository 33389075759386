import without from "lodash/without";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import isNil from "lodash/isNil";
import xor from "lodash/xor";
import queryString, { ParsedQuery } from "query-string";
import { last } from "../lodash";

export const getAppliedFilters = (filterParams: string[] = ["filters"]) => {
  return filterParams.reduce((result, param) => {
    const filters = new QueryFilters(
      window.location.search,
      param
    ).getFilters();

    return { ...result, ...filters };
  }, {});
};

export interface IRange {
  start: number | null;
  end: number | null;
}

export type IFilterValue = string | string[];

interface IParsedValue {
  name: string;
  val: IFilterValue;
}

export interface ITimeDelta {
  time: number;
  unit: string;
}

export interface ITimeDeltaRange {
  start: ITimeDelta;
  end: ITimeDelta;
}

// Convenience utility for updating the filters object with a value
const addParsedValue = (value: IParsedValue, target: any): void => {
  const { name, val } = value;
  if (name) {
    target[name] = val;
  }
};

/**
 * The QueryFilters class provides a wrapper around filter-related functionality in the Akorda application.
 * It uses a custom syntax for parsing urls for filter information.
 * It allows for parsed filters to be updated, added, removed
 * It allows for filters to be serialized to a new query string
 */
class QueryFilters {
  filters: any;
  queryStringParamName: string;
  qs: any;
  public static filterDelimiter: string = ";";
  public static filterKeyMultiValueDelimiter: string = ":";
  public static filterKeySingleValueDelimiter: string = "=";
  public static filterValueDelimiter: string = ",";
  public static filterValueRangeDelimiter: string = "~";
  public static notSet = "not-set";
  public static exclusionValue = "exclusion-value";

  constructor(
    query: string | ParsedQuery<string | number> = window.location.search,
    filtersName: string = "filters"
  ) {
    const qs = typeof query === "string" ? queryString.parse(query) : query;
    this.queryStringParamName = filtersName;
    this.qs = qs;
    this.filters = QueryFilters.parse(qs[filtersName] || "");
  }

  addValue(key: string, value: IFilterValue, isExclusion: boolean = false) {
    if (!key) return;
    const filter = this.getValue(key);
    const isArray = Array.isArray(value);
    if (!filter || !isArray) {
      this.setValue(key, value, isExclusion);
    } else if (isArray) {
      this.setValue(
        key,
        xor(filter as string[], value as string[]),
        isExclusion
      );
    }
  }

  getFilters() {
    return this.filters;
  }

  getValue(key: string): IFilterValue {
    const filters = this.getFilters();
    let values = filters[key];
    const isArray = Array.isArray(values);
    if (isArray) {
      values = without(values, QueryFilters.exclusionValue);
    }
    return values || null;
  }

  hasFilter(key: string): boolean {
    const value = this.getValue(key);
    return !isNil(value) && !isEmpty(value);
  }

  isExclusionFilter(key: string): boolean {
    const filters = this.getFilters();
    const values = filters[key];
    return this.isExclusionFilterValue(last(values));
  }

  hasValue(key: string, value: IFilterValue) {
    const values = this.getValue(key);
    if (isNil(values)) {
      return false;
    }
    return Array.isArray(values)
      ? values.includes(value as string)
      : isEqual(values, value);
  }

  setFilters(filters: any = {}) {
    Object.keys(filters).forEach((key) => this.addValue(key, filters[key]));
  }

  public static parse = (query) => {
    const filters = {};
    if (!query) {
      return filters;
    }
    query.split(QueryFilters.filterDelimiter).forEach((value) => {
      const parsedValue = QueryFilters.parseMultiValue(value);
      addParsedValue(parsedValue, filters);
    });
    return filters;
  };

  public static isNegationFilter = (filterName: string = "") => {
    return !isEmpty(filterName) && filterName.startsWith("!");
  };

  public static parseMultiValue = (value: string): IParsedValue => {
    const parsed: any = {};
    const [name, val] = value.split(QueryFilters.filterKeyMultiValueDelimiter);

    const hasNameAndValue = name && !isNil(val);
    const isNegationFilter = QueryFilters.isNegationFilter(name);
    const isNullFilter = isNegationFilter && isNil(val);
    const isExclusionFilter = isNegationFilter && !isNil(val);

    if (hasNameAndValue || isNullFilter) {
      parsed.name = name.replace(/^!/, ""); // remove the bang (!) indicating a null filter value from the name, since internally we'll treat this as having the 'not-set' value

      if (isNullFilter) {
        parsed.val = [QueryFilters.notSet];
      } else {
        parsed.val = val
          .split(QueryFilters.filterValueDelimiter)
          .map((v) => decodeURIComponent(v));
      }

      if (isExclusionFilter) {
        parsed.val.push(QueryFilters.exclusionValue);
      }
    }

    return parsed;
  };

  removeAll() {
    this.filters = {};
  }

  removeFilter(key: string) {
    const filters = this.getFilters();
    delete filters[key];
  }

  removeValue(key: string, value: string) {
    if (!!key && !!value) {
      const filter = this.getValue(key);
      if (!Array.isArray(filter)) {
        return;
      }
      if (filter.includes(value)) {
        const isExclusion = this.isExclusionFilter(key);
        this.setValue(
          key,
          without(filter, value, QueryFilters.exclusionValue),
          isExclusion
        );
      }
    }
  }

  setValue(key: string, value: IFilterValue, isExclusion: boolean = false) {
    if (!key) return;
    const hasValue = !isEmpty(value);
    const isEmptyRange = value === "~";
    if (!hasValue || isEmptyRange) {
      this.removeFilter(key);
    } else if (isExclusion) {
      let values = value;
      if (!Array.isArray(values)) {
        values = [values];
      }
      values.push(QueryFilters.exclusionValue);
      this.filters[key] = values;
    } else {
      this.filters[key] = value;
    }
  }

  isNullFilterValue = (value: string) => value === QueryFilters.notSet;

  isExclusionFilterValue = (value: string) =>
    value === QueryFilters.exclusionValue;

  toList(): string[] {
    const filters = [];
    keys(this.getFilters()).forEach((key) => {
      if (this.hasFilter(key)) {
        let values = this.getValue(key);
        if (!Array.isArray(values)) {
          values = [values];
        }
        // encode the values and join them with the value delimiter
        values = values
          .map((v) => encodeURIComponent(v))
          .join(QueryFilters.filterValueDelimiter);

        const isNullFilter = this.isNullFilterValue(values);

        const isExclusionFilter = this.isExclusionFilter(key);

        let filter = isNullFilter || isExclusionFilter ? `!${key}` : key;

        if (!isNullFilter) {
          filter = `${filter}${QueryFilters.filterKeyMultiValueDelimiter}${values}`;
        }

        filters.push(filter);
      }
    });
    return filters;
  }

  toString(): string {
    return this.toList().join(QueryFilters.filterDelimiter);
  }

  toQuery(): { [key: string]: string | number | boolean } {
    const qs = Object.assign({}, this.qs);
    const filterString = this.toString();
    if (!filterString) {
      delete qs[this.queryStringParamName];
    } else {
      qs[this.queryStringParamName] = filterString;
    }
    return qs;
  }

  toQueryString(): string {
    return queryString.stringify(this.toQuery());
  }
}

export default QueryFilters;
