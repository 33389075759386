import contractTypes from "./contract-types";
import playbookHistory from "./playbook-history";
import playbookList from "./playbook-list";
import playbookSearch from "./playbook-search";
import playbookStats from "./playbook-stats";
import playbookSchemas from "./playbook-schemas";
import playbookSummary from "./playbook-summary";

import { combineReducers } from "redux";

export default combineReducers({
  contractTypes,
  playbookHistory,
  playbookList,
  playbookSearch,
  playbookStats,
  playbookSchemas,
  playbookSummary,
});
