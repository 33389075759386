import { getAppUrl, getSubdomain, isAppInIframe } from "@app/utils/helpers";
import queryString from "query-string";

/**
 * Redirects the user to the external login page (Auth0) via a BFF, where it looks
 * up the login url using the company alias (subdomain).
 *
 * Two required query params are the `alias` and the `appUrl`. The alias helps in
 * looking up the company data, in case there is a configured OpenID configuration.
 * The base appUrl ensures a proper redirect-uri is provided to Auth0.
 */
export function getLoginRedirectUrl() {
  const isIframeAuth = isAppInIframe();
  const { protocol, host, pathname, search } = window.location;
  const redirectUrlPath = "/api/login/redirect";

  let appUrl = `${protocol}//${host}`;

  const alias = getSubdomain() || "app";

  const loginUrl = `${appUrl}${redirectUrlPath}?${queryString.stringify({
    appUrl, // e.g., https://app.legalsiftercontrol.com
    alias, // e.g., app or datadog.app
    returnTo: `${pathname}${search}`, // user to be redirected here post-login
    uuid: crypto.randomUUID(), // used as the state value for authentication with auth0 and maps to session information stored in the bff
    isIframeAuth,
  })}`;

  if (isIframeAuth) {
    return `/iframe-login?${queryString.stringify({ loginUrl })}`;
  }

  return loginUrl;
}

export function getLogoutRedirectUrl() {
  const isIframeAuth = isAppInIframe();

  const returnTo = `${getAppUrl()}/logout?${queryString.stringify({
    isIframeAuth,
  })}`;

  const logoutUrl = `/api/login/logout/redirect?${queryString.stringify({
    returnTo,
  })}`;

  return logoutUrl;
}
