import { RESET_PLAYBOOK_LIST } from "@app/redux/data/playbook/playbook-list";
import constants from "@app/utils/constants";
import { handleActions } from "redux-actions";
import { UPDATE_PLAYBOOK_LIST_UI_STATE } from "../actions/types";

const { PLAYBOOK_LIST_TABS } = constants;
const { COMPANY_PAPER } = PLAYBOOK_LIST_TABS;

export interface PlaybookListReduxState {
  tab: string;
  sort: any;
  search: string;
}

export const initialState: PlaybookListReduxState = {
  // the selected tab,
  tab: COMPANY_PAPER,
  // the sort criteria
  sort: null,
  // the search filter
  search: null,
};

export default handleActions(
  {
    [UPDATE_PLAYBOOK_LIST_UI_STATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [RESET_PLAYBOOK_LIST]: () => ({
      ...initialState,
    }),
  },
  initialState
);
