import scrollIntoView from "scroll-into-view-if-needed";

/**
 * Creates a div element which contains the string HTML passed by parameter
 * @param innerHTML string HTML
 */
export function createDivElement(innerHTML: string = "") {
  const divElement = document.createElement("div");
  divElement.innerHTML = innerHTML;
  return divElement;
}

export function getBodyContent(html: string): string {
  if (!!html && html.length > 0) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.innerHTML;
  }
  return "";
}

export const getHTMLStringFromFragment = (
  documentFragment: DocumentFragment
) => {
  const isFragment = isDocumentFragment(documentFragment);
  if (isFragment) {
    const divElement = document.createElement("div");
    divElement.appendChild(documentFragment.cloneNode(true));
    return divElement.innerHTML;
  } else {
    return null;
  }
};

export function getTextContent(element: HTMLElement) {
  const cloneElement = element.cloneNode(true) as HTMLElement;
  const deleteTags = cloneElement.querySelectorAll("del");
  for (let i = 0; i < deleteTags.length; i++) {
    deleteTags[i].parentNode.removeChild(deleteTags[i]);
  }
  return cloneElement.textContent;
}

export function getTextContentFromHTMLString(html: string) {
  return createDivElement(html).textContent;
}

export const getTextFromFragment = (documentFragment: any) => {
  const isFragment = isDocumentFragment(documentFragment);
  if (isFragment) {
    const divElement = document.createElement("div");
    divElement.appendChild(documentFragment.cloneNode(true));
    return getTextContent(divElement);
  } else {
    return null;
  }
};

/**
 * Injects a bold element in a HTML string with the passed name
 * Need to check which is the firstElement tag to identifies if it can be inserted as a block/inline element
 * @param content
 * @param name
 * @param separator
 */
export const injectNameInHtmlString = (
  content: string,
  name: string,
  separator: string = "."
) => {
  const boldNameElement = document.createElement("b");
  boldNameElement.classList.add("akorda-name-element");
  boldNameElement.innerHTML = `${name}${separator} `;
  // Create a div container element
  const contentElement = createDivElement(content);
  // Try to insert the bold element into the example html content
  // If it is a text node the first element or a list
  if (
    !!contentElement.firstChild &&
    (contentElement.firstChild.nodeType === Node.TEXT_NODE ||
      ["ol", "ul"].includes(
        contentElement.firstElementChild.tagName.toLowerCase()
      ))
  ) {
    // insert the bold element as the first element of container div
    // <b>{Name}.</b><ol></ol>
    contentElement.insertAdjacentElement("afterbegin", boldNameElement);
  } else {
    // insert the bold element after the first child element or container div
    // <p><b>{Name}.</b></p>
    const element = contentElement.firstElementChild || contentElement;
    element.insertAdjacentElement("afterbegin", boldNameElement);
  }
  return contentElement.innerHTML;
};

export const isDocumentFragment = (documentFragment: DocumentFragment) => {
  return (
    !!documentFragment &&
    documentFragment.nodeType === Node.DOCUMENT_FRAGMENT_NODE
  );
};

export function ScrollToElement(
  element: any,
  block: string = "center",
  inline: string = "center",
  scrollMode: string = "if-needed"
) {
  // Checking ownerDocument due to testing issue
  if (!!element && !!element.ownerDocument) {
    const inlineCenter: any = {
      behavior: "smooth",
      block,
      inline,
      scrollMode,
    };
    scrollIntoView(element, inlineCenter);
  }
}

// Using HTML native scroll functions
export function ScrollIntoViewIfNeeded(element: any, block: string = "start") {
  // Checking ownerDocument due to testing issue
  if (!!element && !!element.ownerDocument) {
    const scrollIntoViewOptions: any = {
      behavior: "smooth",
      block,
      inline: block,
    };
    !!element.scrollIntoViewIfNeeded
      ? element.scrollIntoViewIfNeeded(scrollIntoViewOptions)
      : element.scrollIntoView(scrollIntoViewOptions);
  }
}
