import commonActionTypes, {
  DOWNLOAD_FILE,
  SET_SHOW_TOOLBOX_DIFF,
  SEARCH_TOOLBOX_TAB,
  UPDATE_SEARCHTOOLBOX_MATCH_COUNT,
  UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX,
  TOGGLE_SHOW_TOOLBOX_SEARCH,
  RESET_TOOLBOX,
} from "../actionTypes";
import IAction from "../../types/IAction";
import axios from "axios";
import {
  addLoader,
  downloadContent as downloadFileHelper,
  getContentFromResponse,
  getFilenameFromResponse,
  removeLoader,
} from "../../utils/helpers";
import { isDownloading } from "../selectors/downloader";
import isNil from "lodash/isNil";
import { isShowingDiffToStandard } from "../selectors/examplesToolbox";

const NOOP = () => {};

export const resetToolbox = () => ({
  type: RESET_TOOLBOX,
});

export const minimizeToolbox = () => ({
  type: commonActionTypes.TOOLBOX_MINIMIZE,
});

export const maximizeToolbox = () => ({
  type: commonActionTypes.TOOLBOX_MAXIMIZE,
});

export const hideToolbox = () => ({
  type: commonActionTypes.TOOLBOX_HIDE,
});

export const showToolbox = () => ({
  type: commonActionTypes.TOOLBOX_SHOW,
});

export const changeToolboxTab = (tabId: string | number) => ({
  type: commonActionTypes.TOOLBOX_CHANGE_TAB,
  payload: tabId,
});

/**
 * Whether to show a diff view of fallbacks/past-examples in the toolbox (diff'd against the standard)
 * @param show Optional boolean to force showing/not-showing. If not provided, the action will toggle.
 */
export const toggleShowToolboxDiff = (show?: boolean) => {
  return (dispatch, getState) => {
    const payload = !isNil(show) ? show : !isShowingDiffToStandard(getState());
    return dispatch({
      type: SET_SHOW_TOOLBOX_DIFF,
      payload,
    });
  };
};

/**
 * Sets the text search associated with a toolbox tab
 * @param search
 */
export const searchToolboxTab = (search: string): IAction => ({
  type: SEARCH_TOOLBOX_TAB,
  payload: search,
});

/**
 * Updates the number of search matches found in the content of a toolbox tab
 * @param count The number of search matches found in the toolbox tab
 */
export const updateSearchToolboxMatchCount = (count: number): IAction => ({
  type: UPDATE_SEARCHTOOLBOX_MATCH_COUNT,
  payload: count,
});

export const updateSearchToolboxMatchFocusIndex = (index: number): IAction => ({
  type: UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX,
  payload: index,
});

export const toggleShowToolboxSearch = (): IAction => ({
  type: TOGGLE_SHOW_TOOLBOX_SEARCH,
});

export const downloadFile =
  (url: string, onSuccess: () => void = NOOP, onFailure: () => void = NOOP) =>
  (dispatch) => {
    return dispatch(
      downloadFileWithAPICall(
        () =>
          axios.get(url, {
            responseType: "blob", // important
            withCredentials: true,
          }),
        onSuccess,
        onFailure
      )
    );
  };

export const downloadFileWithAPICall =
  (
    call: () => Promise<any>,
    onSuccess: (response?: any) => void = NOOP,
    onFailure: (response?: any) => void = NOOP,
    title?: string,
    mimeType?: string
  ) =>
  (dispatch, getState) => {
    const isDownloadingFile = isDownloading(getState());
    if (isDownloadingFile) return;
    addLoader();
    return dispatch({
      type: DOWNLOAD_FILE,
      promise: call(),
      meta: {
        onSuccess: (response) => {
          onSuccess(response);
          const content = getContentFromResponse(response);
          const filename = getFilenameFromResponse(response, title, mimeType);
          downloadFileHelper(content, filename);
          removeLoader();
        },
        onFailure: (response) => {
          onFailure(response);
          removeLoader();
        },
      },
    });
  };
