import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
} from "@app/redux/utils";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";

import { createSelector } from "reselect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParsedQuery } from "query-string";

//#region TYPES
const LOAD_CLAUSE_INSIGHTS = "insights/clause/load";
const RESET_CLAUSE_INSIGHTS = "insights/clause/reset";
//#endregion

//#region ACTIONS
export const loadClauseInsights = (
  playbookId: string | number,
  clauseId: string | number,
  query?: ParsedQuery
) => {
  return {
    type: LOAD_CLAUSE_INSIGHTS,
    promise: API.loadClauseInsights(playbookId, clauseId, query),
  };
};

export const resetClauseInsights = () => ({
  type: RESET_CLAUSE_INSIGHTS,
});
//#endregion

//#region SELECTORS
export const getClauseInsightsState = (state) => state.data.insights.clause;

export const getClauseInsights = createSelector(
  getClauseInsightsState,
  (state) => state.clauseInsights || {}
);

export const getClauseInsightsStatus = createSelector(
  getClauseInsightsState,
  (state) => state.clauseInsightsStatus
);

export const hasClauseInsightsError = createSelector(
  getClauseInsightsStatus,
  (status) => status === DataStatus.Error
);

export const isLoadingClauseInsights = createSelector(
  getClauseInsightsStatus,
  (status) => status === DataStatus.Loading
);

const getResourceKey = createSelector(
  getClauseInsightsState,
  (state) => state.key
);
//#endregion

//#region Hooks
export const useClauseInsights = (
  playbookId: number | string,
  clauseId: string | number
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getClauseInsightsStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || hasDataError(status) || key !== playbookId) {
      dispatch(loadClauseInsights(playbookId, clauseId));
    }
  }, [playbookId, clauseId, dispatch]);

  const insights = useSelector(getClauseInsights);
  const status = useSelector(getClauseInsightsStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);

  return [insights, { isLoading, hasError, hasLoaded }];
};
//#endregion

export interface ClauseInsightsState {
  clauseInsights: any;
  clauseInsightsStatus: DataStatus;
  key: string;
}

export const initialState: ClauseInsightsState = {
  clauseInsights: null,
  clauseInsightsStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [LOAD_CLAUSE_INSIGHTS]: (state, action) => {
      return handle(state, action, {
        start: (s: ClauseInsightsState): ClauseInsightsState => ({
          ...s,
          clauseInsightsStatus: DataStatus.Loading,
        }),
        failure: (s: ClauseInsightsState): ClauseInsightsState => ({
          ...s,
          clauseInsightsStatus: DataStatus.Error,
        }),
        success: (s: ClauseInsightsState): ClauseInsightsState => {
          const clauseInsights = get(action, "payload.data");
          return {
            ...s,
            clauseInsights,
            clauseInsightsStatus: DataStatus.Done,
          };
        },
      });
    },
    [RESET_CLAUSE_INSIGHTS]: () => ({
      ...initialState,
    }),
  },
  initialState
);
