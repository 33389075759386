import { User } from "@app/entities/users";
import { Api, ApiResponse } from "./_base";
import { AuthGroup } from "@app/entities/auth";
import { AuthContextValue } from "@app/_Login/components/Auth/AuthProvider";

/**
 * Authenticate
 * @param username
 * @param password
 */
export const login = (
  username: string,
  password: string
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/login/authenticate`,
    data: {
      username,
      password,
    },
    method: "POST",
  });
};

export const logout = (): Promise<any> => {
  return Api.execute({ url: `/api/login/logout`, data: {}, method: "POST" });
};

export const isTokenValid = async () => {
  return Api.execute({ url: `/api/login/status` });
};

export const getIdentity = async (): Promise<AuthContextValue> => {
  const response: any = await Api.execute({
    url: `/api/auth/identity`,
  });
  return response.data;
};

export const getMyInfo = (): Promise<ApiResponse<User>> => {
  return Api.execute({ url: `/api/login/my-info` });
};

export function addAuthAsCookies(
  akordaToken: any,
  partitioned = false
): Promise<any> {
  return Api.execute({
    url: "/api/login/add-auth-as-cookies",
    method: "POST",
    data: {
      akordaToken,
      partitioned, // Chromium only for now - for CHIPS - Cookies Having Independent Partitioned State - needed for iframed app
    },
  });
}

export const upsertAuthGroup = (
  data: AuthGroup
): Promise<ApiResponse<AuthGroup>> => {
  return Api.execute({
    url: `/api/proxy/ms-auth/v1/groups`,
    method: "POST",
    data,
  });
};

export const deleteAuthGroup = (code: string) => {
  return Api.execute({
    url: `/api/proxy/ms-auth/v1/groups/${code}?force=true`,
    method: "DELETE",
  });
};

export const fetchPasswordPolicies = (): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/auth/password-policies`,
  });
};
