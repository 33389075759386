import { get, pick } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import {
  DataStatus,
  isDataNotLoaded,
  isDataLoading,
  hasDataError,
  hasDataLoaded,
} from "@app/redux/utils";
import IAction from "@app/types/IAction";
import { IPagination } from "@app/types-business/Project";
import { MetadataField } from "@app/entities/metadata";
import { useDispatch, useSelector } from "react-redux";
import { runSelector } from "@app/redux/utils";
import { useEffect } from "react";

//#region ACTION TYPES
export const LOAD_DOCUMENT_HISTORY = "review/document-history/load";
export const RESET_DOCUMENT_HISTORY = "review/document-history/reset";
//#endregion

//#region ACTIONS
/**
 * Loads the historical information related to a document.
 * @param documentId
 */
export const loadDocumentHistory = (
  documentId: string,
  query: Record<string, any> = {}
): IAction => ({
  type: LOAD_DOCUMENT_HISTORY,
  promise: API.getDocumentHistory(documentId, query),
  meta: {
    documentId,
  },
});

export const resetDocumentHistory = (): IAction => ({
  type: RESET_DOCUMENT_HISTORY,
});
//#endregion

//#region SELECTORS
export const documentHistoryState: (state: any) => DocumentHistoryReduxState = (
  state
) => state.data.review.documentHistory;

export const getDocumentHistoryStatus = createSelector(
  documentHistoryState,
  (state) => state.historyStatus
);

export const isDocumentHistoryLoading = createSelector(
  getDocumentHistoryStatus,
  (status) => isDataLoading(status)
);

export const hasDocumentHistoryError = createSelector(
  getDocumentHistoryStatus,
  (status) => hasDataError(status)
);

export const hasDocumentHistoryLoaded = createSelector(
  getDocumentHistoryStatus,
  (status) => hasDataLoaded(status)
);

export const getDocumentHistory = createSelector(
  documentHistoryState,
  (state) => state.events || []
);

export const getDocumentHistoryPaginationInfo = createSelector(
  documentHistoryState,
  (state) => {
    const pageInfo: any = state.paginationInfo || {};
    return {
      page: pageInfo.page || 0,
      size: pageInfo.pageSize || 0,
      totalPages: pageInfo.totalSize
        ? Math.ceil(pageInfo.totalSize / pageInfo.pageSize)
        : 0,
    };
  }
);

export const getDocumentHistoryMetadata = createSelector(
  documentHistoryState,
  (state) => state.metadata || {}
);

export const getDocumentHistoryResourceKey = createSelector(
  documentHistoryState,
  (state) => state.key
);

//#endregion

//#region HOOKS
export const useDocumentHistory = (
  documentId: string,
  query: Record<string, any> = {}
): any[] => {
  const dispatch = useDispatch();

  const events = useSelector(getDocumentHistory);
  const isLoading = useSelector(isDocumentHistoryLoading);
  const hasError = useSelector(hasDocumentHistoryError);
  const hasLoaded = useSelector(hasDocumentHistoryLoaded);

  useEffect(() => {
    const status = runSelector(getDocumentHistoryStatus);
    const key = runSelector(getDocumentHistoryResourceKey);

    if (
      (!!documentId && isDataNotLoaded(status)) ||
      (!!key && key !== documentId)
    ) {
      dispatch(loadDocumentHistory(documentId, query));
    }
  }, [dispatch, documentId, query]);

  return [events, { isLoading, hasError, hasLoaded }];
};
//#endregion

//#region REDUCER
export interface DocumentHistoryReduxState {
  events: any[];
  historyStatus: DataStatus;
  paginationInfo: IPagination;
  metadata?: { [key: string]: MetadataField };
  key: string;
}

export const initialState: DocumentHistoryReduxState = {
  // the history of the document
  events: [],
  // status of the document history load: completed, loading, error
  historyStatus: DataStatus.NotLoaded,
  // pagination information
  paginationInfo: null,
  // company metadata
  metadata: null,
  // document id
  key: null,
};

export default handleActions(
  {
    [LOAD_DOCUMENT_HISTORY]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          historyStatus: DataStatus.Loading,
        }),
        failure: (s) => ({ ...s, historyStatus: DataStatus.Error }),
        success: (s) => {
          const paginationInfo: any = pick(
            get(action, "payload.data", {
              page: 0,
              pageSize: 20,
              totalSize: 0,
              totalPages: 0,
            }),
            ["page", "pageSize", "totalSize", "totalPages"]
          );
          return {
            ...s,
            historyStatus: DataStatus.Done,
            events: get(action, "payload.data.events", []),
            paginationInfo,
            metadata: get(action, "payload.data.metadata", {}),
            key: get(action, "meta.documentId", null),
          };
        },
      });
    },
    [RESET_DOCUMENT_HISTORY]: () => ({ ...initialState }),
  },
  initialState
);

//#endregion
