import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import * as API from "@app/API/companies";

export const resetApp = (): IAction => ({
  type: actionTypes.RESET_APP,
});

export const switchCompany = (
  companyId: number,
  onSuccess?: () => void,
  onFailure?: (response: any) => void
) => ({
  type: actionTypes.SWITCH_COMPANY,
  promise: API.switchCompany(companyId),
  meta: {
    onSuccess,
    onFailure,
  },
});
