import axios from "axios";
import constants from "../utils/constants";

/**
 * Create clustering for the given clauseId
 * @param companyId
 * @param playbookId
 * @param clauseId
 */
export function executeClustering(
  companyId: number,
  playbookId: number,
  clauseId: number | string
): Promise<any> {
  return axios.get(
    `${constants.BASE_URL}/api/proxy/ms-embeddings/v2/embeddings/clusters?playbookId=${playbookId}&companyId=${companyId}&clauseId=${clauseId}`,
    {
      withCredentials: true,
    }
  );
}
