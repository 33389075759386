import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import {
  SHOW_EXPORT_MODAL,
  HIDE_EXPORT_MODAL,
  LOAD_DOCUMENT_SUMMARY,
} from "../../actionTypes";

export const initialState = {
  // whether to show the import version modal
  showModal: false,
  // the document id for export
  documentId: null,
  // the version number for export
  versionNumber: null,
  // the version control for export
  versionControl: null,
};

export default handleActions(
  {
    [SHOW_EXPORT_MODAL]: (state, action) => {
      return {
        ...state,
        showModal: true,
        documentId: action.payload.documentId,
        versionNumber: action.payload.versionNumber,
        versionControl: action.payload.versionControl,
      };
    },
    [HIDE_EXPORT_MODAL]: (state) => {
      // TODO documentId, versionNumber and versionControl were not reset to fix export issue when the modal is closed quickly
      // We need a download component and a refactor to decouple exportModal with the downloadFile
      return {
        ...state,
        showModal: false,
      };
    },
    [LOAD_DOCUMENT_SUMMARY]: (state, action: any) => {
      return handle(state, action, {
        success: (s) => {
          return {
            ...s,
            versionControl: action.payload.data.document.versionControl,
          };
        },
      });
    },
  },
  initialState
);
