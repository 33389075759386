import { createSelector } from "reselect";
import { get, keyBy, compact } from "@app/utils/lodash";
import { QUOTAS } from "../../quotas/quotas";
import { getUserFullName, getUserId } from "@app/entities/users";

const loginState = (state) => state.login || {};

export const getUserContextStatus = createSelector(
  loginState,
  (state) => state.userContextStatus
);

export const isUserContextLoading = createSelector(
  getUserContextStatus,
  (status) => status === "loading"
);

export const hasUserContextError = createSelector(
  getUserContextStatus,
  (status) => status === "error"
);

export const hasUserContextLoaded = createSelector(
  getUserContextStatus,
  (status) => status === "completed"
);

export const getUserContext = createSelector(
  loginState,
  (state) => state.userContext
);

export const getMyUserId = createSelector(getUserContext, (userContext) =>
  get(userContext, "userId")
);

export const getMyUserEmail = createSelector(getUserContext, (userContext) =>
  get(userContext, "email")
);

export const getMyUserName = createSelector(getUserContext, (userContext) =>
  getUserFullName(userContext)
);

export const getMyUserInfo = createSelector(getUserContext, (userContext) => ({
  userId: getUserId(userContext),
  userName: getUserFullName(userContext),
}));

export const getMyCompanyId = createSelector(getUserContext, (userContext) =>
  get(userContext, "company.companyId", null)
);

export const getMyCompanyAlias = createSelector(getUserContext, (userContext) =>
  get(userContext, "company.alias", "")
);

export const getMyRoles = createSelector(getUserContext, (userContext) =>
  get(userContext, "groups", [])
);

const getMyQuotas = createSelector(
  getUserContext,
  (userContext) => get(userContext, "company.quotas", []) || []
);

export const getMyQuotasAsMap = createSelector(getMyQuotas, (quotas) =>
  keyBy(quotas, "name")
);

export const getDocumentsQuota = createSelector(getMyQuotasAsMap, (quotas) =>
  get(quotas[QUOTAS.DOCUMENTS], "quota", 0)
);

export const getPlaybooksQuota = createSelector(getMyQuotasAsMap, (quotas) =>
  get(quotas[QUOTAS.PLAYBOOKS], "quota", 0)
);

export const getUsersQuota = createSelector(getMyQuotasAsMap, (quotas) =>
  get(quotas[QUOTAS.USERS], "quota", 0)
);

export const getAuthGroupsMap = createSelector(
  getUserContext,
  (userContext) => get(userContext, "company.authConfig.groups") || {}
);

export const getAuthScopesMap = createSelector(
  getUserContext,
  (userContext) => get(userContext, "company.authConfig.scopes") || {}
);

export const getDocumentScopes = createSelector(
  getAuthScopesMap,
  (scopesMap): string[] => {
    const documentPermissions = get(scopesMap, "document.permissions") || [];
    return compact(
      documentPermissions.map((permission) => permission?.fullName)
    );
  }
);

export const hasUserContext = createSelector(getMyUserId, (userId) => !!userId);

export const getLoginUrl = createSelector(
  loginState,
  (state) => state.loginUrl
);
