import { get, isEqual, isNil, isEmpty, intersection } from "../lodash";

export const isDevelopment = process.env.NODE_ENV === "development";

export function cleanStateFromLocalStorage(key: string) {
  if (!!window && !!window.localStorage) {
    localStorage.removeItem(key);
  }
}

export const fromLocalStorage = (key: string) => {
  const localStorage = getLocalStorage();
  if (localStorage) {
    return localStorage.getItem(key);
  }
};

const getLocalStorage = () =>
  isClientSide() && get(window, "localStorage", null);

export const toLocalStorage = (key: string, value: any) => {
  const localStorage = getLocalStorage();
  if (localStorage) {
    localStorage.setItem(key, value);
  }
};

/**
 * Convenience function for React "props" value comparison
 * @param field The path of the property to get in each props object
 * @param propsA The first props object for comparison
 * @param propsB The secon props object for comparison
 */
export const hasPropChanged = (
  field: string,
  propsA: any,
  propsB: any
): boolean => {
  const valueA = get(propsA, field, null);
  const valueB = get(propsB, field, null);
  return isDifferent(valueA, valueB);
};

export function isClientSide(): boolean {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

/**
 * Convenience function utilizing lodash's "isEqual". Returns
 * true if the two values are different. Note: _.isEqual uses a deep comparison
 * for objects and arrays and should be used wisely in cases of large object
 * or array comparisons.
 * @param valueA First value to compare
 * @param valueB Second value to compare
 */
export const isDifferent = (valueA: any, valueB: any): boolean => {
  return !isEqual(valueA, valueB);
};

/**
 * Return true if the two arrays intersect (have at least one value in common)
 * @param array1
 * @param array2
 * @returns true if a value in one array is also in the other
 */
export function hasIntersect(array1: any[], array2: any[]) {
  return !isEmpty(intersection(array1, array2));
}

export function loadStateFromLocalStorage(key: string) {
  if (isClientSide()) {
    if (!!window && !!window.localStorage) {
      const serializedState = localStorage.getItem(key);
      try {
        if (!isNil(serializedState)) {
          return JSON.parse(serializedState);
        }
      } catch (e) {
        if (!isNil(serializedState)) {
          return serializedState;
        }
      }
    }
    return null;
  }
}

/**
 * Simple empty function to use as stub
 */
export const NOOP = (..._args: any[]) => {};

export function saveStateInLocalStorage(key: string, state: any) {
  if (!!window && !!window.localStorage) {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch {
      // ignore write errors
    }
  }
}

export function isAppInIframe() {
  return window.location !== window.parent.location;
}

export function getAppUrl(): string {
  const { protocol, hostname, port } = window.location;
  let appUrl = `${protocol}//${hostname}`;
  if (port) {
    appUrl += `:${port}`;
  }
  return appUrl;
}
