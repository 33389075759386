import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { denormalize, normalize } from "normalizr";
import { customFieldsListSchema } from "./schemas";
import { get, orderBy, without } from "@app/utils/lodash";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { SettingsEntity } from "@app/entities/settings";
import { customFieldSchemaName } from "./custom-field-schema";
import { CustomField } from "@app/entities/custom-fields";

// #region ACTION TYPES
export const LOAD_CUSTOM_FIELDS = "custom-fields/load";
export const RESET_CUSTOM_FIELDS = "custom-fields/reset";
export const CREATE_CUSTOM_FIELD = "custom-field/create";
export const UPDATE_CUSTOM_FIELD = "custom-fields/update";
export const DELETE_CUSTOM_FIELD = "custom-fields/delete";

// #endregion

//#region ACTIONS
export const loadCustomFields = (): any => ({
  type: LOAD_CUSTOM_FIELDS,
  promise: API.loadEntities(customFieldSchemaName),
});

export const resetCustomFields = (): IAction => ({
  type: RESET_CUSTOM_FIELDS,
});

export const createCustomField = (
  customField: CustomField,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_CUSTOM_FIELD,
  promise: API.createEntity(customFieldSchemaName, customField, true),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateCustomField = (
  formId: string,
  data: Partial<SettingsEntity<CustomField>>,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_CUSTOM_FIELD,
  promise: API.updateEntity(formId, data),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteCustomField = (
  formId: string,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: DELETE_CUSTOM_FIELD,
  promise: API.deleteEntity(formId),
  meta: {
    formId,
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const customFieldState = (state) =>
  state.data.assembly.customFields.customFields;

export const getCustomFields = createSelector(
  customFieldState,
  (state) => get(state, "customFields") || {}
);

export const getCustomFieldIds = createSelector(
  customFieldState,
  (state) => state.customFieldIds
);

export const getCustomFieldsList = createSelector(
  getCustomFields,
  getCustomFieldIds,
  (customFields, ids) => {
    return orderBy(
      denormalize(ids, customFieldsListSchema, {
        customFields,
      }),
      ["createdDate"],
      ["desc"]
    );
  }
);

export const getCustomFieldsStatus = createSelector(
  customFieldState,
  (state) => state.customFieldsStatus
);

export const isCustomFieldsLoading = createSelector(
  getCustomFieldsStatus,
  (status) => status === DataStatus.Loading
);

export const isCustomFieldSubmitting = createSelector(
  getCustomFieldsStatus,
  (status) => status === DataStatus.Submitting
);

export const hasCustomFieldError = createSelector(
  getCustomFieldsStatus,
  (status) => status === DataStatus.Error
);

export const hasCustomFieldListLoaded = createSelector(
  getCustomFieldsStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region HOOKS
export const useCustomFields = () => {
  const dispatch = useDispatch();

  const customFields = useSelector(getCustomFieldsList);
  const customFieldsMap = useSelector(getCustomFields);
  const isLoading = useSelector(isCustomFieldsLoading);
  const hasError = useSelector(hasCustomFieldError);
  const hasLoaded = useSelector(hasCustomFieldListLoaded);
  const isSubmitting = useSelector(isCustomFieldSubmitting);

  useEffect(() => {
    const status = runSelector(getCustomFieldsStatus);
    if (isDataNotLoaded(status)) {
      dispatch(loadCustomFields());
    }
  }, [dispatch]);

  return [
    customFields,
    customFieldsMap,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface CustomFieldsState {
  customFields: { [key: string]: SettingsEntity<CustomField> };
  customFieldIds: string[];
  customFieldsStatus: DataStatus;
}

export const initialState: CustomFieldsState = {
  customFields: {},
  customFieldIds: [],
  customFieldsStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_CUSTOM_FIELDS]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          customFieldsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          customFieldsStatus: DataStatus.Error,
        }),
        success: (s) => {
          const data = get(action, "payload.data.entities", []);
          const { entities, result } = normalize(data, customFieldsListSchema);
          const { customFields } = entities;

          return {
            ...s,
            customFieldsStatus: DataStatus.Done,
            customFieldIds: result,
            customFields,
          };
        },
      }),
    [CREATE_CUSTOM_FIELD]: (state, action) =>
      handle(state, action, {
        start: (s): CustomFieldsState => ({
          ...s,
          customFieldsStatus: DataStatus.Submitting,
        }),
        failure: (s): CustomFieldsState => ({
          ...s,
          customFieldsStatus: DataStatus.Done,
        }),
        success: (s): CustomFieldsState => {
          const newCustomField: any = get(action, "payload.data");

          return {
            ...s,
            customFieldsStatus: DataStatus.Done,
            customFieldIds: [...s.customFieldIds, newCustomField.id],
            customFields: {
              ...s.customFields,
              [newCustomField.id]: newCustomField,
            },
          };
        },
      }),
    [DELETE_CUSTOM_FIELD]: (state, action) =>
      handle(state, action, {
        start: (s): CustomFieldsState => ({
          ...s,
          customFieldsStatus: DataStatus.Submitting,
        }),
        failure: (s): CustomFieldsState => ({
          ...s,
          customFieldsStatus: DataStatus.Done,
        }),
        success: (s): CustomFieldsState => {
          const customFieldId: string = get(action, "meta.formId") || "";
          const customFields = {
            ...s.customFields,
          };
          delete customFields[customFieldId];
          return {
            ...s,
            customFieldsStatus: DataStatus.Done,
            customFieldIds: without(s.customFieldIds, customFieldId),
            customFields,
          };
        },
      }),
    [UPDATE_CUSTOM_FIELD]: (state, action) =>
      handle(state, action, {
        success: (s): CustomFieldsState => {
          const customField: any = get(action, "payload.data");
          const customFields = {
            ...s.customFields,
            [customField.id]: customField,
          };
          return {
            ...s,
            customFields,
          };
        },
      }),
    [RESET_CUSTOM_FIELDS]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
