import { useEffect } from "react";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { createSelector } from "reselect";
import * as API from "@app/API";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { get } from "@app/utils/lodash";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "@app/entities/dashboard";
import { SettingsEntity, SettingsEntityAcl } from "@app/entities/settings";
import { NOOP } from "@app/utils/helpers";

//#region Action Types
export const LOAD_DASHBOARD_DETAILS = "/dashboard/details/load";
export const UPDATE_DASHBOARD = "/dashboard/update";
export const SHARE_DASHBOARD = "/dashboard/share";
export const UNSHARE_DASHBOARD = "/dashboard/unshare";
export const RESET_DASHBOARD_DETAILS = "/dashboard/details/reset";
//#endregion

//#region Actions
export const loadDashboardDetails = (dashboardId: string) => ({
  type: LOAD_DASHBOARD_DETAILS,
  promise: API.loadEntityDetails(dashboardId),
  meta: {
    dashboardId,
  },
});

export const updateDashboard = (
  dashboardId: string,
  data: Partial<SettingsEntity<Dashboard>>,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_DASHBOARD,
  promise: API.updateEntity(dashboardId, data),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const shareDashboard = (
  dashboardId: string,
  acl: SettingsEntityAcl,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: SHARE_DASHBOARD,
  promise: API.shareEntity(dashboardId, acl),
  meta: {
    acl,
    onSuccess,
    onFailure,
  },
});

export const unshareDashboard = (
  dashboardId: string,
  userId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UNSHARE_DASHBOARD,
  promise: API.unshareEntity(dashboardId, [userId]),
  meta: {
    userId,
    onSuccess,
    onFailure,
  },
});

export const resetDashboardDetails = () => ({
  type: RESET_DASHBOARD_DETAILS,
});
//#endregion

//#region Selectors
export const dashboardDetailsState = (state) =>
  state.data.dashboard.dashboardDetails;

export const getDashboard = createSelector(
  dashboardDetailsState,
  (state) => state.dashboard
);

export const getDashboardStatus = createSelector(
  dashboardDetailsState,
  (state) => state.dashboardStatus
);

export const isDashboardLoading = createSelector(
  getDashboardStatus,
  isDataLoading
);

export const isDashboardSubmitting = createSelector(
  getDashboardStatus,
  isDataSubmitting
);

export const hasDashboardError = createSelector(
  getDashboardStatus,
  hasDataError
);

export const hasDashboardLoaded = createSelector(
  getDashboardStatus,
  hasDataLoaded
);

export const getDashboardAcl = createSelector(
  dashboardDetailsState,
  (state) => state.dashboardAcl
);

const getResourceKey = createSelector(
  dashboardDetailsState,
  (state) => state.key
);

//#endregion

//#region Hooks
export const useDashboard = (dashboardId: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getDashboardStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || (!!key && key !== dashboardId)) {
      dispatch(loadDashboardDetails(dashboardId));
    }
  }, [dashboardId, dispatch]);

  const dashboard = useSelector(getDashboard);
  const acl = useSelector(getDashboardAcl);
  const isLoading = useSelector(isDashboardLoading);
  const isSubmitting = useSelector(isDashboardSubmitting);
  const hasError = useSelector(hasDashboardError);
  const hasLoaded = useSelector(hasDashboardLoaded);

  return [dashboard, acl, { isLoading, isSubmitting, hasError, hasLoaded }];
};

//#region REDUCER
export interface DashboardDetails {
  key: number;
  dashboard: any;
  dashboardAcl: any[];
  dashboardStatus: string;
}

export const initialState: DashboardDetails = {
  // id of the loaded dashboard
  key: null,
  // dashboard information
  dashboard: null,
  // status of the dashboard load: loading, error, completed
  dashboardStatus: DataStatus.NotLoaded,
  // acl for the dashboard
  dashboardAcl: [],
};

//#endregion

export default handleActions(
  {
    [LOAD_DASHBOARD_DETAILS]: (state: DashboardDetails, action: any) => {
      return handle(state, action, {
        start: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Loading,
        }),
        failure: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Error,
        }),
        success: (s: DashboardDetails) => ({
          ...s,
          key: action.meta.dashboardId,
          dashboardStatus: DataStatus.Done,
          dashboard: get(action, "payload.entity") || null,
          dashboardAcl: get(action, "payload.acl") || [],
        }),
      });
    },
    [UPDATE_DASHBOARD]: (state: DashboardDetails, action: any) => {
      return handle(state, action, {
        start: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Submitting,
        }),
        failure: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
        }),
        success: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
          dashboard: get(action, "payload.data"),
        }),
      });
    },
    [SHARE_DASHBOARD]: (state: DashboardDetails, action: any) => {
      return handle(state, action, {
        start: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Submitting,
        }),
        failure: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
        }),
        success: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
          dashboardAcl: get(action, "meta.acl"),
        }),
      });
    },
    [UNSHARE_DASHBOARD]: (state: DashboardDetails, action: any) => {
      return handle(state, action, {
        start: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Submitting,
        }),
        failure: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
        }),
        success: (s: DashboardDetails) => ({
          ...s,
          dashboardStatus: DataStatus.Done,
          dashboardAcl: (s.dashboardAcl || []).filter((aclEntry) => {
            const removedUserId = get(action, "meta.userId") || "";
            return aclEntry.userId !== removedUserId;
          }),
        }),
      });
    },
    [RESET_DASHBOARD_DETAILS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
