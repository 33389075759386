import { get, pick } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { DataStatus } from "@app/redux/utils";
import IAction from "@app/types/IAction";
import { IPagination } from "@app/types-business/Project";

//#region ACTION TYPES
export const LOAD_TICKET_HISTORY = "ticket/history/load";
export const RESET_TICKET_HISTORY = "ticket/history/reset";
//#endregion

//#region ACTIONS
/**
 * Loads the historical information related to a ticket.
 * @param ticketId
 */
export const loadTicketHistory = (
  ticketId: string,
  query: Record<string, any> = {}
): IAction => ({
  type: LOAD_TICKET_HISTORY,
  promise: API.getTicketHistory(ticketId, query),
});

export const resetTicketHistory = (): IAction => ({
  type: RESET_TICKET_HISTORY,
});
//#endregion

//#region SELECTORS
export const ticketHistoryState: (state: any) => TicketHistoryReduxState = (
  state
) => state.data.ticket.ticketHistory;

export const getTicketHistoryStatus = createSelector(
  ticketHistoryState,
  (state) => state.historyStatus
);

export const isTicketHistoryLoading = createSelector(
  getTicketHistoryStatus,
  (status) => status === DataStatus.Loading
);

export const hasTicketHistoryError = createSelector(
  getTicketHistoryStatus,
  (status) => status === DataStatus.Error
);

export const getTicketHistory = createSelector(
  ticketHistoryState,
  (state) => state.events
);

export const getTicketHistoryPaginationInfo = createSelector(
  ticketHistoryState,
  (state): IPagination => {
    return state.paginationInfo;
  }
);

//#endregion

//#region HOOKS
//#endregion

//#region REDUCER
export interface TicketHistoryReduxState {
  events: any[];
  historyStatus: string;
  paginationInfo: IPagination;
}

export const initialState: TicketHistoryReduxState = {
  // the history of the ticket
  events: [],
  // status of the ticket history load: completed, loading, error
  historyStatus: null,
  // pagination information
  paginationInfo: null,
};

export default handleActions(
  {
    [LOAD_TICKET_HISTORY]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          historyStatus: DataStatus.Loading,
        }),
        failure: (s) => ({ ...s, historyStatus: DataStatus.Error }),
        success: (s) => {
          const paginationInfo: any = pick(
            get(action, "payload.data", {
              page: 0,
              pageSize: 20,
              totalSize: 0,
              totalPages: 0,
            }),
            ["page", "pageSize", "totalSize", "totalPages"]
          );

          return {
            ...s,
            historyStatus: DataStatus.Done,
            events: get(action, "payload.data.events", []),
            paginationInfo,
          };
        },
      });
    },
    [RESET_TICKET_HISTORY]: () => ({ ...initialState }),
  },
  initialState
);

//#endregion
