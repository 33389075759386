import { createSelector } from "reselect";
import constants from "../../../utils/constants";
import {
  getSelectedClause,
  getSelectedClauseIssuesLog,
} from "../../../_Contract/selectors";
import get from "lodash/get";
import find from "lodash/find";
import { isNil } from "@app/utils/lodash";
import { getUsers } from "@app/redux/data/users";
import { getUserFullName } from "@app/entities/users";

export const getToolboxState = (state) => state.toolbox;

export const isShowingToolbox = createSelector(
  getToolboxState,
  (state) => state.isShowingToolbox
);

export const isToolboxMinimized = createSelector(
  getToolboxState,
  (state) => state.isToolboxMinimized
);

export const getActiveToolboxTabId = createSelector(
  getToolboxState,
  (state) => state.activeToolboxTabId
);

export const getStandard = createSelector(
  getToolboxState,
  (state) => state.standard ?? ""
);

// Format guidance as example for the toolbox
export const getGuidance = createSelector(
  getToolboxState,
  (state) => state.guidance
);

export const getFallbacks = createSelector(
  getToolboxState,
  (state) => state.fallbacks || []
);

export const getFallbacksCount = createSelector(
  getFallbacks,
  (fallbacks) => fallbacks.length
);

export const getPastExamples = createSelector(
  getToolboxState,
  (state) => state.pastExamples || []
);

export const getPastExamplesCount = createSelector(
  getPastExamples,
  (pastExamples) => pastExamples.length
);

export const getPlaybookClauseStatus = createSelector(
  getToolboxState,
  (state) => state.playbookClauseStatus
);

export const isPlaybookClauseLoading = createSelector(
  getPlaybookClauseStatus,
  (status) => status === "loading"
);

export const hasPlaybookClauseError = createSelector(
  getPlaybookClauseStatus,
  (status) => status === "error"
);

export const isShowingDiffToStandard = createSelector(
  getToolboxState,
  (state) => state.isShowingDiffToStandard
);

export const isShowingSearch = createSelector(
  getToolboxState,
  (state) => state.isShowingSearch
);

export const getToolboxSearchInfo = createSelector(
  getToolboxState,
  (state) => ({
    search: state.search || "",
    searchMatchCount: state.searchMatchCount || 0,
    searchMatchFocusIndex: state.searchMatchFocusIndex || 0,
  })
);

export const isToolboxLoading = createSelector(
  getPlaybookClauseStatus,
  (playbookClauseStatus) => playbookClauseStatus === "loading"
);

export const hasToolboxLoadingError = createSelector(
  getPlaybookClauseStatus,
  (playbookClauseStatus) => playbookClauseStatus === "error"
);

// Returns all issue logs (for the selected clause) that are
// related to approvals, which includes any primary approval
// actions, but also any subsequent secondary actions that
// add have an impact on (or might have been impacted by) the
// primary approval action. E.g., reverting a change that was
// previously approved is included here.
export const getSelectedClauseApprovalLogs = createSelector(
  getSelectedClauseIssuesLog,
  (issueLogs) => {
    let toolboxIssues = [];
    issueLogs.forEach((issue, index: number) => {
      if (
        [
          constants.CLAUSE_ACTION_MAP.GET_APPROVAL,
          constants.CLAUSE_ACTION_MAP.CANCEL_APPROVAL,
          constants.CLAUSE_ACTION_MAP.APPROVE,
          constants.CLAUSE_ACTION_MAP.DENY,
        ].includes(issue.action)
      ) {
        toolboxIssues.push(issue);
        if (
          [
            constants.CLAUSE_ACTION_MAP.DENY,
            constants.CLAUSE_ACTION_MAP.APPROVE,
          ].includes(issue.action) &&
          !!issueLogs[index + 1] &&
          [
            constants.CLAUSE_ACTION_MAP.REVERT,
            constants.CLAUSE_ACTION_MAP.REOPEN,
            constants.CLAUSE_ACTION_MAP.REVISE,
            constants.CLAUSE_ACTION_MAP.REJECT,
            constants.CLAUSE_ACTION_MAP.ACCEPT,
          ].includes(issueLogs[index + 1].action)
        ) {
          toolboxIssues.push(issueLogs[index + 1]);
        }
      }
    });
    return toolboxIssues;
  }
);

// Takes the approval logs of the selected clause and remaps
// it into a slightly simpler format for approvals in the toolbox
// component
export const getApprovalsForToolbox = createSelector(
  getSelectedClauseApprovalLogs,
  getUsers,
  (logs, userMap) => {
    // reverse the order to be reverse chronological
    return logs.reverse().map((log) => {
      const { createdBy, issueLogId, action, createdDate } = log;
      const approver = get(log, "approver", { name: "", email: "" });
      const user = userMap[createdBy];
      return {
        id: issueLogId,
        action,
        userId: createdBy,
        userName: !isNil(user) ? getUserFullName(user) : approver.name,
        userEmail: !isNil(user) ? user.email : approver.email,
        comment: get(log, "comment", ""),
        html: get(log, "metadata.html", null),
        date: createdDate, // only use the createdDate
      };
    });
  }
);

export const getIssues = createSelector(
  getToolboxState,
  getSelectedClause,
  (state, clause) => {
    const issues = state.issues || [];
    return issues.map((issue) => ({
      ...issue,
      isRecommended: !!find(
        get(clause, "recommendations", []),
        (recommendation) => recommendation.playbookIssueId === issue.id
      ),
    }));
  }
);

export const getSelectedClauseStructureSettings = createSelector(
  getToolboxState,
  (state) => get(state, "structureSettings") || {}
);
