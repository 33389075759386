import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import get from "lodash/get";
import {
  CREATE_USER,
  HIDE_EDIT_USER_FORM,
  LOAD_USERS,
  RESET_ADMINISTRATION_STATE,
  SHOW_EDIT_USER_FORM,
  UPDATE_USER,
  UPDATE_USER_STATUS,
} from "../actions/types";
import { User } from "../../types-business/Profile";
import { IInsightsPagination } from "../../types-business/Insights";
import pick from "lodash/pick";

const defaultPagination = {
  page: 0,
  size: 20,
  totalPages: 0,
  totalElements: 0,
};

export interface State {
  // users listing for a company
  users: User[];
  // status of users api call (loading, error, completed)
  usersStatus: string;
  // users list pagination info
  paginationInfo: IInsightsPagination;
  // status of edit user operation (error, updating, completed)
  editUserStatus: string;
  // status of create user operation (error, loading, completed)
  addUserStatus: string;
}

export const initialState: State = {
  users: [],
  usersStatus: null,
  paginationInfo: { ...defaultPagination },
  editUserStatus: null,
  addUserStatus: null,
};

const handleUpdateUser = (state, action) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      editUserStatus: "updating",
    }),
    success: (s) => {
      return {
        ...s,
        editUserStatus: "completed",
      };
    },
    failure: (s) => {
      return {
        ...s,
        editUserStatus: "error",
      };
    },
  });
};

export default handleActions(
  {
    // reverts the state back to the initial state
    [RESET_ADMINISTRATION_STATE]: () => ({
      ...initialState,
    }),
    [SHOW_EDIT_USER_FORM]: (state, action) => ({
      ...state,
      editUserId: action.payload,
    }),
    [HIDE_EDIT_USER_FORM]: (state) => ({
      ...state,
      editUserId: null,
    }),
    [LOAD_USERS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          usersStatus: "loading",
        }),
        success: (s) => {
          const paginationInfo: any = pick(
            get(action, "payload.data", defaultPagination),
            ["size", "totalPages", "totalElements"]
          );
          paginationInfo.page = get(action, "payload.data.number", 0);

          return {
            ...s,
            users: get(action, "payload.data.content", []),
            usersStatus: "completed",
            paginationInfo,
          };
        },
        failure: (s) => {
          return {
            ...s,
            usersStatus: "error",
          };
        },
      });
    },
    [CREATE_USER]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          addUserStatus: "loading",
        }),
        success: (s) => ({
          ...s,
          addUserStatus: "completed",
        }),
        failure: (s) => ({
          ...s,
          addUserStatus: "error",
        }),
      });
    },
    [UPDATE_USER]: handleUpdateUser,
    [UPDATE_USER_STATUS]: handleUpdateUser,
  },
  initialState
);
