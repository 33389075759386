import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import find from "lodash/find";
import get from "lodash/get";
import filter from "lodash/filter";
import xor from "lodash/xor";
import {
  RESET_SUMMARY_STATE,
  CANCEL_SEND_APPROVALS,
  ENQUEUE_APPROVAL,
  SET_APPROVAL_QUEUE,
} from "../actions/types";
import constants from "../../utils/constants";
import { LOAD_DOCUMENT } from "../../_Document/actions/types";

export interface ApprovalQueueReduxState {
  queue: any[];
}

export const initialState: ApprovalQueueReduxState = {
  // the active tab in the card header
  queue: [],
};

const filterQueueWithClauses = (state, action) => {
  const issues = get(action, "payload.data.document.issues.items", []);
  // Filter approval queue if the clause is in the document and it has open status
  const queue = filter(state.queue, (approval) => {
    const issue = find(issues, (c) => c.issueId === approval.issueId);
    return !!issue && issue.turnStatus === constants.TURN_STATUS_MAP.OPEN;
  });
  return queue;
};

export default handleActions(
  {
    [RESET_SUMMARY_STATE]: () => {
      return {
        ...initialState,
      };
    },
    [ENQUEUE_APPROVAL]: (state, action) => ({
      ...state,
      queue: [...state.queue, action.payload],
    }),
    [LOAD_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          const queue = filterQueueWithClauses(state, action);
          return {
            ...s,
            queue,
          };
        },
      });
    },
    [CANCEL_SEND_APPROVALS]: (state, action: any) => {
      const queue = xor(state.queue, action.payload);
      return {
        ...state,
        queue,
      };
    },
    [SET_APPROVAL_QUEUE]: (state, action: any) => ({
      ...state,
      queue: action.payload,
    }),
  },
  initialState
);
