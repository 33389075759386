import { FC } from "react";
import Notification from "./Notification";

export const SuccessNotification: FC<{ children }> = ({ children }) => (
  <Notification iconName="fal-check-circle">{children}</Notification>
);

export const WarningNotification: FC<{ children }> = ({ children }) => (
  <Notification iconName="fas-exclamation-triangle">{children}</Notification>
);

export const ErrorNotification: FC<{ children }> = ({ children }) => (
  <Notification iconName="fas-exclamation-circle">{children}</Notification>
);
