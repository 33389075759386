import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { JSONSchema7 } from "json-schema";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";

export const customFieldSchemaName = "ASSEMBLY_CUSTOM_FIELD";

// #region ACTION TYPES
export const LOAD_CUSTOM_FIELD_SCHEMA = "custom-field-schema/load";
export const RESET_CUSTOM_FIELD_SCHEMA = "custom-field-schema/reset";
// #endregion

//#region ACTIONS
export const loadCustomFieldSchema = (): any => ({
  type: LOAD_CUSTOM_FIELD_SCHEMA,
  promise: API.loadSchema(customFieldSchemaName),
});

export const resetCustomFieldSchema = (): IAction => ({
  type: RESET_CUSTOM_FIELD_SCHEMA,
});
//#endregion

//#region SELECTORS
export const customFieldSchemaState = (state) =>
  state.data.assembly.customFields.customFieldSchema;

export const getCustomFieldSchema = createSelector(
  customFieldSchemaState,
  (state) => get(state, "customFieldSchema") || {}
);

export const getCustomFieldSchemaStatus = createSelector(
  customFieldSchemaState,
  (state) => state.customFieldSchemaStatus
);

export const isCustomFieldSchemaLoading = createSelector(
  getCustomFieldSchemaStatus,
  (status) => status === DataStatus.Loading
);

export const hasCustomFieldSchemaError = createSelector(
  getCustomFieldSchemaStatus,
  (status) => status === DataStatus.Error
);

export const hasCustomFieldSchemaLoaded = createSelector(
  getCustomFieldSchemaStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region HOOKS
export const useCustomFieldSchema = () => {
  const dispatch = useDispatch();

  const customFieldSchema = useSelector(getCustomFieldSchema);
  const isLoading = useSelector(isCustomFieldSchemaLoading);
  const hasError = useSelector(hasCustomFieldSchemaError);
  const hasLoaded = useSelector(hasCustomFieldSchemaLoaded);

  useEffect(() => {
    const status = runSelector(getCustomFieldSchemaStatus);
    if (isDataNotLoaded(status)) {
      dispatch(loadCustomFieldSchema());
    }
  }, [dispatch]);

  return [
    customFieldSchema,
    {
      isLoading,
      hasError,
      hasLoaded,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface CustomFieldSchemaState {
  customFieldSchema: JSONSchema7;
  customFieldSchemaStatus: DataStatus;
}

export const initialState: CustomFieldSchemaState = {
  customFieldSchema: null,
  customFieldSchemaStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_CUSTOM_FIELD_SCHEMA]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          customFieldSchemaStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          customFieldSchemaStatus: DataStatus.Error,
        }),
        success: (s) => {
          const customFieldSchema = get(action, "payload.data.schema");
          return {
            ...s,
            customFieldSchemaStatus: DataStatus.Done,
            customFieldSchema,
          };
        },
      }),
    [RESET_CUSTOM_FIELD_SCHEMA]: () => ({
      ...initialState,
    }),
  },
  initialState
);
/* #endregion */
