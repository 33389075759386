export const QUOTAS = {
  PLAYBOOKS: "PLAYBOOKS",
  DOCUMENTS: "DOCUMENTS",
  USERS: "USERS",
};

const QuotaSettings = {
  [QUOTAS.PLAYBOOKS]: 3,
  [QUOTAS.DOCUMENTS]: 100,
  [QUOTAS.USERS]: 3,
};

export default QuotaSettings;
