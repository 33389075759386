import classNames from "classnames";
import s from "./styles.module.scss";

type Props = {
  id?: string; // html element id
  size?: string; // sm, md, lg
  name?: string; // material-icon name or font-awesome name
  src?: string; // img src url
  height?: number; // (optional, in pixels)
  width?: number; // (optional, in pixels)
  fontSize?: number; // (optional, in pixels)
  className?: string;
  title?: string; // alt for images
  children?: any;
  dataElmId?: string;
  spin?: boolean; // only available for font-awesome
  faClassName?: string; // additional font-awesome class to apply to font-awesome icon
  onClick?: (e) => void;
  disabled?: boolean;
};

/**
 * The <Icon/> component provides a simple wrapper around either (a) an image (<img>) element, or (b)
 * a font-based icon such as those from font-awesome or material-icons. If a "src" prop is provided,
 * it's assumed that this is an img. If a name is provided, it's assumed that this is a font-icon. The
 * src and name props are mutually exclusive.
 *
 * The "height" and "width" props are optional (for <img>s) as is the "fontSize" prop (for font-icons) to
 * provide an easier option to override default size values than via custom css classes.
 */

const matchFontAwesome = (name: string) => {
  // Regex indicates font-awesome icon when the regex matches. The icon name can be
  // fa-[name], far-[name], and fas-[name], where "far-" indicates a regular icon
  // and "fas-" indicates a "solid" icon. A font-awesome icon with "fa-" will render
  // as the default, but this could be either regular or solid depending on the icon.
  const isFontAwesomeRegEx = /^(fa-brands|fa[r?s?b?l?d?]?)-(.*)/;
  return name && name.match(isFontAwesomeRegEx);
};

const NOOP = () => {};

const icon = (props: Props) => {
  const {
    height,
    width,
    fontSize,
    dataElmId,
    disabled,
    faClassName,
    spin,
    onClick = NOOP,
  } = props;
  const size = props.size || "";

  if (props.src) {
    // inline-style for easy size adjustment
    const style = {
      height,
      width,
    };
    return (
      <img
        alt={props.title}
        data-elm-id={dataElmId}
        className={classNames(s.icon, s[size], props.className)}
        src={props.src}
        style={style}
        data-testid="image-icon"
        onClick={(e) => !disabled && onClick(e)}
      />
    );
  } else {
    const isFontAwesome = matchFontAwesome(props.name);
    // if the icon is a font-awesome icon, create the class name from the matched groups
    const iconClass = isFontAwesome
      ? `${isFontAwesome[1]} fa-${isFontAwesome[2]}${!!spin ? " fa-spin" : ""}${
          !!faClassName ? ` ${faClassName}` : ""
        }`
      : "material-icons";

    // inline-style for easy size adjustment
    const style = {
      fontSize,
    };
    return (
      <span
        id={props.id}
        className={classNames("font-icon", s.icon, s[size], props.className, {
          "akorda-disabled": disabled,
        })}
        data-testid="font-icon"
        onClick={(e) => !disabled && !!onClick && onClick(e)}
        data-elm-id={dataElmId}
      >
        <i title={props.title} className={iconClass} style={style}>
          {!isFontAwesome ? props.name : null}
        </i>
        {props.children}
      </span>
    );
  }
};

export default icon;
