import { schema } from "normalizr";

// Define a users schema
export const action = new schema.Entity("actions");

// Define your comments schema
export const option = new schema.Entity("options", {
  actions: [action],
});

export const questionSchema = new schema.Entity("questions", {
  options: [option],
});

export const questionsSchema = [questionSchema];
