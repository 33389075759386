import { useEffect } from "react";
import { Base64 } from "js-base64";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get } from "@app/utils/lodash";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { DocumentService } from "@app/types-business/documents";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalRequest, CancelApprovalRequest, ProcessIssue } from "@app/API";
import { NOOP } from "@app/utils/helpers";
import { QueueableReduxThunk } from "@app/utils/redux/action-queue";
import {
  DocumentRelationName,
  getVersionControl,
  NegotiationAction,
} from "@app/entities/document";
import { ApiSuccessResponse } from "@app/API/_base";

// for review operations (processing clauses, for example), we want to track the status of the operation
// but we don't want the same kind of spinner we show during updates, so we have this specialized status
// which helps block clause toolbar actions
const reviewOpInProgressStatus = "review-op-in-progress";
const reviewOpHasConflictErrorStatus = "review-op-conflict-error";

// #region ACTION TYPES
export const LOAD_REVIEW_DOCUMENT = "review/document/load";
export const RESET_REVIEW_DOCUMENT = "review/document/reset";
export const LOAD_ORIGINAL_CONTENT = "review/document/original";
export const PROCESS_CLAUSE_ISSUE = "review/document/clause-issue/process";
export const REQUEST_APPROVAL = "review/document/clause-issue/request-approval";
export const CANCEL_APPROVAL = "review/document/clause-issue/cancel-approval";
export const FINALIZE_DOCUMENT = "review/document/finalize";
export const CHANGE_NEGOTIATION = "review/document/change-negotiation";
export const UPDATE_DOCUMENT = "review/document/update-document";
export const UPDATE_DOCUMENT_HTML = "review/document/update-html";
export const WILL_SAVE = "review/document/willsave";
export const UPDATE_DOCUMENT_ACCESS = "review/document/update-access";
export const RELATE_DOCUMENT = "review/document/relate-document";
export const REFRESH_DOCUMENT = "review/document/refresh-document";

// #endregion

// #region ACTIONS
export const loadReviewDocument = (
  documentId: string,
  params?: {
    versionNumber?: number;
    extensions?: string;
    initialState?: boolean;
  }
): IAction => ({
  type: LOAD_REVIEW_DOCUMENT,
  promise: API.getReviewDocument(documentId, params),
  meta: {
    documentId,
  },
});

export const loadOriginalContent = (documentId: string): IAction => ({
  type: LOAD_ORIGINAL_CONTENT,
  promise: API.getReviewDocumentHtml(documentId, { initialState: true }),
});

export const resetReviewDocument = (): IAction => ({
  type: RESET_REVIEW_DOCUMENT,
});

export const processIssue =
  (
    documentId: string,
    data: Omit<ProcessIssue, "versionControl">,
    onSuccess = NOOP,
    onFailure = NOOP
  ): QueueableReduxThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (hasReviewOperationConflictError(state)) return Promise.reject();

    // add the version control to the payload from state, since it may have been updated by the last service call in the queue
    const document = getReviewDocument(state);
    const versionControl = getVersionControl(document);
    return dispatch({
      type: PROCESS_CLAUSE_ISSUE,
      promise: API.processClauseIssue(documentId, { ...data, versionControl }),
      meta: {
        onSuccess,
        onFailure,
      },
    });
  };

export const updateHtml =
  (documentId: string, html: string, onFailure = NOOP): QueueableReduxThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (hasReviewOperationConflictError(state)) return Promise.reject();

    // add the version control to the payload from state, since it may have been updated by the last service call in the queue
    const document = getReviewDocument(state);
    const versionControl = getVersionControl(document);

    return dispatch({
      type: UPDATE_DOCUMENT_HTML,
      promise: API.updateDocument(documentId, {
        content: {
          bytes: Base64.encode(html),
        },
        versionControl,
      }),
      meta: {
        onFailure,
      },
    });
  };

export const requestApproval = (
  documentId: string,
  request: ApprovalRequest[],
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: REQUEST_APPROVAL,
  promise: API.requestApproval(documentId, request),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const cancelApproval = (
  documentId: string,
  request: CancelApprovalRequest,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CANCEL_APPROVAL,
  promise: API.cancelApproval(documentId, request),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateDocument = (
  documentId: string,
  data: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_DOCUMENT,
  promise: API.updateDocument(documentId, data),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const updateDocumentAccess = (
  documentId: string,
  access: DocumentService.UpdateAccessRequest,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_DOCUMENT_ACCESS,
  promise: API.updateAccess(documentId, access),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const finalizeDocument = (
  documentId: string,
  data: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: FINALIZE_DOCUMENT,
  promise: API.finalizeDocument(documentId, data),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const reSendForSignature = (
  documentId: string,
  request: DocumentService.ReSendDocumentForSignatureRequest,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.SendForSignature,
    data: request,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const cancelNegotiation = (
  documentId: string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.CancelNegotiation,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const cancelSignature = (
  documentId: string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.CancelSignature,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const reOpen = (
  documentId: string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.Reopen,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const setInReview = (
  documentId: string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.SetWithUser,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const setWithCounterparty = (
  documentId: string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.SetWithCounterparty,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const sendForReview = (
  documentId: string,
  request: DocumentService.SetWithCounterpartyRequest,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.SetWithCounterparty,
    data: request.setWithCounterparty,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const sendForSignature = (
  documentId: string,
  request: DocumentService.SendDocumentForSignatureRequest,
  onSuccess: (response?: ApiSuccessResponse<any>) => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: CHANGE_NEGOTIATION,
  promise: API.changeNegotiation(documentId, {
    action: NegotiationAction.SendForSignature,
    data: request.sendForSignature,
  }),
  meta: {
    documentId,
    onSuccess,
    onFailure,
  },
});

export const willSave = () => ({
  type: WILL_SAVE,
});

export const addRelatedDocument =
  (
    documentId: string,
    relatedDocumentId: string,
    relationName: DocumentRelationName,
    onSuccess: (data?: any) => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch) => {
    return dispatch({
      type: RELATE_DOCUMENT,
      promise: API.addRelatedDocument(documentId, [
        {
          relatedDocumentId,
          relationName,
        },
      ]),
      meta: {
        documentId,
        onSuccess,
        onFailure,
      },
    });
  };

export const removeRelatedDocument =
  (
    documentId: string,
    relatedDocumentId: string,
    relationName: DocumentRelationName,
    onSuccess: (data?: any) => void = NOOP,
    onFailure: () => void = NOOP
  ): any =>
  (dispatch) =>
    dispatch({
      type: RELATE_DOCUMENT,
      promise: API.removeRelatedDocument(documentId, [
        {
          relatedDocumentId,
          relationName,
        },
      ]),
      meta: {
        documentId,
        onSuccess,
        onFailure,
      },
    });

/**
 * Loads the quickview data/content for a silent document update
 * @param id
 */
export const refreshDocument = (
  id: string,
  onSuccess: (data?: any) => void = NOOP,
  onFailure: () => void = NOOP
) => ({
  type: REFRESH_DOCUMENT,
  promise: API.getReviewDocument(id),
  meta: {
    onSuccess,
    onFailure,
  },
});

// #endregion

// #region SELECTORS
export const reviewDocumentState = (state) => state.data.review.document;

export const getReviewDocument = createSelector(reviewDocumentState, (state) =>
  get(state, "document", null)
);

export const getReviewDocumentContent = createSelector(
  reviewDocumentState,
  (state) => get(state, "content") || {}
);

export const getReviewDocumentClauses = createSelector(
  getReviewDocument,
  (document) => get(document, "issues.items") || []
);

export const getReviewDocumentStatus = createSelector(
  reviewDocumentState,
  (state) => state.status
);

export const isReviewDocumentLoading = createSelector(
  getReviewDocumentStatus,
  (status) => status === DataStatus.Loading
);

export const hasReviewDocumentError = createSelector(
  getReviewDocumentStatus,
  (status) => status === DataStatus.Error
);

export const hasReviewDocumentLoaded = createSelector(
  getReviewDocumentStatus,
  (status) => status === DataStatus.Done
);

export const isReviewDocumentUpdating = createSelector(
  getReviewDocumentStatus,
  (status) => status === DataStatus.Submitting
);

export const isReviewOperationInProgress = createSelector(
  getReviewDocumentStatus,
  (status) => status === reviewOpInProgressStatus
);

export const hasReviewOperationConflictError = createSelector(
  getReviewDocumentStatus,
  (status) => status === reviewOpHasConflictErrorStatus
);

export const isSavePending = createSelector(
  reviewDocumentState,
  (state) => state.willSave
);

export const getResourceKey = createSelector(
  reviewDocumentState,
  (state) => state.key
);
// #endregion

// #region HOOKS
export const useReviewDocument = (
  documentId: string,
  params?: {
    versionNumber?: number;
    extensions?: string;
    initialState?: boolean;
  }
) => {
  const dispatch = useDispatch();

  const document = useSelector(getReviewDocument);
  const isLoading = useSelector(isReviewDocumentLoading);
  const isUpdating = useSelector(isReviewDocumentUpdating);
  const hasError = useSelector(hasReviewDocumentError);
  const hasLoaded = useSelector(hasReviewDocumentLoaded);

  useEffect(() => {
    const status = runSelector(getReviewDocumentStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || key !== documentId) {
      dispatch(loadReviewDocument(documentId, params));
    }
  }, [dispatch, documentId, params]);

  return [document, { isLoading, isUpdating, hasError, hasLoaded }];
};
// #endregion

//#region REDUCER
export interface ReviewDocumentReduxState {
  status: DataStatus | typeof reviewOpInProgressStatus;
  document: DocumentService.ReviewDocument;
  content: DocumentService.ReviewDocumentContent;
  willSave: boolean;
  key: string;
}

export const initialState: ReviewDocumentReduxState = {
  status: DataStatus.NotLoaded,
  document: null,
  content: {
    editor: null,
    original: null,
    quickView: null,
  },
  willSave: false,
  key: null,
};

const handleReviewOperation = (state, action) => {
  return handle(state, action, {
    start: (s: ReviewDocumentReduxState): ReviewDocumentReduxState => ({
      ...s,
      status: reviewOpInProgressStatus,
    }),
    failure: (s) => {
      const newStatus =
        get(action, "payload.response.status") === 409
          ? reviewOpHasConflictErrorStatus
          : DataStatus.Done;
      return {
        ...s,
        status: newStatus,
      };
    },
    success: (s) => {
      return {
        ...s,
        status: DataStatus.Done,
        document: get(action, "payload.data"),
        willSave: false,
      };
    },
  });
};

const handleUpdateDocument = (state, action) => {
  return handle(state, action, {
    success: (s) => {
      const document = get(action, "payload.data");
      return {
        ...s,
        document,
      };
    },
  });
};

export default handleActions(
  {
    [LOAD_REVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          status: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          status: DataStatus.Error,
        }),
        success: (s) => {
          const { document, content } = get(action, "payload.data") || {};
          return {
            ...s,
            key: get(action, "meta.documentId", null),
            document,
            content: {
              ...s.content, // we may have content.original that we need to retain
              ...content,
            },
            status: DataStatus.Done,
            willSave: false,
          };
        },
      });
    },
    [LOAD_ORIGINAL_CONTENT]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          return {
            ...s,
            content: {
              ...s.content,
              original: get(action, "payload.data"),
            },
          };
        },
      });
    },
    [PROCESS_CLAUSE_ISSUE]: handleReviewOperation,
    [REQUEST_APPROVAL]: handleReviewOperation,
    [CANCEL_APPROVAL]: handleReviewOperation,
    [UPDATE_DOCUMENT_HTML]: handleReviewOperation,
    [FINALIZE_DOCUMENT]: (state, action) => {
      const isCurrentDocument = state.key === get(action, "meta.documentId"); // This action can be dispatched in the contract list but it should be ignored here
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: isCurrentDocument ? DataStatus.Loading : s.status,
        }),
        failure: (s) => ({
          ...s,
          status: isCurrentDocument ? DataStatus.Done : s.status,
        }),
        success: (s) => {
          let document = s.document;
          let content = s.content;
          if (isCurrentDocument) {
            document = get(action, "payload.data.document");
            content = {
              ...s.content, // we may have content.original that we need to retain
              ...get(action, "payload.data.content"),
            };
          }
          return {
            ...s,
            document,
            content,
            status: isCurrentDocument ? DataStatus.Done : s.status,
          };
        },
      });
    },
    [UPDATE_DOCUMENT_ACCESS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          status: DataStatus.Done,
        }),
        success: (s) => {
          return {
            ...s,
            document: get(action, "payload.data"),
            status: DataStatus.Done,
          };
        },
      });
    },
    [UPDATE_DOCUMENT]: handleUpdateDocument,
    [RELATE_DOCUMENT]: handleUpdateDocument,
    // a "silent" (no status change) update of the entire quickview to include any updated related docs
    [REFRESH_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          return {
            ...s,
            document: get(action, "payload.data.document"),
          };
        },
      });
    },
    [CHANGE_NEGOTIATION]: (state, action) => {
      const isCurrentDocument = state.key === get(action, "meta.documentId"); // This action can be dispatched in the contract list but it should be ignored here
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: isCurrentDocument ? DataStatus.Submitting : s.status,
        }),
        failure: (s) => ({
          ...s,
          status: isCurrentDocument ? DataStatus.Done : s.status,
        }),
        success: (s) => {
          let document = s.document;
          if (isCurrentDocument) {
            document = get(action, "payload.data");
          }
          return {
            ...s,
            document,
            status: isCurrentDocument ? DataStatus.Done : s.status,
          };
        },
      });
    },
    [WILL_SAVE]: (s) => ({
      ...s,
      willSave: true,
    }),
    [RESET_REVIEW_DOCUMENT]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
