import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import without from "lodash/without";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import get from "lodash/get";
import {
  TOGGLE_CLAUSE_MODAL,
  SHOW_FALLBACK_MODAL,
  HIDE_FALLBACK_MODAL,
  TOGGLE_EXPAND_CLAUSE,
  EXPAND_CLAUSE,
  CREATE_FALLBACK,
  SHOW_PLAYBOOK_ISSUE_MODAL,
  HIDE_PLAYBOOK_ISSUE_MODAL,
  CREATE_PLAYBOOK_ISSUE,
  TOGGLE_OPTIONAL_CLAUSES,
  TRACK_EDITS,
  CREATE_PLAYBOOK_CLAUSE,
  SELECT_PLAYBOOK_CLAUSE,
  DELETE_PLAYBOOK_CLAUSE,
} from "../actions/types";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { isEmpty } from "@app/utils/lodash";
import { isSectionClause } from "@app/entities/playbook/playbook-clause";
import {
  LOAD_PLAYBOOK,
  PUBLISH_PLAYBOOK,
  RESET_PLAYBOOK,
} from "@app/redux/data/playbook/playbook-summary";

export interface PlaybookSummaryReduxState {
  showClauseModal: boolean;
  showFallbackModal: boolean;
  deleteClauseStatus: string;
  expandedClauseIds: number[];
  createClauseStatus: string;
  createFallbackStatus: string;
  publishStatus: string;
  currentPlaybookId: number;
  selectedClauseId: number;
  selectedFeedbackClauseId: number;
  showIssueModal: boolean;
  selectedIssueId: number;
  createIssueStatus: string;
  isShowingOptionalClauses: boolean;
  trackingChanges: any;
}

export const initialState: PlaybookSummaryReduxState = {
  showClauseModal: false,
  showFallbackModal: false,
  createClauseStatus: null,
  deleteClauseStatus: null,
  // ui-state: the list of clause ids that are expanded to show child clauses
  expandedClauseIds: null,
  createFallbackStatus: null,
  publishStatus: null,
  selectedClauseId: null,
  selectedFeedbackClauseId: null,
  showIssueModal: false,
  currentPlaybookId: null,
  selectedIssueId: null,
  createIssueStatus: null,
  // whether the editor show the optional clauses
  isShowingOptionalClauses: false,
  // dictionary for tracking changes
  trackingChanges: null,
};

const updateTrackingChanges = (state, action) => {
  let trackingChanges = cloneDeep(state.trackingChanges) || {};
  const { id, hasChanged } = action.payload;
  if (hasChanged) {
    trackingChanges[id] = true;
  } else {
    trackingChanges = omit(trackingChanges, id);
  }
  return {
    ...state,
    trackingChanges,
  };
};

// Show all the playbook section clauses expanded by default
// when a playbook is loaded the first time
const autoExpandSectionClauses = (state, clauses, playbookId) => {
  if (playbookId !== state.currentPlaybookId) {
    let expandedClauseIds = [];
    (clauses || []).forEach((clause) => {
      if (isSectionClause(clause) && !isEmpty(clause.clauses)) {
        expandedClauseIds.push(clause.id);
      }
    });
    return expandedClauseIds;
  }
  return state.expandedClauseIds;
};

export default handleActions(
  {
    [LOAD_PLAYBOOK]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          trackingChanges: null,
        }),
        success: (s) => {
          const { id: playbookId, clauses } = action.payload.data;
          const expandedClauseIds = autoExpandSectionClauses(
            s,
            clauses,
            playbookId
          );
          return {
            ...s,
            expandedClauseIds,
            currentPlaybookId: playbookId,
          };
        },
      });
    },
    [CREATE_PLAYBOOK_CLAUSE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({ ...s, createClauseStatus: "loading" }),
        failure: (s) => ({
          ...s,
          createClauseStatus: "error",
          showClauseModal: false,
        }),
        success: (s) => {
          return {
            ...s,
            createClauseStatus: "completed",
            showClauseModal: false,
          };
        },
      });
    },
    [SELECT_PLAYBOOK_CLAUSE]: (state, action) => {
      const selectedClauseId = get(action, "payload", null);
      const stateExpandedClauseIds = state.expandedClauseIds || [];
      return {
        ...state,
        trackingChanges: pickBy(state.trackingChanges || {}, (_value, key) =>
          key.startsWith("playbook-")
        ),
        selectedClauseId,
        selectedFeedbackClauseId: selectedClauseId,
        expandedClauseIds: uniq([
          ...stateExpandedClauseIds,
          ...get(action, "meta.relatedClauseIds", []),
        ]),
      };
    },
    [TOGGLE_CLAUSE_MODAL]: (state) => {
      return {
        ...state,
        showClauseModal: !state.showClauseModal,
      };
    },
    [SHOW_FALLBACK_MODAL]: (state, action) => ({
      ...state,
      showFallbackModal: true,
      selectedIssueId: action.payload,
    }),
    [HIDE_FALLBACK_MODAL]: (state) => ({
      ...state,
      showFallbackModal: false,
    }),
    [CREATE_FALLBACK]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({ ...s, createFallbackStatus: "loading" }),
        failure: (s) => ({
          ...s,
          createFallbackStatus: "error",
          showFallbackModal: false,
        }),
        success: (s) => {
          return {
            ...s,
            createFallbackStatus: "completed",
            showFallbackModal: false,
          };
        },
      });
    },
    [TOGGLE_EXPAND_CLAUSE]: (state, action) => {
      const { clauseId, relatedClauseIds } = action.payload;
      const stateExpandedClauseIds = state.expandedClauseIds || [];
      const isExpanded = stateExpandedClauseIds.includes(clauseId);
      // if the clause is expanded, remove its id and all related clause ids (to collapse it and all collapsible descendants)
      const expandedClauseIds = isExpanded
        ? without(stateExpandedClauseIds, clauseId, ...relatedClauseIds)
        : [...stateExpandedClauseIds, clauseId];
      return {
        ...state,
        expandedClauseIds,
      };
    },
    [EXPAND_CLAUSE]: (state, action) => {
      const clauseId = action.payload;
      const stateExpandedClauseIds = state.expandedClauseIds || [];
      return {
        ...state,
        expandedClauseIds: compact(uniq([...stateExpandedClauseIds, clauseId])),
      };
    },
    [DELETE_PLAYBOOK_CLAUSE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          deleteClauseStatus: "loading",
        }),
        failure: (s) => ({ ...s, deleteClauseStatus: "error" }),
        success: (s) => {
          return {
            ...s,
            deleteClauseStatus: "completed",
            clauseIdToDelete: null,
          };
        },
      });
    },
    [PUBLISH_PLAYBOOK]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          publishStatus: "loading",
        }),
        failure: (s) => ({ ...s, publishStatus: "error" }),
        success: (s) => ({
          ...s,
          publishStatus: "completed",
        }),
      }),
    [SHOW_PLAYBOOK_ISSUE_MODAL]: (state) => ({
      ...state,
      showIssueModal: true,
    }),
    [HIDE_PLAYBOOK_ISSUE_MODAL]: (state) => ({
      ...state,
      showIssueModal: false,
    }),
    [CREATE_PLAYBOOK_ISSUE]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          createIssueStatus: "loading",
        }),
        failure: (s) => ({
          ...s,
          createIssueStatus: "error",
          showIssueModal: false,
        }),
        success: (s) => ({
          ...s,
          createIssueStatus: "completed",
          showIssueModal: false,
        }),
      }),
    [TOGGLE_OPTIONAL_CLAUSES]: (state) => ({
      ...state,
      isShowingOptionalClauses: !state.isShowingOptionalClauses,
    }),
    [TRACK_EDITS]: updateTrackingChanges,
    [RESET_PLAYBOOK]: () => ({ ...initialState }),
  },
  initialState
);
