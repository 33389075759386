import { toast, ToastOptions } from "react-toastify";
import {
  ErrorNotification,
  WarningNotification,
  SuccessNotification,
} from "./NotificationTypes";
import classNames from "classnames";

const akordaToastClassName = "akorda-toast";

export const notify = (
  message: string | React.ReactNode,
  toastOptions: any = {}
): void => {
  toast(message, toastOptions);
};

export const notifySuccess = (
  message: string | React.ReactNode,
  toastOptions: ToastOptions = {}
): void => {
  toast(<SuccessNotification>{message}</SuccessNotification>, {
    ...toastOptions,
    className: classNames(akordaToastClassName, "akorda-toast-success"),
  });
};

export const notifyFailure = (
  message: string | React.ReactNode,
  toastOptions: any = {}
): void => {
  notify(<ErrorNotification>{message}</ErrorNotification>, {
    ...toastOptions,
    className: classNames(akordaToastClassName, "akorda-toast-error"),
  });
};

export const notifyWarning = (
  message: string | React.ReactNode,
  toastOptions: any = {}
): void => {
  notify(<WarningNotification>{message}</WarningNotification>, {
    ...toastOptions,
    className: classNames(akordaToastClassName, "akorda-toast-warning"),
  });
};

const notifications = {
  notify,
  notifySuccess,
  notifyFailure,
  notifyWarning,
};

export default notifications;
