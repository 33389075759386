import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { createSelector } from "reselect";
import * as API from "@app/API";
import { filter, get } from "@app/utils/lodash";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { ParsedQuery } from "query-string";
import {
  getCounterpartyFolders,
  getCurrentCounterpartyFolder,
  isRootFolder,
} from "@app/entities/counterparties";
import { NOOP } from "@app/utils/helpers";

//#region TYPES
export const LOAD_COUNTERPARTY = "AKORDA::LOAD_PROJECT";
export const UPDATE_COUNTERPARTY = "AKORDA::UPDATE_PROJECT";
export const DELETE_COUNTERPARTY = "AKORDA::DELETE_PROJECT";
export const RESET_COUNTERPARTY = "AKORDA::RESET_PROJECT";
export const CREATE_COUNTERPARTY_FOLDER = "AKORDA::CREATE_COUNTERPARTY_FOLDER";
export const MOVE_COUNTERPARTY_FOLDER = "AKORDA::MOVE_COUNTERPARTY_FOLDER";
export const REMOVE_COUNTERPARTY_FOLDER = "AKORDA::REMOVE_COUNTERPARTY_FOLDER";
export const UPDATE_COUNTERPARTY_FOLDER_NAME =
  "AKORDA::UPDATE_COUNTERPARTY_FOLDER_NAME";
export const LOAD_COUNTERPARTY_FOLDER = "AKORDA::LOAD_COUNTERPARTY_FOLDER";
export const RELATE_COUNTERPARTY = "AKORDA::RELATE_COUNTERPARTY";

//#endregion

//#region ACTIONS
export const resetCounterparty = () => ({
  type: RESET_COUNTERPARTY,
});

export const loadCounterparty = (
  counterpartyId: string,
  query?: ParsedQuery,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: LOAD_COUNTERPARTY,
  promise: API.loadCounterparty(counterpartyId, query),
  meta: {
    counterpartyId,
    onSuccess,
    onFailure,
  },
});

export const deleteCounterparty = (
  counterpartyId: string,
  onSuccess: () => void,
  onFailure: () => void,
) => ({
  type: DELETE_COUNTERPARTY,
  promise: API.deleteCounterparty(counterpartyId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateCounterparty = (
  counterpartyId: string,
  update: { [key: string]: any },
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: UPDATE_COUNTERPARTY,
  promise: API.updateCounterparty(counterpartyId, update),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const createCounterpartyFolder = (
  counterpartyId: string,
  folderName: string,
  parentFolderId: string,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: CREATE_COUNTERPARTY_FOLDER,
  promise: API.createCounterpartyFolder(
    counterpartyId,
    folderName,
    parentFolderId,
  ),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const loadCounterpartyFolder = (
  counterpartyId: string,
  folderId: string,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: LOAD_COUNTERPARTY_FOLDER,
  promise: API.loadCounterpartyFolder(counterpartyId, folderId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const moveCounterpartyFolder = (
  counterpartyId: string,
  folderId: string,
  newParent: string,
  currentParent: string,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: MOVE_COUNTERPARTY_FOLDER,
  promise: API.moveCounterpartyFolder(
    counterpartyId,
    folderId,
    newParent,
    currentParent,
  ),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeCounterpartyFolder = (
  counterpartyId: string,
  folderId: string,
  parent: string,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: REMOVE_COUNTERPARTY_FOLDER,
  promise: API.removeCounterpartyFolder(counterpartyId, folderId, parent),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const updateCounterpartyFolderName = (
  counterpartyId: string,
  folderId: string,
  folderName: string,
  parent: string,
  onSuccess?: () => void,
  onFailure?: () => void,
) => ({
  type: UPDATE_COUNTERPARTY_FOLDER_NAME,
  promise: API.updateCounterpartyFolderName(
    counterpartyId,
    folderId,
    folderName,
    parent,
  ),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const addRelatedCounterparty = (
  counterpartyId: string,
  relatedCounterpartyId: string,
  onSuccess: (data?: any) => void = NOOP,
  onFailure: () => void = NOOP,
  folderId?: string,
): any => ({
  type: RELATE_COUNTERPARTY,
  promise: API.addRelatedCounterparty(
    counterpartyId,
    [relatedCounterpartyId],
    folderId,
  ),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeRelatedCounterparty =
  (
    counterpartyId: string,
    relatedCounterpartyId: string,
    onSuccess: (data?: any) => void = NOOP,
    onFailure: () => void = NOOP,
    folderId?: string,
  ): any =>
  (dispatch) =>
    dispatch({
      type: RELATE_COUNTERPARTY,
      promise: API.removeRelatedCounterparty(counterpartyId, [
        relatedCounterpartyId,
      ]),
      meta: {
        onSuccess,
        onFailure,
      },
    });
//#endregion

//#region SELECTORS
export const counterpartyState = (state) => state.data.counterparty;

export const getCounterparty = createSelector(counterpartyState, (state) =>
  get(state, "counterparty", {}),
);

export const getCounterpartyStatus = createSelector(
  counterpartyState,
  (state) => state.counterpartyStatus,
);

export const getCounterpartyId = createSelector(
  counterpartyState,
  (state) => state.key,
);

export const getCurrentCounterpartyFolderId = createSelector(
  getCounterparty,
  (counterparty) =>
    get(getCurrentCounterpartyFolder(counterparty), "folderId") || "root",
);

export const isLoadingCounterparty = createSelector(
  getCounterpartyStatus,
  (status) => status === DataStatus.Loading,
);

//#endregion

//#region  HOOKS
export const useCounterparty = (
  counterpartyId: string,
  query?: ParsedQuery,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // If we don't have a valid counterparty ID (e.g., null), we'll reset. This can happen if the useCounterparty hooks is based
    // on a user selection that may be cleared (e.g., assembly form)
    if (!counterpartyId) {
      dispatch(resetCounterparty());
    } else {
      const status = runSelector(getCounterpartyStatus);
      const key = runSelector(getCounterpartyId);
      if (isDataNotLoaded(status) || (!!key && key !== counterpartyId)) {
        dispatch(loadCounterparty(counterpartyId, query));
      }
    }
  }, [counterpartyId, dispatch, query]);

  const counterparty = useSelector(getCounterparty);
  const status = useSelector(getCounterpartyStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);
  const isSubmitting = isDataSubmitting(status);

  const actions = {
    loadCounterparty,
    deleteCounterparty,
    updateCounterparty,
  };

  return [
    counterparty,
    { isLoading, hasError, hasLoaded, isSubmitting },
    actions,
  ];
};

export const useCounterpartyFolder = (folderId: string = "root") => {
  const counterpartyId = useSelector(getCounterpartyId);

  const dispatch = useDispatch();

  const counterparty = useSelector(getCounterparty);
  const folders = useMemo(
    () => getCounterpartyFolders(counterparty),
    [counterparty],
  );
  const currentFolder = useMemo(
    () => getCurrentCounterpartyFolder(counterparty),
    [counterparty],
  );

  useEffect(() => {
    const counterpartyStatus = runSelector(getCounterpartyStatus);
    const key = runSelector(getCurrentCounterpartyFolderId);
    if (
      counterpartyId &&
      !isDataNotLoaded(counterpartyStatus) &&
      !!key &&
      key !== folderId
    ) {
      dispatch(loadCounterpartyFolder(counterpartyId, folderId));
    }
  }, [counterpartyId, dispatch, folderId]);

  const status = useSelector(getCounterpartyStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);
  const isSubmitting = isDataSubmitting(status);

  // Force to use an unique folder name like in a file browser (i.e "my Folder", "my Folder (1)")
  const forceUniqueFolderName = useCallback(
    (folder) => {
      let { folderName } = folder;
      const folderNameRegex = new RegExp(
        `^(${folderName}|${folderName}?(\\s\\([0-9]\\)))$`,
      );
      const count = filter(
        folders,
        (f) => f.folderName.match(folderNameRegex) && f.folderId !== folderId,
      ).length;

      if (count) {
        folderName = `${folderName} (${count})`;
      }
      return folderName;
    },
    [folderId, folders],
  );

  const saveFolder = useCallback(
    (folder, onSuccess, onFailure) => {
      const { folderId: saveFolderId } = folder;
      const folderName = forceUniqueFolderName(folder);

      if (!saveFolderId) {
        const parent = isRootFolder(folderId) ? null : folderId;
        dispatch(
          createCounterpartyFolder(
            counterpartyId,
            folderName,
            parent,
            onSuccess,
            onFailure,
          ),
        );
      } else {
        dispatch(
          updateCounterpartyFolderName(
            counterpartyId,
            saveFolderId,
            folderName,
            folderId,
            onSuccess,
            onFailure,
          ),
        );
      }
    },
    [counterpartyId, dispatch, folderId, forceUniqueFolderName],
  );

  const removeFolder = useCallback(
    (folder, onSuccess, onFailure) => {
      const { folderId, parent } = folder;
      dispatch(
        removeCounterpartyFolder(
          counterpartyId,
          folderId,
          parent,
          onSuccess,
          onFailure,
        ),
      );
    },
    [counterpartyId, dispatch],
  );

  const moveFolder = useCallback(
    (folderId, parent, currentParent, onSuccess, onFailure) => {
      parent = isRootFolder(parent) ? null : parent;
      dispatch(
        moveCounterpartyFolder(
          counterpartyId,
          folderId,
          parent,
          currentParent,
          onSuccess,
          onFailure,
        ),
      );
    },
    [counterpartyId, dispatch],
  );

  const actions = {
    saveFolder,
    removeFolder,
    moveFolder,
  };

  return [
    currentFolder,
    { isLoading, hasError, hasLoaded, isSubmitting },
    actions,
  ];
};
//#endregion

///#region REDUCER

export interface CounterpartyState {
  key: number;
  counterparty: any;
  counterpartyStatus: string;
}

export const initialState: CounterpartyState = {
  // id of the loaded counterparty
  key: null,
  // counterparty information
  counterparty: null,
  // status of the counterparty load: loading, error, completed
  counterpartyStatus: DataStatus.NotLoaded,
};

const handleUpdate = (state, action) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      counterpartyStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      counterpartyStatus: DataStatus.Error,
    }),
    success: (s) => ({
      ...s,
      counterpartyStatus: DataStatus.Done,
      counterparty: { ...s.counterparty, ...get(action, "payload.data", null) },
    }),
  });
};

const handleUpdateFolder = (state: CounterpartyState, action: any) => {
  return handle(state, action, {
    start: (s: CounterpartyState) => ({
      ...s,
      counterpartyStatus: DataStatus.Loading,
    }),
    failure: (s: CounterpartyState) => ({
      ...s,
      counterpartyStatus: DataStatus.Error,
    }),
    success: (s: CounterpartyState) => ({
      ...s,
      counterpartyStatus: DataStatus.Done,
      counterparty: {
        ...s.counterparty,
        currentFolder: get(action, "payload.data.currentFolder", null),
      },
    }),
  });
};

export default handleActions(
  {
    [LOAD_COUNTERPARTY]: (state: CounterpartyState, action: any) => {
      return handle(state, action, {
        start: (s: CounterpartyState) => ({
          ...s,
          counterpartyStatus: DataStatus.Loading,
        }),
        failure: (s: CounterpartyState) => ({
          ...s,
          counterpartyStatus: DataStatus.Error,
        }),
        success: (s: CounterpartyState) => ({
          ...s,
          key: action.meta.counterpartyId,
          counterpartyStatus: DataStatus.Done,
          counterparty: get(action, "payload.data", null),
        }),
      });
    },
    [UPDATE_COUNTERPARTY]: handleUpdate,
    [CREATE_COUNTERPARTY_FOLDER]: handleUpdate,
    [MOVE_COUNTERPARTY_FOLDER]: handleUpdate,
    [REMOVE_COUNTERPARTY_FOLDER]: handleUpdate,
    [UPDATE_COUNTERPARTY_FOLDER_NAME]: handleUpdate,
    [RELATE_COUNTERPARTY]: handleUpdate,
    [LOAD_COUNTERPARTY_FOLDER]: handleUpdateFolder,
    [RESET_COUNTERPARTY]: () => ({ ...initialState }),
  },
  initialState,
);
//#endregion
