import { createSelector } from "reselect";
import get from "lodash/get";
import constants from "@app/utils/constants";
import {
  getPendingApprovals,
  getProcessedApprovals,
} from "@app/redux/data/review/approvals";
import { find } from "@app/utils/lodash";
const { APPROVALS_TABS } = constants;

const approvalSummaryState = (state) =>
  get(state, "approvals.approvalSummary", null);

export const isProcessingIssue = createSelector(
  approvalSummaryState,
  (state) => !!state.processingAction
);

export const getProcessingAction = createSelector(
  approvalSummaryState,
  (state) => state.processingAction
);

export const getSelectedClauseId = createSelector(
  approvalSummaryState,
  (summary) => get(summary, "selectedClauseId", null)
);

export const getProcessedDocumentApprovals = createSelector(
  getProcessedApprovals,
  (processed) => get(processed[0], "approvals") || []
);

export const getOpenDocumentApprovals = createSelector(
  getPendingApprovals,
  (pending) => get(pending[0], "approvals") || []
);

export const getDocumentApprovals = createSelector(
  getOpenDocumentApprovals,
  getProcessedDocumentApprovals,
  (pending, processed) => [...pending, ...processed]
);

export const getSelectedClause = createSelector(
  getSelectedClauseId,
  getDocumentApprovals,
  (clauseId, clauses) => find(clauses, (c) => c.issueId === clauseId)
);

export const getApprovalStats = createSelector(
  getOpenDocumentApprovals,
  getProcessedDocumentApprovals,
  (openApprovals, processedApprovals) => {
    return {
      [APPROVALS_TABS.AWAITING_APPROVAL]: {
        count: openApprovals.length,
        emphasizeCount: openApprovals.length > 0,
      },
      [APPROVALS_TABS.PROCESSED]: {
        count: processedApprovals.length,
        emphasizeCount: false,
      },
      total: openApprovals.length + processedApprovals.length,
    };
  }
);

export const getActiveCardTabId = createSelector(
  approvalSummaryState,
  getApprovalStats,
  (state, stats) => {
    if (state.activeCardTabId) {
      // if state has a value for the tab id, use it
      return state.activeCardTabId;
    } else {
      // otherwise, defer to approvals unless approvals is 0
      return get(stats, `${APPROVALS_TABS.AWAITING_APPROVAL}.count`) === 0
        ? APPROVALS_TABS.PROCESSED
        : APPROVALS_TABS.AWAITING_APPROVAL;
    }
  }
);
