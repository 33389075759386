export const UPDATE_APPROVAL_SUMMARY_ACTIVE_CARD_TAB_ID =
  "APPROVAL-CENTER::UPDATE_APPROVAL_SUMMARY_ACTIVE_CARD_TAB_ID";
export const SELECT_CLAUSE = "APPROVAL-CENTER::SELECT_CLAUSE";
export const TOGGLE_SELECT_CLAUSE = "APPROVAL-CENTER::TOGGLE_SELECT_CLAUSE";
export const DESELECT_CLAUSE = "APPROVAL-CENTER::DESELECT_CLAUSE";
export const RESET_SUMMARY_STATE = "APPROVAL-CENTER::RESET_SUMMARY_STATE";
export const SHOW_DENIAL_MODAL = "APPROVAL-CENTER::SHOW_DENIAL_MODAL";
export const HIDE_APPROVAL_MODAL = "APPROVAL-CENTER::HIDE_APPROVAL_MODAL";
export const CANCEL_SEND_APPROVALS = "APPROVAL-CENTER::CANCEL_SEND_APPROVALS";
export const ENQUEUE_APPROVAL = "APPROVAL-CENTER::ENQUEUE_APPROVAL";
export const SET_APPROVAL_QUEUE = "APPROVAL-CENTER::SET_APPROVAL_QUEUE";

/* EXTERNAL APPROVALS */
export const EXTERNAL_APPROVALS_TOGGLE_SHOW_FULL_DOCUMENT =
  "EXTERNAL-APPROVALS::EXTERNAL_APPROVALS_TOGGLE_SHOW_FULL_DOCUMENT";
export const EXTERNAL_APPROVALS_SUBMIT_DECISION =
  "EXTERNAL-APPROVALS::EXTERNAL_APPROVALS_SUBMIT_DECISION";
