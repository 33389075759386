import { Button, ButtonProps } from "reactstrap";
import classNames from "classnames";
import s from "./styles.module.scss";
import SvgSpinner from "../svgSpinner";

export interface BaseButtonProps extends ButtonProps {
  id?: string;
  isProcessing?: boolean;
  size?: string;
  color?: string;
  width?: string;
  block?: boolean;
  onClick?: (e: any) => void;
  dataElmId?: string;
  disabled?: boolean;
  outline?: boolean;
  title?: string;
}

const BaseButton = (props: BaseButtonProps) => {
  const {
    dataElmId,
    color = "primary",
    children,
    size = "lg",
    width,
    className,
    isProcessing = false,
    disabled = false,
    ...rest
  } = props;

  return (
    <Button
      data-elm-id={dataElmId}
      data-size={size}
      data-width={width}
      className={classNames(s.root, className, {
        [s.isProcessing]: isProcessing,
      })}
      disabled={!!disabled || !!isProcessing}
      color={color}
      {...rest}
    >
      {children}
      {isProcessing && <SvgSpinner xsmall className={s.buttonSpinner} />}
    </Button>
  );
};

export default BaseButton;
