import { get } from "../lodash";

export function getServiceErrorDescription(
  error: { response: any },
  defaultMessage = ""
): string {
  return get(
    error,
    "response.data.error.error_description",
    get(
      error,
      "response.data.error.title",
      getServiceErrorMessage(error, defaultMessage)
    )
  );
}

export function getServiceErrorMessage(
  error: { response: any },
  defaultMessage = ""
) {
  if (get(error, "response.status") === 500 && !!defaultMessage) {
    return defaultMessage;
  }
  // Some service errors like in ms-profile will have an error message at response.data.error.error.message
  // or response.data.error.message, otherwise we may have the error at response.data.message
  return (
    get(
      error,
      "response.data.error.error.message",
      get(
        error,
        "response.data.error.message",
        get(error, "response.data.message")
      )
    ) || defaultMessage
  );
}
