import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { useEffect } from "react";
import { denormalize, normalize } from "normalizr";
import { integrationListSchema } from "./schemas";

//#region TYPES
export const LOAD_INTEGRATIONS = "ADMINISTRATION::LOAD_INTEGRATIONS";
//#endregion

// #region ACTIONS
export const loadIntegrations = (): IAction => ({
  type: LOAD_INTEGRATIONS,
  promise: API.loadIntegrations(),
});
//#endregion

//#region SELECTORS
export const integrationListState = (state) =>
  state.data.integrations.integrationList;

export const getIntegrations = createSelector(
  integrationListState,
  (state) => get(state, "integrations") || {}
);

export const getIntegrationIds = createSelector(
  integrationListState,
  (state) => state.integrationIds
);

export const getIntegrationList = createSelector(
  getIntegrations,
  getIntegrationIds,
  (integrations, integrationIds) => {
    return denormalize(integrationIds, integrationListSchema, {
      integrations,
    });
  }
);

export const getIntegrationListStatus = createSelector(
  integrationListState,
  (state) => state.integrationStatus
);

export const isIntegrationListLoading = createSelector(
  getIntegrationListStatus,
  (status) => status === DataStatus.Loading
);

export const hasIntegrationListError = createSelector(
  getIntegrationListStatus,
  (status) => status === DataStatus.Error
);

export const hasIntegrationListLoaded = createSelector(
  getIntegrationListStatus,
  (status) => status === DataStatus.Done
);

//#endregion

//#region HOOKS
export const useIntegrations = () => {
  const dispatch = useDispatch();

  const integrationList = useSelector(getIntegrationList);
  const integrationMap = useSelector(getIntegrations);
  const isLoading = useSelector(isIntegrationListLoading);
  const hasError = useSelector(hasIntegrationListError);
  const hasLoaded = useSelector(hasIntegrationListLoaded);

  useEffect(() => {
    const status = runSelector(getIntegrationListStatus);
    if (isDataNotLoaded(status)) {
      dispatch(loadIntegrations());
    }
  }, [dispatch]);

  return [
    integrationList,
    integrationMap,
    {
      isLoading,
      hasError,
      hasLoaded,
    },
  ];
};
//#endregion

//#region REDUCER
export interface IntegrationListReduxState {
  integrationStatus: DataStatus;
  integrations: { [key: number]: any };
  integrationIds: number[];
}

export const initialState: IntegrationListReduxState = {
  integrationStatus: DataStatus.NotLoaded,
  integrations: null,
  integrationIds: [],
};

export default handleActions(
  {
    [LOAD_INTEGRATIONS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          integrationStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          integrationStatus: DataStatus.Error,
        }),
        success: (s) => {
          const { entities, result } = normalize(
            get(action, "payload.data.content", []),
            integrationListSchema
          );
          const { integrations } = entities;
          return {
            ...s,
            integrations,
            integrationIds: result,
            integrationStatus: DataStatus.Done,
          };
        },
      });
    },
  },
  initialState
);
//#endregion
