export const DISPLAY_COUNTS = "INSIGHTS::DISPLAY_COUNTS";
export const DISPLAY_PERCENTAGES = "INSIGHTS::DISPLAY_PERCENTAGES";
export const RESET_CONTRACT = "INSIGHTS::RESET_CONTRACT";
export const RESET_CLAUSE_INFO = "INSIGHTS::RESET_CLAUSE_INFO";
export const TOGGLE_DISPLAY_DIFFS = "INSIGHTS::TOGGLE_DISPLAY_DIFFS";
export const TOGGLE_HIGHLIGHTS = "INSIGHTS::TOGGLE_HIGHLIGHTS";
export const UPLOAD_PLAYBOOK_TEMPLATE = "INSIGHTS::UPLOAD_PLAYBOOK_TEMPLATE";
export const RESET_INSIGHTS_ADMIN = "INSIGHTS::RESET_INSIGHTS_ADMIN";
export const EXECUTE_CLUSTERING = "INSIGHTS::EXECUTE_CLUSTERING";
export const RESET_LIBRARY = "INSIGHTS::RESET_LIBRARY";
