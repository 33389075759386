import { readFile } from "@app/utils/files";
import constants from "../utils/constants";
import { Api, ApiResponse } from "./_base";

/**
 * Create a support request
 * @param description
 * @param name
 * @param email
 */
export async function createSupportRequest(
  description: string,
  name: string,
  email: string,
  files?: File[]
): Promise<ApiResponse<any>> {
  const data: any = {
    description,
    name,
    email,
  };
  let uploadFiles = [];
  for (let file of files || []) {
    const fileInfo = await readFile(file);
    uploadFiles.push({
      filename: fileInfo.filename,
      content: fileInfo.bytes,
    });
  }
  data.files = uploadFiles;
  return Api.execute({
    url: `${constants.BASE_URL}/api/support/requests`,
    data,
    method: "POST",
  });
}
