import constants from "../utils/constants";
import { Api } from "./_base";

/**
 * Download public resource
 * @param signedId
 */
export function downloadSharedResource(signedId: string): Promise<any> {
  return Api.execute({
    url: `${constants.BASE_URL}/api/share/${signedId}/download`,
  });
}
