import { ParsedQuery } from "query-string";
import { Api, ApiResponse } from "../_base";
import moment from "moment-timezone";

export * from "./review";

export function loadPlaybookInsights(
  playbookId: string | number,
  query: ParsedQuery = {}
): Promise<ApiResponse<any>> {
  query["filters.tz"] = `${moment().utcOffset()}`;
  return Api.execute({
    url: `/api/insights/${playbookId}/summary`,
    params: query,
  });
}

export function loadClauseInsights(
  playbookId: string | number,
  clauseId: string | number,
  query: ParsedQuery = {}
): Promise<ApiResponse<any>> {
  query["filters.tz"] = `${moment().utcOffset()}`;
  return Api.execute({
    url: `/api/insights/${playbookId}/clauses/${clauseId}/summary`,
    params: query,
  });
}

export function exportClauseExamples(
  playbookId: string | number,
  clauseId: string | number,
  format: string,
  query: ParsedQuery = {}
): Promise<any> {
  query["filters.tz"] = `${moment().utcOffset()}`;
  return Api.execute({
    url: `/api/insights/${playbookId}/clauses/${clauseId}/export/${format}`,
    params: query,
  });
}
