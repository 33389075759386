import { useEffect, useMemo, useState } from "react";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { createSelector } from "reselect";
import * as API from "@app/API";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { get } from "@app/utils/lodash";
import { useDispatch, useSelector } from "react-redux";
import { NOOP } from "@app/utils/helpers";
import { Ticket } from "@app/entities/tickets/tickets";
import { CHANGE_NEGOTIATION } from "../review";

//#region Action Types
export const LOAD_TICKET_DETAILS = "/ticket/details/load";
export const UPDATE_TICKET = "/ticket/update";
export const RESET_TICKET_DETAILS = "/ticket/details/reset";
export const REMOVE_ATTACHMENT = "/ticket/remove-attachment";
export const FOLLOW_TICKET = "/ticket/follow-ticket";
export const UNFOLLOW_TICKET = "/ticket/unfollow-ticket";
export const ADD_RELATED_DOCUMENT = "/ticket/add-related-document";
export const REMOVE_RELATED_DOCUMENT = "/ticket/remove-related-document";
export const ADD_RELATED_TICKET = "/ticket/add-related-ticket";
export const REMOVE_RELATED_TICKET = "/ticket/remove-related-ticket";
export const REMOVE_TICKET = "/ticket/remove-ticket";
export const REFRESH_TICKET = "/ticket/details/refresh-ticket";

//#endregion

//#region Actions
export const loadTicketDetails = (ticketId: string) => ({
  type: LOAD_TICKET_DETAILS,
  promise: API.loadTicket(ticketId),
});

export const updateTicket = (
  ticketId: string,
  data: Partial<Ticket>,
  onSuccess = NOOP,
  onFailure = NOOP,
  actionType?: string
) => ({
  type: actionType || UPDATE_TICKET,
  promise: API.updateTicket(ticketId, data),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const assignTicket = (
  ticketId: string,
  assignee: string | null,
  message: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_TICKET,
  promise: API.assignTicket(ticketId, assignee, message),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeAttachment = (
  ticketId: string,
  removeAttachmentIds: string[],
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: REMOVE_ATTACHMENT,
  promise: API.removeAttachment(ticketId, removeAttachmentIds),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const followTicket = (
  ticketId: string,
  userId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: FOLLOW_TICKET,
  promise: API.followTicket(ticketId, userId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const unfollowTicket = (
  ticketId: string,
  userId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UNFOLLOW_TICKET,
  promise: API.unfollowTicket(ticketId, userId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const addRelatedDocument = (
  ticketId: string,
  relatedDocumentId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: ADD_RELATED_DOCUMENT,
  promise: API.addRelatedDocumentToTicket(ticketId, relatedDocumentId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeRelatedDocument = (
  ticketId: string,
  relatedDocumentId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: REMOVE_RELATED_DOCUMENT,
  promise: API.removeRelatedDocumentFromTicket(ticketId, relatedDocumentId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const addRelatedTicket = (
  ticketId: string,
  relatedTicketId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: ADD_RELATED_TICKET,
  promise: API.addRelatedTicket(ticketId, relatedTicketId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeChildTicket = (
  ticketId: string,
  relatedTicketId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: REMOVE_RELATED_TICKET,
  promise: API.removeRelatedTicket(ticketId, relatedTicketId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const removeTicket = (
  ticketId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: REMOVE_TICKET,
  promise: API.removeTicket(ticketId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const refreshTicket = (ticket: Ticket) => ({
  type: REFRESH_TICKET,
  payload: ticket,
});

export const resetTicketDetails = () => ({
  type: RESET_TICKET_DETAILS,
});
//#endregion

//#region Selectors
export const ticketDetailsState = (state) => state.data.ticket.ticketDetails;

export const getTicket = createSelector(
  ticketDetailsState,
  (state) => state.ticket
);

export const getTicketStatus = createSelector(
  ticketDetailsState,
  (state) => state.ticketStatus
);

export const isTicketLoading = createSelector(getTicketStatus, isDataLoading);

export const isTicketSubmitting = createSelector(
  getTicketStatus,
  isDataSubmitting
);

export const hasTicketError = createSelector(getTicketStatus, hasDataError);

export const hasTicketLoaded = createSelector(getTicketStatus, hasDataLoaded);

//#endregion

//#region Hooks
export const useTicket = (ticketId: string) => {
  const dispatch = useDispatch();
  const [id, setId] = useState<string>("");

  useEffect(() => {
    const status = runSelector(getTicketStatus);
    if (isDataNotLoaded(status) || id !== ticketId) {
      setId(ticketId);
      dispatch(loadTicketDetails(ticketId));
    }
  }, [ticketId, dispatch, id]);

  const ticket = useSelector(getTicket);
  const isLoading = useSelector(isTicketLoading);
  const isSubmitting = useSelector(isTicketSubmitting);
  const hasError = useSelector(hasTicketError);
  const hasLoaded = useSelector(hasTicketLoaded);

  const actions = useMemo(
    () => ({
      loadTicketDetails: () => dispatch(loadTicketDetails(id)),
      resetTicketState: () => dispatch(resetTicketDetails()),
      updateTicket: (update: Record<string, any>, onSuccess, onFailure) =>
        dispatch(updateTicket(id, update, onSuccess, onFailure)),
      assignTicket: (ticketId, assignee, onSuccess, onFailure) =>
        dispatch(assignTicket(ticketId, assignee, onSuccess, onFailure)),
    }),
    [dispatch, id]
  );

  return useMemo(
    () => [ticket, { isLoading, isSubmitting, hasError, hasLoaded }, actions],
    [ticket, actions, isLoading, isSubmitting, hasError, hasLoaded]
  );
};

//#region REDUCER
export interface TicketDetails {
  ticket: Ticket;
  ticketStatus: DataStatus;
}

export const initialState: TicketDetails = {
  ticket: null,
  ticketStatus: DataStatus.NotLoaded,
};

//#endregion

const handleRefreshTicket = (state: TicketDetails, action) => ({
  ...state,
  ticketStatus: DataStatus.Done,
  ticket: action.payload,
});

const handleUpdateTicket = (state: TicketDetails, action: any) => {
  return handle(state, action, {
    start: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Submitting,
    }),
    failure: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Done,
    }),
    success: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Done,
      ticket: get(action, "payload.data"),
    }),
  });
};

const handleSubmittingTicket = (state: TicketDetails, action: any) => {
  return handle(state, action, {
    start: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Submitting,
    }),
    failure: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Done,
    }),
    success: (s: TicketDetails): TicketDetails => ({
      ...s,
      ticketStatus: DataStatus.Done,
    }),
  });
};

export default handleActions(
  {
    [LOAD_TICKET_DETAILS]: (state: TicketDetails, action: any) => {
      return handle(state, action, {
        start: (s: TicketDetails): TicketDetails => ({
          ...s,
          ticketStatus: DataStatus.Loading,
        }),
        failure: (s: TicketDetails): TicketDetails => ({
          ...s,
          ticketStatus: DataStatus.Error,
        }),
        success: (s: TicketDetails): TicketDetails => ({
          ...s,
          ticketStatus: DataStatus.Done,
          ticket: get(action, "payload.data") || null,
        }),
      });
    },
    [UPDATE_TICKET]: handleUpdateTicket,
    [CHANGE_NEGOTIATION]: handleSubmittingTicket,
    [REMOVE_TICKET]: handleSubmittingTicket,
    [REMOVE_ATTACHMENT]: handleUpdateTicket,
    [FOLLOW_TICKET]: handleUpdateTicket,
    [UNFOLLOW_TICKET]: handleUpdateTicket,
    [ADD_RELATED_DOCUMENT]: handleUpdateTicket,
    [REMOVE_RELATED_DOCUMENT]: handleUpdateTicket,
    [ADD_RELATED_TICKET]: handleUpdateTicket,
    [REMOVE_RELATED_TICKET]: handleUpdateTicket,
    [REFRESH_TICKET]: handleRefreshTicket,
    [RESET_TICKET_DETAILS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
