import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
} from "@app/redux/utils";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { get } from "@app/utils/lodash";
import * as API from "@app/API";

import { createSelector } from "reselect";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParsedQuery } from "query-string";

//#region TYPES
const LOAD_PLAYBOOK_INSIGHTS = "insights/playbook/load";
const RESET_PLAYBOOK_INSIGHTS = "insights/playbook/reset";
//#endregion

//#region ACTIONS
export const loadPlaybookInsights = (
  playbookId: string | number,
  query?: ParsedQuery
) => {
  return {
    type: LOAD_PLAYBOOK_INSIGHTS,
    promise: API.loadPlaybookInsights(playbookId, query),
  };
};

export const resetPlaybookInsights = () => ({
  type: RESET_PLAYBOOK_INSIGHTS,
});
//#endregion

//#region SELECTORS
export const getPlaybookInsightsState = (state) => state.data.insights.playbook;

export const getPlaybookInsights = createSelector(
  getPlaybookInsightsState,
  (state) => state.playbookInsights || {}
);

export const getPlaybookInsightsStatus = createSelector(
  getPlaybookInsightsState,
  (state) => state.playbookInsightsStatus
);

export const hasPlaybookInsightsError = createSelector(
  getPlaybookInsightsStatus,
  (status) => status === DataStatus.Error
);

export const isLoadingPlaybookInsights = createSelector(
  getPlaybookInsightsStatus,
  (status) => status === DataStatus.Loading
);

const getResourceKey = createSelector(
  getPlaybookInsightsState,
  (state) => state.key
);
//#endregion

//#region Hooks
export const usePlaybookInsights = (playbookId: number | string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status = runSelector(getPlaybookInsightsStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || hasDataError(status) || key !== playbookId) {
      dispatch(loadPlaybookInsights(playbookId));
    }
  }, [playbookId, dispatch]);

  const insights = useSelector(getPlaybookInsights);
  const status = useSelector(getPlaybookInsightsStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);

  return [insights, { isLoading, hasError, hasLoaded }];
};
//#endregion

export interface PlaybookInsightsState {
  playbookInsights: any[];
  playbookInsightsStatus: DataStatus;
  key: string;
}

export const initialState: PlaybookInsightsState = {
  playbookInsights: [],
  playbookInsightsStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [LOAD_PLAYBOOK_INSIGHTS]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookInsightsState): PlaybookInsightsState => ({
          ...s,
          playbookInsightsStatus: DataStatus.Loading,
        }),
        failure: (s: PlaybookInsightsState): PlaybookInsightsState => ({
          ...s,
          playbookInsightsStatus: DataStatus.Error,
        }),
        success: (s: PlaybookInsightsState): PlaybookInsightsState => {
          const playbookInsights = get(action, "payload.data");
          return {
            ...s,
            playbookInsights,
            playbookInsightsStatus: DataStatus.Done,
          };
        },
      });
    },
    [RESET_PLAYBOOK_INSIGHTS]: () => ({
      ...initialState,
    }),
  },
  initialState
);
