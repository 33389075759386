import { handleActions } from "redux-actions";
import {
  DISPLAY_COUNTS,
  DISPLAY_PERCENTAGES,
  TOGGLE_HIGHLIGHTS,
  RESET_CONTRACT,
} from "../actions/types";

export interface InsightClauseListReduxState {
  displayCounts: boolean;
  highlightFrictions: boolean;
}

export const initialState: InsightClauseListReduxState = {
  // whether to show analytics data as counts instead of percentages
  displayCounts: false,
  // whether the rows with frictions are highlighted
  highlightFrictions: true,
};

export default handleActions(
  {
    [DISPLAY_COUNTS]: (state) => ({
      ...state,
      displayCounts: true,
    }),
    [DISPLAY_PERCENTAGES]: (state) => ({
      ...state,
      displayCounts: false,
    }),
    [TOGGLE_HIGHLIGHTS]: (s) => ({
      ...s,
      highlightFrictions: !s.highlightFrictions,
    }),
    [RESET_CONTRACT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
