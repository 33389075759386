export enum Role {
  AkordaAdmin = "ADMIN",
  CompanyAdmin = "COMPANY_ADMIN",
  Sales = "SALES",
  SalesManager = "SALES_MANAGEMENT",
  Legal = "LEGAL",
  LegalManager = "LEGAL_MANAGEMENT",
  Insights = "LEGAL_INSIGHTS",
  Trial = "TRIAL_COMPANY_ADMIN",
  Revenue = "REVENUE",
  Signer = "SIGNER",
  Business = "BUSINESS",
}

const Roles = {
  ADMIN: Role.AkordaAdmin as const,
  COMPANY_ADMIN: Role.CompanyAdmin as const,
  SALES: Role.Sales as const,
  SALES_MANAGEMENT: Role.SalesManager as const,
  LEGAL: Role.Legal as const,
  LEGAL_MANAGEMENT: Role.LegalManager as const,
  LEGAL_INSIGHTS: Role.Insights as const,
  TRIAL_COMPANY_ADMIN: Role.Trial as const,
  REVENUE: Role.Revenue as const,
  SIGNER: Role.Signer as const,
  BUSINESS: Role.Business as const,
};

export default Roles;
