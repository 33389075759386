import { Comment, CommentReference } from "@app/entities/comments";
import { Api, ApiResponse } from "./_base";

export const loadComments = (
  type: string,
  entityId: string | number
): Promise<ApiResponse<Comment[]>> => {
  return Api.execute({
    url: `/api/comments/${type}/${entityId}`,
  });
};

export const createComment = (
  type: string,
  entityId: string | number,
  text: string,
  reference: CommentReference
) => {
  return Api.execute({
    url: `/api/comments/${type}/${entityId}`,
    method: "POST",
    data: {
      reference,
      text,
    },
  });
};

export const updateComment = (
  commentId: string | number,
  text: string,
  reference: CommentReference
): Promise<ApiResponse<Comment>> => {
  return Api.execute({
    url: `/api/comments/messages/${commentId}`,
    method: "PATCH",
    data: {
      text,
      reference,
    },
  });
};

export const deleteComment = (
  commentId: number | string
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/comments/messages/${commentId}`,
    method: "DELETE",
  });
};
