import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
} from "@app/redux/utils";
import IAction from "@app/types/IAction";
import { useDispatch, useSelector } from "react-redux";
import constants from "@app/utils/constants";
import { useEffect } from "react";

//#region ACTION TYPES
export const LOAD_DOCUMENT_VERSIONS = "review/document-versions/load";
export const RESET_DOCUMENT_VERSIONS = "review/document-versions/reset";
//#endregion

// #region ACTIONS

export const loadDocumentVersions = (documentId: string | number): IAction => {
  return {
    type: LOAD_DOCUMENT_VERSIONS,
    promise: API.getDocumentVersions(documentId),
    meta: {
      documentId,
    },
  };
};

export const resetDocumentVersions = (): IAction => ({
  type: RESET_DOCUMENT_VERSIONS,
});
// #endregion

// #region SELECTORS
export const documentVersionsState = (state) =>
  state.data.review.documentVersions;

export const getDocumentVersionsStatus = createSelector(
  documentVersionsState,
  (state) => state.versionsStatus
);

export const isDocumentVersionsLoading = createSelector(
  getDocumentVersionsStatus,
  (status) => isDataLoading(status)
);

export const hasDocumentVersionsError = createSelector(
  getDocumentVersionsStatus,
  (status) => hasDataError(status)
);

export const hasDocumentVersionsLoaded = createSelector(
  getDocumentVersionsStatus,
  (status) => hasDataLoaded(status)
);

// Returns the original/raw list of versions for a document
const getVersionsRaw = createSelector(
  documentVersionsState,
  (state) => state.versions
);

// Returns the list of versions filtered to remove failed/deleted versions
export const getVersions = createSelector(getVersionsRaw, (versions) => {
  const { FAILED, DELETED } = constants.PROCCESS_STATUS_MAP;
  return versions.filter(
    (version) => ![FAILED, DELETED].includes(version.status)
  );
});

const getResourceKey = createSelector(
  documentVersionsState,
  (state) => state.key || null
);

// #endregion

// #region HOOKS
export const useDocumentVersions = (documentId: string) => {
  const dispatch = useDispatch();

  const versions = useSelector(getVersions);
  const isLoading = useSelector(isDocumentVersionsLoading);
  const hasError = useSelector(hasDocumentVersionsError);
  const hasLoaded = useSelector(hasDocumentVersionsLoaded);

  useEffect(() => {
    const status = runSelector(getDocumentVersionsStatus);
    const key = runSelector(getResourceKey);
    if (
      !!documentId &&
      (isDataNotLoaded(status) || (!!key && key !== documentId))
    ) {
      dispatch(loadDocumentVersions(documentId));
    }
  }, [dispatch, documentId]);

  return [versions, { isLoading, hasError, hasLoaded }];
};
// #endregion

//#region REDUCER
export interface DocumentVersionsReduxState {
  versions: any[];
  versionsStatus: string;
  key: string;
}

export const initialState: DocumentVersionsReduxState = {
  versions: [],
  versionsStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [LOAD_DOCUMENT_VERSIONS]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          versionsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({ ...s, versionsStatus: DataStatus.Error }),
        success: (s) => {
          return {
            ...s,
            versionsStatus: DataStatus.Done,
            versions: get(action, "payload.data", []).reverse(),
            key: get(action, "meta.documentId"),
          };
        },
      });
    },
    [RESET_DOCUMENT_VERSIONS]: () => ({ ...initialState }),
  },
  initialState
);

//#endregion
