import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import actionTypes from "../actionTypes";
import commonActionTypes from "../../_Home/actionTypes";
import { UserContext } from "../../types-business/Profile";
import constants from "../../utils/constants";
import get from "lodash/get";
import { UPDATE_USER } from "@app/_Administration/actions/types";

const { DEFAULT_LOGIN_URL } = constants;

interface State {
  userContext?: UserContext;
  userContextStatus: string;
  loginUrl?: string;
}

export const initialState: State = {
  userContext: null,
  userContextStatus: null,
  loginUrl: null, // always start as null to trigger federated loginUrl fetching,
};

const handleTrialUser = (userContext: UserContext) => {
  // Trial users are technically company admins, but we need to treat them differently.
  // Here we adjust the user role from the BE by prefixing it with `TRIAL_`, which allows us to provide different permissions
  const isTrialUser = get(userContext, "company.trial", false);
  if (isTrialUser) {
    userContext.groups = ["TRIAL_COMPANY_ADMIN"];
  }
  return userContext;
};

export default handleActions(
  {
    [actionTypes.SET_USER_CONTEXT]: (state: any, action) => {
      return {
        ...state,
        userContext: handleTrialUser(action.payload),
        userContextStatus: "completed",
      };
    },
    [actionTypes.REFRESH_MY_PROFILE]: (state, action) =>
      handle(state, action, {
        success: (s) => {
          return {
            ...s,
            userContext: handleTrialUser(action.payload.data),
          };
        },
      }),
    [actionTypes.LOAD_MY_PROFILE]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          userContextStatus: "loading",
          userContext: null,
        }),
        failure: (s) => ({
          ...s,
          userContextStatus: "error",
        }),
        success: (s) => {
          return {
            ...s,
            userContext: handleTrialUser(action.payload.data),
            userContextStatus: "completed",
          };
        },
      }),
    [UPDATE_USER]: (state, action) =>
      handle(state, action, {
        success: (s) => {
          const state = { ...s };
          const updatedUser = get(action, "payload.data");
          // if the current user is not the one updated, do nothing (keep current state)
          if (updatedUser.userId !== s.userContext.userId) return state;
          // otherwise, the current user has been changed, so update the user context
          // for the current user.
          state.userContext = handleTrialUser(action.payload.data);
          return state;
        },
      }),
    [actionTypes.FETCH_LOGIN_URL]: (state, action) => ({
      ...state,
      loginUrl: get(action, "payload", DEFAULT_LOGIN_URL),
    }),
    [commonActionTypes.RESET_APP]: () => ({ ...initialState }),
  },
  initialState
);
