import { schema } from "normalizr";
export const ticketSchema = new schema.Entity(
  "tickets",
  {},
  {
    idAttribute: "key",
  }
);
export const ticketListSchema = [ticketSchema];

export const ticketTypeSchema = new schema.Entity("ticketTypes", {});
export const ticketTypeListSchema = [ticketTypeSchema];
