import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import get from "lodash/get";
import {
  LOAD_COMPANY_PROFILE,
  RESET_ADMINISTRATION_STATE,
  UPDATE_COMPANY_PROFILE,
} from "../actions/types";
import { Company } from "@app/entities/companies";

export interface State {
  // profile for a company
  company: Company;
  // status of the api fetch call for a company profile (error, loading, completed)
  companyStatus: string;
}

export const initialState: State = {
  company: null,
  companyStatus: null,
};

export default handleActions(
  {
    // reverts the state back to the initial state
    [RESET_ADMINISTRATION_STATE]: () => ({
      ...initialState,
    }),
    [LOAD_COMPANY_PROFILE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          companyStatus: "loading",
        }),
        success: (s) => ({
          ...s,
          company: get(action, "payload.data", null),
          companyStatus: "completed",
        }),
        failure: (s) => ({
          ...s,
          companyStatus: "error",
        }),
      });
    },
    [UPDATE_COMPANY_PROFILE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          companyStatus: "updating",
        }),
        success: (s) => ({
          ...s,
          company: get(action, "meta.company", s.company),
          companyStatus: "completed",
        }),
        failure: (s) => ({
          ...s,
          companyStatus: "update-error",
        }),
      });
    },
  },
  initialState
);
