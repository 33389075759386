import { DocumentService } from "@app/types-business/documents";
import { Api, ApiResponse } from "../_base";
import queryString from "query-string";
import moment from "moment-timezone";
import { omit } from "@app/utils/lodash";
import { EnvelopeLinkType, NegotiationAction } from "@app/entities/document";

export enum ProcessIssueAction {
  "AcceptAll" = "ACCEPT",
  "AcceptPartial" = "PARTIAL_ACCEPT",
  "RejectAll" = "REJECT",
  "RejectPartial" = "PARTIAL_REJECT",
  "Revise" = "REVISE",
  "Done" = "DONE",
  "Finalize" = "FINALIZE",
  "Negotiate" = "NEGOTIATE",
  "Open" = "OPEN",
}

export type ProcessIssue = {
  action: ProcessIssueAction;
  html?: string;
  issueId: string;
  versionControl: string;
};

export type Approver = {
  name: string;
  email: string;
};

export type ApprovalRequest = {
  name: string;
  email: string;
  approvals: {
    issueId: string;
    text: string;
    html: string;
    comment?: string;
  }[];
};

export type CancelApprovalRequest = {
  issueId: string;
  cancel: {
    comment?: string;
  };
};

export type ApproveRequest = {
  issueId: string;
  approve: {
    comment?: string;
    text?: string;
    html?: string;
  };
  companyId?: string; // for external users
};

export type DenyRequest = {
  issueId: string;
  deny: {
    comment?: string;
  };
  companyId?: string; // for external users
};

export type NegotiationActionRequest = {
  action: NegotiationAction;
  data?: any;
};

export const processClauseIssue = (
  documentId: string,
  data: ProcessIssue
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/process-issue`,
    method: "POST",
    data,
  });
};

export const getReviewDocument = (
  documentId: string,
  params?: {
    versionNumber?: number;
    extensions?: string;
    initialState?: boolean;
  }
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}`,
    params,
  });
};

export const getReviewDocumentHtml = (
  documentId: string,
  params?: {
    versionNumber?: number;
    initialState?: boolean;
  }
): Promise<ApiResponse<string>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/html`,
    params,
  });
};

export const getReviewDocumentList = (query: Record<string, any> = {}) => {
  query["filters.tz"] = moment().utcOffset();
  return Api.execute({
    url: `/api/review/documents?${queryString.stringify(query)}`,
  });
};

export const getMyReviewDocumentList = (query: Record<string, any> = {}) => {
  query["filters.assignee"] = "true";
  return getReviewDocumentList(query);
};

export const requestApproval = (
  documentId: string,
  approvalRequests: ApprovalRequest[]
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/request-approval`,
    data: {
      approvalRequests,
    },
    method: "POST",
  });
};

export const cancelApproval = (
  documentId: string,
  request: CancelApprovalRequest
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/cancel-approval`,
    data: request,
    method: "POST",
  });
};

export const approve = (
  documentId: string,
  request: ApproveRequest
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/process-approval${
      request.companyId ? `/external?companyId=${request.companyId}` : ""
    }`,
    data: omit(request, "companyId"),
    method: "POST",
  });
};

export const deny = (
  documentId: string,
  request: DenyRequest
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/process-approval${
      request.companyId ? `/external?companyId=${request.companyId}` : ""
    }`,
    data: omit(request, "companyId"),
    method: "POST",
  });
};

export const finalizeDocument = (
  documentId: string,
  data: any
): Promise<ApiResponse<DocumentService.ReviewDocument>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/finalize`,
    data,
    method: "POST",
  });
};

export function changeNegotiation(
  documentId: string,
  request: NegotiationActionRequest
): Promise<ApiResponse<DocumentService.Document>> {
  return Api.execute({
    url: `/api/review/documents/${documentId}/change-negotiation`,
    data: request,
    method: "POST",
  });
}

export const getApprovals = (): Promise<
  ApiResponse<DocumentService.ReviewApprovalsResponse>
> => {
  return Api.execute({
    url: `/api/review/documents/approvals`,
  });
};

export const getApprovalsForDocument = (
  documentId: string
): Promise<ApiResponse<DocumentService.ReviewApprovalsResponse>> => {
  return Api.execute({
    url: `/api/review/documents/approvals?documentId=${documentId}`,
  });
};

export const getApprovalsWithToken = (
  token: string
): Promise<ApiResponse<DocumentService.ReviewApprovalsResponse>> => {
  return Api.execute({
    url: `/api/review/documents/approvals/token/${token}`,
  });
};

export const getReviewIssueHistory = (
  documentId: string,
  issueId: string
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/review/documents/${documentId}/issue-history/${issueId}`,
  });
};

export const getEnvelopeURL = (
  envelopeId: string,
  linkType: EnvelopeLinkType = EnvelopeLinkType.Edit,
  returnUrl?: string
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/review/documents/envelopes/${envelopeId}`,
    params: {
      linkType,
      returnUrl,
    },
  });
};
