import { createSelector } from "reselect";
import get from "lodash/get";
import first from "lodash/first";
import constants from "../../utils/constants";
import {
  getApprovalsMetadata,
  getApprovalsStatus,
  getPendingApprovals,
} from "@app/redux/data/review/approvals";
import { getDocumentId } from "@app/entities/document";

export const getExternalApprovalTitle = createSelector(
  getApprovalsMetadata,
  (metadata) => get(metadata, "documentTitle") || " ---------- "
);

export const getExternalApprovalFullDocumentHtml = createSelector(
  getApprovalsMetadata,
  (metadata) => get(metadata, "fullDocumentHtml") || ""
);

export const getExternalApprovalCompanyId = createSelector(
  getApprovalsMetadata,
  (metadata) => get(metadata, "companyId")
);

export const hasExternalApprovalInvalidToken = createSelector(
  getApprovalsStatus,
  (status) => status === constants.ERRORS.INVALID_ACTION_TOKEN
);

export const getExternalApprovalDocumentId = createSelector(
  getPendingApprovals,
  (openRequests) => getDocumentId(openRequests[0])
);

export const getActiveExternalApprovalRequest = createSelector(
  getPendingApprovals,
  (openRequests) => first(get(openRequests[0], "approvals") || [])
);
