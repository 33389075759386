import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { NOOP, resolveCompanyId } from "@app/utils/helpers";
import { find, get, orderBy } from "@app/utils/lodash";
import commonTypes from "@app/_Home/actionTypes";

import { denormalize, normalize } from "normalizr";
import { backupsListSchema } from "./schemas";
import {
  Backup,
  BackupStatus,
  CreateBackupRequest,
} from "@app/entities/backups";
import { IPagination } from "@app/types-business/Project";

//#region TYPES
export const LOAD_BACKUPS = "AKORDA::LOAD_BACKUPS";
export const CREATE_BACKUP = "AKORDA::CREATE_BACKUP";
//#endregion

//#region  ACTIONS
export const loadBackups = (
  companyId: number,
  page: number = 0,
  size: number = 50,
  onSuccess = NOOP,
  onFailure = NOOP,
) => ({
  type: LOAD_BACKUPS,
  promise: API.loadBackups(companyId, page, size),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const createBackup = (
  request?: CreateBackupRequest,
  onSuccess = NOOP,
  onFailure = NOOP,
) => ({
  type: CREATE_BACKUP,
  promise: API.createBackup(request),
  meta: {
    onSuccess,
    onFailure,
  },
});

//#endregion

/* #region  SELECTORS */
export const backupsState = (state) => state.data.backups;

export const getBackups = createSelector(
  backupsState,
  (state) => get(state, "backups") || null,
);

export const getBackupsIds = createSelector(
  backupsState,
  (state) => state.backupsIds,
);

export const getBackupsList = createSelector(
  getBackups,
  getBackupsIds,
  (backups, ids) => {
    return orderBy(
      denormalize(ids, backupsListSchema, {
        backups,
      }),
      ["createdDate"],
      ["desc"],
    );
  },
);

export const getBackupsStatus = createSelector(
  backupsState,
  (state) => state.backupsStatus,
);

export const isBackupsLoading = createSelector(getBackupsStatus, (status) =>
  isDataLoading(status),
);

export const isBackupsSubmitting = createSelector(getBackupsStatus, (status) =>
  isDataSubmitting(status),
);

export const hasBackupsError = createSelector(getBackupsStatus, (status) =>
  hasDataError(status),
);

export const hasBackupsLoaded = createSelector(getBackupsStatus, (status) =>
  hasDataLoaded(status),
);

export const getBackupsPagination = createSelector(
  backupsState,
  (state) => state.paginationInfo,
);

//#endregion

//#region HOOKS
export const useBackups = (
  companyId: number = resolveCompanyId(),
  page: number = 0,
) => {
  const dispatch = useDispatch();

  const backupsMap = useSelector(getBackups);
  const backups = useSelector(getBackupsList);
  const backupStatus = useSelector(getBackupsStatus);

  useEffect(() => {
    const status = runSelector(getBackupsStatus);
    const { page: currentPage } = runSelector(getBackupsPagination);
    if (isDataNotLoaded(status) || page !== currentPage) {
      dispatch(loadBackups(companyId, page));
    }
  }, [companyId, dispatch, page]);

  const actions = useMemo(() => {
    return {
      refresh: () => dispatch(loadBackups(companyId, 0)),
    };
  }, [companyId, dispatch]);

  const isRunning = useMemo(
    () => !!find(backups, { status: BackupStatus.Running }),
    [backups],
  );

  return [backups, backupsMap, backupStatus, actions, isRunning];
};

//#endregion

/* #region  REDUCER  */
export interface BackupsState {
  backups: { [key: string]: Backup };
  backupsIds: string[];
  backupsStatus: DataStatus;
  paginationInfo: IPagination;
}

export const initialState: BackupsState = {
  backups: {},
  backupsIds: [],
  backupsStatus: DataStatus.NotLoaded,
  paginationInfo: {
    page: 0,
    pageSize: 0,
    totalSize: 0,
    totalPages: 0,
  },
};

export default handleActions(
  {
    [LOAD_BACKUPS]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          backupsStatus: DataStatus.Loading,
        }),
        failure: (s) => {
          return {
            ...s,
            backupsStatus: DataStatus.Error,
          };
        },
        success: (s) => {
          const data = get(action, "payload.data") || {
            page: 0,
            totalSize: 0,
            pageSize: 0,
            backups: [],
          };
          const { entities, result } = normalize(
            data.backups,
            backupsListSchema,
          );
          const { backups } = entities;
          const { page, pageSize, totalSize } = data;
          return {
            ...s,
            backupsStatus: DataStatus.Done,
            backupsIds: result,
            backups,
            paginationInfo: {
              page,
              pageSize,
              totalSize,
              totalPages: totalSize ? Math.ceil(totalSize / pageSize) : 0,
            },
          };
        },
      }),
    [commonTypes.RESET_APP]: () => ({ ...initialState }),
  },
  initialState,
);
//#endregion
