import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { isDevelopment } from "./utils/helpers";

const i18next = i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: true,
    fallbackLng: "en",
    debug: isDevelopment,

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },

    ns: ["translations"],
    defaultNS: "translations",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18next;
