import { createBrowserHistory } from "history";

const isClientSide =
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement;

// Custom browser history that we can use in tests and in action creators
const history = isClientSide ? createBrowserHistory() : null;

export default history;
