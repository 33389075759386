import classNames from "classnames";
import { ReactComponent as LegalSifterControlBrand } from "@app/media/img/ls-control-brand-horizontal.svg";
import s from "./styles.module.scss";
import { NOOP } from "@app/utils/helpers";

interface Props {
  className?: string;
  onClick?: (e) => void;
}

function AppLogo({ className, onClick = NOOP }: Props) {
  return (
    <div
      className={classNames("ls-app-logo", s.appLogo, className)}
      onClick={onClick}
    >
      <LegalSifterControlBrand />
    </div>
  );
}

export default AppLogo;
