import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import IAction from "@app/types/IAction";
import * as API from "@app/API";
import { get } from "@app/utils/lodash";
import { DataStatus } from "@app/redux/utils";
import { createSelector } from "@reduxjs/toolkit";
import { IPagination } from "@app/types-business/Project";
import { denormalize, normalize } from "normalizr";
import { documentListSchema } from "./schemas";
import { DocumentService } from "@app/types-business/documents";
import {
  CHANGE_NEGOTIATION,
  FINALIZE_DOCUMENT,
  LOAD_REVIEW_DOCUMENT,
} from "./document";

// #region ACTION TYPES
export const LOAD_REVIEW_DOCUMENTS = "review/documents/load";
export const RESET_REVIEW_DOCUMENTS = "review/documents/reset";
// #endregion

// #region ACTIONS
export const loadReviewDocuments = (
  query: Record<string, any> = {}
): IAction => {
  return {
    type: LOAD_REVIEW_DOCUMENTS,
    promise: API.getReviewDocumentList(query),
  };
};

export const loadMyReviewDocuments = (
  query: Record<string, any> = {}
): IAction => {
  return {
    type: LOAD_REVIEW_DOCUMENTS,
    promise: API.getMyReviewDocumentList(query),
  };
};

export const resetReviewDocuments = (): IAction => ({
  type: RESET_REVIEW_DOCUMENTS,
});
// #endregion

// #region SELECTORS
export const reviewDocumentsState = (state) => state.data.review.documents;

export const getReviewDocumentsMap = createSelector(
  reviewDocumentsState,
  (state) => get(state, "documents") || {}
);

export const getReviewDocumentIds = createSelector(
  reviewDocumentsState,
  (state) => get(state, "documentIds") || []
);

export const getReviewDocuments = createSelector(
  getReviewDocumentsMap,
  getReviewDocumentIds,
  (documents, ids) => {
    return denormalize(ids, documentListSchema, { documents });
  }
);

export const getReviewDocumentsStatus = createSelector(
  reviewDocumentsState,
  (state) => state.status
);

export const areReviewDocumentsLoading = createSelector(
  getReviewDocumentsStatus,
  (status) => status === DataStatus.Loading
);

export const hasReviewDocumentsError = createSelector(
  getReviewDocumentsStatus,
  (status) => status === DataStatus.Error
);

export const hasReviewDocumentsLoaded = createSelector(
  getReviewDocumentsStatus,
  (status) => status === DataStatus.Done
);

export const isReviewDocumentsUpdating = createSelector(
  getReviewDocumentsStatus,
  (status) => status === DataStatus.Submitting
);

export const getPaginationInfo = createSelector(
  reviewDocumentsState,
  (state) => state.paginationInfo
);

export const getDocumentFilters = createSelector(
  reviewDocumentsState,
  (state) => state.filters || []
);
// #endregion

//#region REDUCER
export interface ReviewDocumentsReduxState {
  status: DataStatus;
  documents: Record<string, DocumentService.ReviewDocument>;
  documentIds: string[];
  filters: DocumentService.DocumentFilter[];
  paginationInfo: IPagination;
}

export const initialState: ReviewDocumentsReduxState = {
  status: DataStatus.NotLoaded,
  documents: {},
  documentIds: [],
  filters: [],
  paginationInfo: {
    page: 0,
    totalSize: 0,
    totalPages: 0,
  },
};

export default handleActions(
  {
    [LOAD_REVIEW_DOCUMENTS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          status: DataStatus.Error,
        }),
        success: (s) => {
          const {
            documents,
            filters,
            page,
            pageSize,
            totalSize,
            totalPages,
          }: any = get(action, "payload.data") || {};

          const { entities, result } = normalize(
            documents || [],
            documentListSchema
          );
          return {
            ...s,
            documents: entities.documents,
            documentIds: result,
            filters: filters || [],
            paginationInfo: {
              page,
              pageSize,
              totalSize,
              totalPages,
            },
            status: DataStatus.Done,
          };
        },
      });
    },
    [LOAD_REVIEW_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        success: (s) => {
          const { document }: any = get(action, "payload.data") || {};
          const documentId = document?.documentId;
          return {
            ...s,
            documents: {
              ...s.documents,
              [documentId]: document,
            },
          };
        },
      });
    },
    [FINALIZE_DOCUMENT]: (state, action) => {
      let documents = state.documents || {};
      const currentDocument =
        documents[get(action, "meta.documentId") as string]; // This action can be dispatched in the contract review page but it should be ignored here
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: !!currentDocument ? DataStatus.Submitting : s.status,
        }),
        failure: (s) => ({
          ...s,
          status: !!currentDocument ? DataStatus.Done : s.status,
        }),
        success: (s) => {
          if (!!currentDocument) {
            documents = {
              ...documents,
              [currentDocument.documentId]: get(
                action,
                "payload.data.document"
              ),
            };
          }
          return {
            ...s,
            documents,
            status: !!currentDocument ? DataStatus.Done : s.status,
          };
        },
      });
    },
    [CHANGE_NEGOTIATION]: (state, action) => {
      let documents = state.documents || {};
      const currentDocument =
        documents[get(action, "meta.documentId") as string]; // This action can be dispatched in the contract review page but it should be ignored here
      return handle(state, action, {
        start: (s) => ({
          ...s,
          status: !!currentDocument ? DataStatus.Submitting : s.status,
        }),
        failure: (s) => ({
          ...s,
          status: !!currentDocument ? DataStatus.Done : s.status,
        }),
        success: (s) => {
          if (!!currentDocument) {
            documents = {
              ...documents,
              [currentDocument.documentId]: {
                ...currentDocument,
                ...(get(action, "payload.data") as any), // to not override other properties like extensions
              },
            };
          }
          return {
            ...s,
            documents,
            status: !!currentDocument ? DataStatus.Done : s.status,
          };
        },
      });
    },
    [RESET_REVIEW_DOCUMENTS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
