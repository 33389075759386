import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import {
  DataStatus,
  isDataNotLoaded,
  isDataLoading,
  hasDataError,
  hasDataLoaded,
  runSelector,
} from "@app/redux/utils";
import IAction from "@app/types/IAction";
import { MetadataField } from "@app/entities/metadata";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

//#region ACTION TYPES
export const LOAD_DOCUMENT_METRICS = "review/document-metrics/load";
export const RESET_DOCUMENT_METRICS = "review/document-metrics/reset";
//#endregion

//#region ACTIONS
/**
 * Loads the metrics information related to a document.
 * @param documentId
 */
export const loadDocumentMetrics = (documentId: string): IAction => ({
  type: LOAD_DOCUMENT_METRICS,
  promise: API.getDocumentMetrics(documentId),
  meta: {
    documentId,
  },
});

export const resetDocumentMetrics = (): IAction => ({
  type: RESET_DOCUMENT_METRICS,
});
//#endregion

//#region SELECTORS
export const documentMetricsState: (state: any) => DocumentMetricsReduxState = (
  state
) => state.data.review.documentMetrics;

export const getDocumentMetricsStatus = createSelector(
  documentMetricsState,
  (state) => state.metricsStatus
);

export const isDocumentMetricsLoading = createSelector(
  getDocumentMetricsStatus,
  (status) => isDataLoading(status)
);

export const hasDocumentMetricsError = createSelector(
  getDocumentMetricsStatus,
  (status) => hasDataError(status)
);

export const hasDocumentMetricsLoaded = createSelector(
  getDocumentMetricsStatus,
  (status) => hasDataLoaded(status)
);

export const getDocumentMetrics = createSelector(
  documentMetricsState,
  (state) => state.metrics || {}
);

export const getDocumentMetricsResourceKey = createSelector(
  documentMetricsState,
  (state) => state.key
);
//#endregion

//#region HOOKS
export const useDocumentMetrics = (
  documentId: string,
  query: Record<string, any> = {}
): any[] => {
  const dispatch = useDispatch();

  const metrics = useSelector(getDocumentMetrics);
  const isLoading = useSelector(isDocumentMetricsLoading);
  const hasError = useSelector(hasDocumentMetricsError);
  const hasLoaded = useSelector(hasDocumentMetricsLoaded);

  useEffect(() => {
    const status = runSelector(getDocumentMetricsStatus);
    const key = runSelector(getDocumentMetricsResourceKey);

    if (
      (!!documentId && isDataNotLoaded(status)) ||
      (!!key && key !== documentId)
    ) {
      dispatch(loadDocumentMetrics(documentId));
    }
  }, [dispatch, documentId, query]);

  return [metrics, { isLoading, hasError, hasLoaded }];
};
//#endregion

//#region REDUCER
export interface DocumentMetricsReduxState {
  metrics: any;
  metricsStatus: DataStatus;
  metadata?: { [key: string]: MetadataField };
  key: string;
}

export const initialState: DocumentMetricsReduxState = {
  // the metrics of the document
  metrics: null,
  // status of the document metrics load: completed, loading, error
  metricsStatus: DataStatus.NotLoaded,
  // company metadata
  metadata: null,
  // document id
  key: null,
};

export default handleActions(
  {
    [LOAD_DOCUMENT_METRICS]: (state: any, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          metricsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({ ...s, metricsStatus: DataStatus.Error }),
        success: (s) => {
          return {
            ...s,
            metricsStatus: DataStatus.Done,
            metrics: get(action, "payload.data", {}),
            key: get(action, "meta.documentId", null),
          };
        },
      });
    },
    [RESET_DOCUMENT_METRICS]: () => ({ ...initialState }),
  },
  initialState
);

//#endregion
