/**
 * Gets the input id using the added comment id
 * @param commentId
 * @returns
 */
export const getNewCommentInputId = (commentId) => `new-${commentId}`;

/**
 * Checks if the given comment id is equals to the input id
 * @param commentId
 * @param inputId
 * @returns
 */
export const isAddingNewComment = (commentId, inputId) =>
  inputId === getNewCommentInputId(commentId);

/**
 * Constant used for the reply input id
 */
export const REPLY_INPUT_ID = "akorda-reply-comment-input";

/**
 * Checks if the given input id is the reply input
 * @param inputId
 * @returns
 */
export const isReplyInput = (inputId) => inputId === REPLY_INPUT_ID;
