import contract from "./contract";
import clauseInfo from "./clause-info";
import admin from "./admin";
import { combineReducers } from "redux";

export default combineReducers({
  contract,
  clauseInfo,
  admin,
});
