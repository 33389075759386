import { useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./AppConfig.module.scss";

import {
  isLoadingConfig,
  loadAppConfig,
  hasLoadedConfig,
  hasConfigError,
  getIntercomAppId,
} from "@app/redux/data/config";
import SvgSpinner from "@app/toolkit/svgSpinner";
import { useTranslation } from "react-i18next";
import BannerMessage, {
  BANNER_MESSAGE_ICON_TYPES,
} from "@app/toolkit/BannerMessage";
import { useFeatureFlags } from "@app/redux/data/config";
import constants from "@app/utils/constants";
import { IntercomProvider } from "react-use-intercom";

const { INTERCOM } = constants.FEATURES_TOGGLE;

const AppConfig: FC = ({ children }) => {
  const isLoading = useSelector(isLoadingConfig);
  const hasLoaded = useSelector(hasLoadedConfig);
  const hasError = useSelector(hasConfigError);
  const intercomAppId = useSelector(getIntercomAppId);
  const [isIntercomEnabled] = useFeatureFlags(INTERCOM);
  const hasIntercom = intercomAppId && isIntercomEnabled;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && !hasLoaded && !hasError) {
      dispatch(loadAppConfig());
    }
  }, [dispatch, isLoading, hasLoaded, hasError]);

  if (isLoading) {
    return <SvgSpinner modal />;
  }

  if (hasError) {
    return (
      <BannerMessage
        iconType={BANNER_MESSAGE_ICON_TYPES.ERROR}
        className={s.error}
      >
        {t("app-config.error")}
      </BannerMessage>
    );
  }

  if (hasIntercom) {
    return (
      <IntercomProvider
        appId={intercomAppId}
        autoBootProps={{
          verticalPadding: 50,
        }}
      >
        {children}
      </IntercomProvider>
    );
  }

  return <>{children}</>;
};

export default AppConfig;
