import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import {
  UPLOAD_PLAYBOOK_TEMPLATE,
  RESET_INSIGHTS_ADMIN,
} from "../actions/types";

export interface InsightAdminReduxState {
  uploadTemplateStatus: string;
}

export const initialState: InsightAdminReduxState = {
  uploadTemplateStatus: null,
};

export default handleActions(
  {
    [UPLOAD_PLAYBOOK_TEMPLATE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          uploadTemplateStatus: "uploading",
        }),
        failure: (s) => ({
          ...s,
          uploadTemplateStatus: "error",
        }),
        success: (s) => {
          return {
            ...s,
            uploadTemplateStatus: "completed",
          };
        },
      });
    },
    [RESET_INSIGHTS_ADMIN]: () => ({ ...initialState }),
  },
  initialState
);
