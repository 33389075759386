import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, mapValues } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import IAction from "@app/types/IAction";
import { NOOP, resolveCompanyId } from "@app/utils/helpers";

import { denormalize, normalize } from "normalizr";
import { clausesSchema, issuesSchema, playbookSummarySchema } from "./schemas";
import { Playbook } from "@app/entities/playbook";
import {
  INDENT_PLAYBOOK_CLAUSE,
  UPDATE_FALLBACK,
  UPDATE_PLAYBOOK_CLAUSE,
  UPDATE_PLAYBOOK_ISSUE,
  MOVE_PLAYBOOK_CLAUSE,
  MOVE_PLAYBOOK_ISSUE,
  MOVE_FALLBACK,
  DELETE_PLAYBOOK_CLAUSE,
  DELETE_PLAYBOOK_ISSUE,
  DELETE_FALLBACK,
  UPDATE_STRUCTURE_TYPE,
  UPDATE_OPTIONAL_CLAUSE,
} from "@app/_Playbook/actions/types";
import { mapKeys } from "lodash";
import constants from "@app/utils/constants";

// #region ACTION TYPES
export const LOAD_PLAYBOOK = "AKORDA::LOAD_PLAYBOOK";
export const UPDATE_PLAYBOOK = "AKORDA::UPDATE_PLAYBOOK";
export const RESET_PLAYBOOK = "AKORDA::RESET_PLAYBOOK";
export const CREATE_PLAYBOOK = "AKORDA::CREATE_PLAYBOOK";
export const DELETE_PLAYBOOK = "AKORDA::DELETE_PLAYBOOK";
export const PUBLISH_PLAYBOOK = "AKORDA::PUBLISH_PLAYBOOK";
// #endregion

//#region ACTIONS

export const loadPlaybook =
  (
    playbookId?: number,
    expanded: boolean = true,
    onSuccess: () => void = NOOP,
    onFailure: () => void = NOOP
  ) =>
  (dispatch, getState) => {
    playbookId = playbookId || getPlaybookId(getState());
    return dispatch({
      type: LOAD_PLAYBOOK,
      promise: API.getPlaybook(playbookId, expanded),
      meta: {
        playbookId,
        expanded,
        onSuccess,
        onFailure,
      },
    });
  };

export const resetPlaybook = (): IAction => ({
  type: RESET_PLAYBOOK,
});

export const updatePlaybook = (
  playbookId: number,
  data: any = {},
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): any => ({
  type: UPDATE_PLAYBOOK,
  promise: API.updatePlaybook(playbookId, data),
  meta: {
    playbookId,
    onSuccess,
    onFailure,
  },
});

export const createPlaybook =
  (
    createPlaybookData: any,
    onSuccess: (response?: any) => void = NOOP,
    onFailure: (response?: any) => void = NOOP
  ) =>
  (dispatch, getState) => {
    // If there is a companyId in the query string use that (for Akorda Admin), otherwise fallback to user's id.
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: CREATE_PLAYBOOK,
      promise: API.createPlaybook(createPlaybookData, companyId),
      meta: {
        onSuccess,
        onFailure,
      },
    });
  };

export const deletePlaybook = (
  playbookId: number,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: DELETE_PLAYBOOK,
  promise: API.deletePlaybook(playbookId),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const publishPlaybook = (
  playbookId: number,
  onSuccess = NOOP,
  onFailure = NOOP
): IAction => ({
  type: PUBLISH_PLAYBOOK,
  promise: API.publishPlaybook(playbookId),
  meta: {
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const playbookSummaryState: (state: any) => PlaybookSummaryReduxState = (
  state
) => state.data.playbook.playbookSummary;

export const getPlaybookSummaryStatus = createSelector(
  playbookSummaryState,
  (state) => state.playbookSummaryStatus
);

export const isPlaybookSummaryLoading = createSelector(
  getPlaybookSummaryStatus,
  (status) => isDataLoading(status)
);

export const isPlaybookSummaryUpdating = createSelector(
  getPlaybookSummaryStatus,
  (status) => isDataSubmitting(status)
);

export const isCreatingPlaybookSummary = createSelector(
  getPlaybookSummaryStatus,
  (status: string) => status === "creating"
);

export const isDeletingPlaybookSummary = createSelector(
  getPlaybookSummaryStatus,
  (status: string) => status === "deleting"
);

export const hasPlaybookSummaryError = createSelector(
  getPlaybookSummaryStatus,
  (status) => hasDataError(status)
);

export const hasPlaybookSummaryLoaded = createSelector(
  getPlaybookSummaryStatus,
  (status) => hasDataLoaded(status)
);

const _getPlaybookSummary = createSelector(
  playbookSummaryState,
  (state) => state.playbookSummary || {}
);

export const getFallbacksMap = createSelector(
  playbookSummaryState,
  (state) => state.fallbacks || {}
);

const _getIssuesMap = createSelector(
  playbookSummaryState,
  (state) => state.issues || {}
);

export const getIssuesMap = createSelector(
  _getIssuesMap,
  getFallbacksMap,
  (issuesMap, fallbacks) =>
    mapValues(issuesMap, (issue) =>
      denormalize(issue, issuesSchema, { fallbacks })
    )
);

const _getClausesMap = createSelector(
  playbookSummaryState,
  (state) => state.clauses || {}
);

export const getClausesMap = createSelector(
  _getClausesMap,
  _getIssuesMap,
  getFallbacksMap,
  (clauses, issues, fallbacks) => {
    return mapValues(clauses, (clause) =>
      denormalize(clause, clausesSchema, {
        clauses: clauses,
        issues,
        fallbacks,
      })
    );
  }
);

export const getPlaybookId = createSelector(
  playbookSummaryState,
  (state) => state.playbookId || null
);

const _getFlattenedClausesIds = createSelector(
  getPlaybookId,
  _getPlaybookSummary,
  (playbookId, summary) => get(summary[playbookId], "flattenedClauseIds") || []
);

export const getFlattenedPlaybookClausesList = createSelector(
  _getFlattenedClausesIds,
  getClausesMap,
  (flattenedClauseIds, clauseMap) => {
    return flattenedClauseIds.map((flattenedClause) => ({
      ...flattenedClause,
      ...clauseMap[flattenedClause.id],
      parentClause: flattenedClause.parentClauseId
        ? clauseMap[flattenedClause.parentClauseId]
        : null,
    }));
  }
);

const isExpanded = createSelector(
  playbookSummaryState,
  (state) => state.isExpanded || false
);

export const getPlaybookSummary = createSelector(
  getPlaybookId,
  _getPlaybookSummary,
  _getClausesMap,
  _getIssuesMap,
  getFallbacksMap,
  (playbookId, playbookSummary, clauses, issues, fallbacks) =>
    denormalize(playbookId, playbookSummarySchema, {
      playbookSummary,
      clauses,
      issues,
      fallbacks,
    }) || {}
);

//#endregion

//#region HOOKS
export const usePlaybook = (
  playbookId: number,
  expanded: boolean = true
): [
  Playbook,
  {
    isLoading: boolean;
    hasError: boolean;
    hasLoaded: boolean;
    isUpdating: boolean;
  },
  any
] => {
  const dispatch = useDispatch();

  const playbook = useSelector(getPlaybookSummary) as Playbook;
  const isLoading = useSelector(isPlaybookSummaryLoading);
  const isUpdating = useSelector(isPlaybookSummaryUpdating);
  const hasError = useSelector(hasPlaybookSummaryError);
  const hasLoaded = useSelector(hasPlaybookSummaryLoaded);

  useEffect(() => {
    if (!playbookId) return;
    const status = runSelector(getPlaybookSummaryStatus);
    const currentPlaybookId = runSelector(getPlaybookId);
    const isCurrentPlaybookExpanded = runSelector(isExpanded);

    // load the playbook in case is not loaded, or it is a different playbook or the current one is not expanded
    if (
      isDataNotLoaded(status) ||
      (!!currentPlaybookId &&
        (currentPlaybookId !== playbookId ||
          (expanded && !isCurrentPlaybookExpanded)))
    ) {
      dispatch(loadPlaybook(playbookId, expanded));
    }
  }, [playbookId, expanded, dispatch]);

  const status = useMemo(
    () => ({
      isLoading,
      hasError,
      hasLoaded,
      isUpdating,
    }),
    [isLoading, hasError, hasLoaded, isUpdating]
  );

  const actions = useMemo(
    () => ({
      loadPlaybook,
      updatePlaybook,
      resetPlaybook,
    }),
    []
  );

  return [playbook, status, actions];
};
//#endregion

//#region REDUCER
export interface PlaybookSummaryReduxState {
  playbookSummary: any;
  playbookSummaryStatus: DataStatus;
  clauses: any;
  issues: any;
  fallbacks: any;
  playbookId: any;
  isExpanded: boolean;
}

export const initialState: PlaybookSummaryReduxState = {
  playbookSummary: null,
  playbookSummaryStatus: DataStatus.NotLoaded,
  clauses: null,
  issues: null,
  fallbacks: null,
  playbookId: null,
  isExpanded: null,
};

const handleLoadPlaybook = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Loading,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Error,
    }),
    success: (s) => {
      const { entities, result: playbookId } = normalize(
        get(action, "payload.data"),
        playbookSummarySchema
      );
      const { playbookSummary, clauses, issues, fallbacks } = entities;
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        playbookSummary,
        clauses,
        issues,
        fallbacks,
        playbookId,
        isExpanded: get(action, "meta.expanded"),
      };
    },
  });

const handleUpdatePlaybook = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
    success: (s) => {
      const updatedPlaybookSummary = get(action, "payload.data") || {};
      const playbookId = updatedPlaybookSummary.id;
      const currentPlaybookSummary = get(s.playbookSummary, playbookId) || {};
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        playbookSummary: {
          [playbookId]: {
            ...currentPlaybookSummary,
            ...updatedPlaybookSummary,
          },
        },
      };
    },
  });

const handleCreatePlaybook = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: "creating",
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Error,
    }),
    success: (s) => {
      const { entities, result: playbookId } = normalize(
        get(action, "payload.data"),
        playbookSummarySchema
      );
      const { playbookSummary, clauses, issues, fallbacks } = entities;
      playbookSummary["status"] = constants.PLAYBOOKS_STATUS_MAP.PROCESSING; // force the status to "processing" since that status comes some seconds later in the async process
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        playbookSummary,
        clauses,
        issues,
        fallbacks,
        playbookId,
        isExpanded: get(action, "meta.expanded"),
      };
    },
  });

const handleDeletePlaybook = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: "deleting",
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
    success: () => ({ ...initialState }),
  });

const handleStartUpdatingPlaybook = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
  });

const handleUpdateClause = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
    success: (s) => {
      const updatedClause = get(action, "payload.data") || {};
      // We normalize the response to have the same data structure but
      // since the response doesn't include issues we don't replace
      // all clause data, just add it to keep the issues.
      const {
        entities: { clauses },
      } = normalize(updatedClause, clausesSchema);
      const clausesMap = { ...s.clauses };
      mapKeys(clauses, (clause, clauseId) => {
        clausesMap[clauseId] = {
          ...clausesMap[clauseId],
          ...clause,
        };
      });
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        clauses: clausesMap,
      };
    },
  });

const handleUpdateIssue = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
    success: (s) => {
      const updatedIssue = get(action, "payload.data") || {};
      const issueId = updatedIssue.id;
      const currentIssue = get(s.issues, issueId) || {};
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        issues: {
          ...s.issues,
          [issueId]: { ...currentIssue, ...updatedIssue },
        },
      };
    },
  });

const handleUpdateFallback = (state, action) =>
  handle(state, action, {
    start: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Submitting,
    }),
    failure: (s) => ({
      ...s,
      playbookSummaryStatus: DataStatus.Done,
    }),
    success: (s) => {
      const updatedFallback = get(action, "payload.data") || {};
      const fallbackId = updatedFallback.id;
      const currentFallback = get(s.fallbacks, fallbackId) || {};
      return {
        ...s,
        playbookSummaryStatus: DataStatus.Done,
        fallbacks: {
          ...s.fallbacks,
          [fallbackId]: { ...currentFallback, ...updatedFallback },
        },
      };
    },
  });

export default handleActions(
  {
    [LOAD_PLAYBOOK]: handleLoadPlaybook,
    [UPDATE_PLAYBOOK]: handleUpdatePlaybook,
    [UPDATE_PLAYBOOK_CLAUSE]: handleUpdateClause,
    [UPDATE_PLAYBOOK_ISSUE]: handleUpdateIssue,
    [UPDATE_FALLBACK]: handleUpdateFallback,
    [INDENT_PLAYBOOK_CLAUSE]: handleStartUpdatingPlaybook,
    [MOVE_PLAYBOOK_CLAUSE]: handleStartUpdatingPlaybook,
    [MOVE_PLAYBOOK_ISSUE]: handleStartUpdatingPlaybook,
    [MOVE_FALLBACK]: handleStartUpdatingPlaybook,
    [DELETE_PLAYBOOK_CLAUSE]: handleStartUpdatingPlaybook,
    [DELETE_PLAYBOOK_ISSUE]: handleStartUpdatingPlaybook,
    [DELETE_FALLBACK]: handleStartUpdatingPlaybook,
    [UPDATE_STRUCTURE_TYPE]: handleStartUpdatingPlaybook,
    [UPDATE_OPTIONAL_CLAUSE]: handleStartUpdatingPlaybook,
    [CREATE_PLAYBOOK]: handleCreatePlaybook,
    [DELETE_PLAYBOOK]: handleDeletePlaybook,
    [RESET_PLAYBOOK]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
