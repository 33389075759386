import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import {
  TOGGLE_DISPLAY_DIFFS,
  EXECUTE_CLUSTERING,
  RESET_CLAUSE_INFO,
} from "../actions/types";

export interface InsightClauseReduxState {
  isShowingDiffs: boolean;
  showAddFallbackModal: boolean;
  clusteringStatus: string;
}

export const initialState: InsightClauseReduxState = {
  isShowingDiffs: true,
  showAddFallbackModal: false,
  clusteringStatus: null,
};

export default handleActions(
  {
    [TOGGLE_DISPLAY_DIFFS]: (state) => ({
      ...state,
      isShowingDiffs: !state.isShowingDiffs,
    }),
    [EXECUTE_CLUSTERING]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          clusteringStatus: "loading",
        }),
        failure: (s) => ({
          ...s,
          clusteringStatus: "error",
        }),
        success: (s) => ({
          ...s,
          clusteringStatus: "completed",
        }),
      });
    },
    [RESET_CLAUSE_INFO]: () => ({
      ...initialState,
    }),
  },
  initialState
);
