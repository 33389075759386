import { Api, ApiResponse } from "./_base";
import { Alert, AlertName, AlertType } from "@app/entities/alerts";

export function loadAlerts(
  objectId?: string,
  objectType?: string,
  alertType?: AlertType,
  alertName?: AlertName
): Promise<ApiResponse<{ alerts: Alert[] }>> {
  return Api.execute({
    url: `/api/alerts`,
    params: {
      objectId,
      objectType,
      alertType: alertType ?? AlertType.User,
      alertName,
    },
  });
}

export interface CreateAlertRequest {
  objectId: string;
  objectType: string;
  userId?: string;
  alertName: string;
  alertType: string;
  arguments?: any;
}

export function createAlert(
  data: CreateAlertRequest
): Promise<ApiResponse<Alert>> {
  return Api.execute({
    url: `/api/alerts`,
    method: "POST",
    data,
  });
}

export function deleteAlert(alertId: string): Promise<ApiResponse<void>> {
  return Api.execute({
    url: `/api/alerts/${alertId}`,
    method: "DELETE",
  });
}
