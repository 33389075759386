import { FileInfo } from "@app/types-business/Document";
/**
 * Returns the mime/media type for the given file, and handles exceptional
 * cases like .msg files which are not recognized by the browser.
 * @param file
 * @returns the media type / mime type for the file
 */
const getMediaType = (file: File) => {
  if (!file.type && file.name.endsWith(".msg"))
    return "application/vnd.ms-outlook";
  if (!file.type && file.name.endsWith(".docx"))
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  return file.type;
};

/**
 * Utility fuction to convert the array buffer to base 64
 */
export const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

/**
 * Utility fuction to convert base 64 to the array buffer
 */
export const base64ToArrayBuffer = (base64Content: string) => {
  if (!base64Content) {
    return null;
  }
  var binary_string = window.atob(base64Content);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
};

/**
 * Reads the content of a file
 */
export const readFile = async (file: File): Promise<FileInfo> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      // Convert array buffer to base64 string
      const bytes = arrayBufferToBase64(e.target["result"] as ArrayBuffer);

      resolve({
        filename: file.name,
        mediaType: getMediaType(file),
        bytes,
      });
    };
    reader.readAsArrayBuffer(file);
  });
};

/**
 * Selecting a file from the file system will often use the extension (.docx, .doc, .pdf) to determine
 * media type, but a user can rename a .doc file to be .docx, and the browser won't know the difference.
 * Here we can use the first bytes of a file to determine if it's a docx file. Warning, technically the
 * signature will indicate any office document (including xlsx and pptx).
 * @param file
 * @returns true if the file is a docx file
 */
export const isValidFileBySignature = async (
  file: File,
  signature: string[]
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target["result"];
      const uint = new Uint8Array(result as ArrayBuffer);
      let bytes = [];
      uint.forEach((byte) => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join("").toUpperCase();
      if (signature.includes(hex)) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    const blob = file.slice(0, 4);
    reader.readAsArrayBuffer(blob);
  });
};

export const isDocx = async (file: File): Promise<any> => {
  return isValidFileBySignature(file, ["504B34"]); // "magic" byte/signature to identify docx file (ignoring file extension)
};

export const isPdf = (file: File): Promise<any> =>
  isValidFileBySignature(file, ["25504446"]); // "magic" byte/signature to identify pdf file (ignoring file extension)

export const isValidFileContract = async (file: File): Promise<any> =>
  isValidFileBySignature(file, ["504B34", "25504446"]); // "magic" byte/signature to identify docx and pdf file (ignoring file extension)

export function isImageMediaType(mediaType: string) {
  return [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/tiff",
    "image/bmp",
  ].includes(mediaType);
}

export function convertCsvToArray(
  text: string,
  delimiter: string = ",",
  ignoreFirstRow: boolean = false
) {
  return text
    .slice(ignoreFirstRow ? text.indexOf("\n") + 1 : 0)
    .split("\n")
    .map((v) => v.split(delimiter));
}
