import { isEmpty } from "../lodash";
import { isClientSide } from "./common";

export function getSubdomain(hostname?: string) {
  if (!hostname && isClientSide()) {
    hostname = window.location.hostname;
  }
  if (!isEmpty(hostname)) {
    const regexParse = new RegExp("[a-zA-Z0-9-_]{2,63}.[a-z.]{2,5}$");
    const urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0], "").slice(0, -1);
  }
  return null;
}

export function isValidAlias(alias: String, defaultSubdomain: string) {
  return (
    !!alias && alias.toLowerCase() !== (defaultSubdomain || "").toLowerCase()
  );
}

export function parseAppHostname(hostname: string): {
  appHostname: string;
  customerSubdomain: string;
  appSubdomain: string;
} {
  const result = {
    appHostname: hostname,
    customerSubdomain: null,
    appSubdomain: null,
  };
  const subdomain = getSubdomain(hostname);
  if (!subdomain) return result;

  if (!subdomain.includes("."))
    return {
      ...result,
      appSubdomain: subdomain,
    };

  const [customerSubdomain, appSubdomain] = subdomain.split(".");
  return {
    ...result,
    appSubdomain,
    customerSubdomain,
    appHostname: hostname.replace(subdomain, appSubdomain),
  };
}
