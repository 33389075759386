export const SHOW_EXPORT_MODAL = "SHOW_EXPORT_MODAL";
export const HIDE_EXPORT_MODAL = "HIDE_EXPORT_MODAL";
export const SHOW_DOCUMENT_RELOAD_MODAL = "SHOW_DOCUMENT_RELOAD_MODAL";
export const HIDE_DOCUMENT_RELOAD_MODAL = "HIDE_DOCUMENT_RELOAD_MODAL";
export const LOAD_DOCUMENT_SUMMARY = "LOAD_DOCUMENT_SUMMARY";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const SET_SHOW_TOOLBOX_DIFF = "SET_SHOW_TOOLBOX_DIFF";
export const SEARCH_TOOLBOX_TAB = "SEARCH_TOOLBOX_TAB";
export const UPDATE_SEARCHTOOLBOX_MATCH_COUNT =
  "UPDATE_SEARCHTOOLBOX_MATCH_COUNT";
export const UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX =
  "UPDATE_SEARCHTOOLBOX_MATCH_FOCUS_INDEX";
export const TOGGLE_SHOW_TOOLBOX_SEARCH = "TOGGLE_SHOW_TOOLBOX_SEARCH";
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";
export const RESET_TOOLBOX = "RESET_TOOLBOX";

const types = {
  SET_SORT_OPTION: "SET_SORT_OPTION",
  VIEW_APPROVALS: "VIEW_APPROVALS",
  TOGGLE_APPROVAL_MODAL: "TOGGLE_APPROVAL_MODAL",
  TOGGLE_CANCEL_APPROVAL_MODAL: "TOGGLE_CANCEL_APPROVAL_MODAL",
  TOGGLE_EXPORT_MODAL: "TOGGLE_EXPORT_MODAL",
  TOGGLE_IMPORT_MODAL: "TOGGLE_IMPORT_MODAL",
  TOOLBOX_MINIMIZE: "TOOLBOX_MINIMIZE",
  TOOLBOX_MAXIMIZE: "TOOLBOX_MAXIMIZE",
  TOOLBOX_HIDE: "TOOLBOX_HIDE",
  TOOLBOX_SHOW: "TOOLBOX_SHOW",
  TOOLBOX_CHANGE_TAB: "TOOLBOX_CHANGE_TAB",
  SET_SHOW_TOOLBOX_DIFF,
  SHOW_EXPORT_MODAL,
  HIDE_EXPORT_MODAL,
  SHOW_DOCUMENT_RELOAD_MODAL,
  HIDE_DOCUMENT_RELOAD_MODAL,
  LOAD_DOCUMENT_SUMMARY,
  DOWNLOAD_FILE,
  SET_FILTER_OPTION,
  RESET_TOOLBOX,
};

export default types;
