import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { filter, get } from "@app/utils/lodash";
import * as API from "@app/API";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  runSelector,
} from "@app/redux/utils";
import {
  isArchivedPlaybook,
  isPublishedPlaybook,
} from "@app/entities/playbook";

export interface MetadataPlaybookListState {
  playbooks: any[];
  playbooksStatus: DataStatus;
  key: string;
}

// #region ACTION TYPES
export const LOAD_METADATA_PLAYBOOK_LIST =
  "PLAYBOOK_METADATA::LOAD_METADATA_PLAYBOOK_LIST";
export const REFRESH_METADATA_PLAYBOOK_LIST =
  "PLAYBOOK_METADATA::REFRESH_METADATA_PLAYBOOK_LIST";
export const RESET_METADATA_PLAYBOOK_LIST =
  "PLAYBOOK_METADATA::RESET_PLAYBOOK_LIST";

// #endregion

//#region SELECTORS
export const playbookListState: (state: any) => MetadataPlaybookListState = (
  state
) => state.data.metadata.playbookList;

//#region ACTIONS
export const loadMetadataPlaybookList = (metadataId): any => ({
  type: LOAD_METADATA_PLAYBOOK_LIST,
  promise: API.getMetadataPlaybooks(metadataId),
  meta: {
    metadataId,
  },
});

export const refreshMetadataPlaybookList = (metadataId): any => ({
  type: REFRESH_METADATA_PLAYBOOK_LIST,
  promise: API.getMetadataPlaybooks(metadataId),
  meta: {
    metadataId,
  },
});

export const resetMetadataPlaybookList = (): IAction => ({
  type: RESET_METADATA_PLAYBOOK_LIST,
});

export const getPlaybooks = createSelector(
  playbookListState,
  (state) => state.playbooks || []
);

export const getActivePlaybooks = createSelector(getPlaybooks, (playbooks) =>
  filter(playbooks, (metadataPlaybook) => {
    const playbook = metadataPlaybook?.playbook;
    return isPublishedPlaybook(playbook) || isArchivedPlaybook(playbook);
  })
);

export const getPlaybooksStatus = createSelector(
  playbookListState,
  (state) => state.playbooksStatus
);

export const isPlaybookListLoading = createSelector(
  getPlaybooksStatus,
  (status) => isDataLoading(status)
);

export const hasPlaybookListError = createSelector(
  getPlaybooksStatus,
  (status) => hasDataError(status)
);

export const hasPlaybookListLoaded = createSelector(
  getPlaybooksStatus,
  (status) => hasDataLoaded(status)
);

export const getResourceKey = createSelector(
  playbookListState,
  (state) => state.key
);
//#endregion

//#region HOOKS
export const useMetadataPlaybooksList = (metadataId: number) => {
  const dispatch = useDispatch();
  const playbooksList = useSelector(getActivePlaybooks);
  const isLoading = useSelector(isPlaybookListLoading);
  const hasError = useSelector(hasPlaybookListError);
  const hasLoaded = useSelector(hasPlaybookListLoaded);

  useEffect(() => {
    const status = runSelector(getPlaybooksStatus);
    const key = runSelector(getResourceKey);
    if (isDataNotLoaded(status) || key !== metadataId) {
      dispatch(loadMetadataPlaybookList(metadataId));
    }
  }, [dispatch, hasError, hasLoaded, isLoading, metadataId]);

  const actions: any = {
    loadMetadataPlaybookList,
    refreshMetadataPlaybookList,
    resetMetadataPlaybookList,
  };

  return [playbooksList, { isLoading, hasError, hasLoaded }, actions];
};
//#endregion

//#region REDUCER
export const initialState: MetadataPlaybookListState = {
  // map of playbooks key'd by ID
  playbooks: null,
  // status of the playbooks load: completed, loading, error
  playbooksStatus: DataStatus.NotLoaded,
  key: null,
};

export default handleActions(
  {
    [RESET_METADATA_PLAYBOOK_LIST]: () => ({
      ...initialState,
    }),
    [LOAD_METADATA_PLAYBOOK_LIST]: (state, action) => {
      return handle(state, action, {
        start: (s: MetadataPlaybookListState): MetadataPlaybookListState => ({
          ...s,
          playbooksStatus: DataStatus.Loading,
          playbooks: null,
          key: null,
        }),
        failure: (s: MetadataPlaybookListState): MetadataPlaybookListState => ({
          ...s,
          playbooksStatus: DataStatus.Error,
        }),
        success: (s: MetadataPlaybookListState): MetadataPlaybookListState => {
          return {
            ...s,
            playbooksStatus: DataStatus.Done,
            playbooks: get(action, "payload.data", []),
            key: get(action, "meta.metadataId") || null,
          };
        },
      });
    },
    [REFRESH_METADATA_PLAYBOOK_LIST]: (state, action) => {
      return handle(state, action, {
        start: (s: MetadataPlaybookListState): MetadataPlaybookListState => ({
          ...s,
          playbooksStatus: DataStatus.Loading,
        }),
        failure: (s: MetadataPlaybookListState): MetadataPlaybookListState => ({
          ...s,
          playbooksStatus: DataStatus.Error,
        }),
        success: (s: MetadataPlaybookListState): MetadataPlaybookListState => {
          return {
            ...s,
            playbooksStatus: DataStatus.Done,
            playbooks: get(action, "payload.data", []),
          };
        },
      });
    },
  },
  initialState
);
//#endregion
