import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { createSelector } from "reselect";
import * as API from "@app/API";
import { get, without } from "@app/utils/lodash";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Comment,
  CommentReference,
  getCommentKey,
} from "@app/entities/comments";
import { denormalize, normalize } from "normalizr";
import { commentListSchema } from "./schema";
import { NOOP } from "@app/utils/helpers";
import IAction from "@app/types/IAction";

//#region TYPES
export const LOAD_COMMENTS = "chat/load";
export const CREATE_COMMENT = "chat/create";
export const UPDATE_COMMENT = "chat/update";
export const DELETE_COMMENT = "chat/delete";
export const RESET_COMMENTS = "chat/reset";
//#endregion

//#region ACTIONS
export const resetComments = () => ({
  type: RESET_COMMENTS,
});

export const loadComments = (
  type: string,
  entityId: string | number,
  onSuccess?: () => void,
  onFailure?: () => void
): IAction => ({
  type: LOAD_COMMENTS,
  promise: API.loadComments(type, entityId),
  meta: {
    key: getCommentKey(type, entityId),
    onSuccess,
    onFailure,
  },
});

export const createComment = (
  type: string,
  entityId: string | number,
  text: string,
  reference: CommentReference,
  onSuccess?: () => void,
  onFailure?: () => void
) => ({
  type: CREATE_COMMENT,
  promise: API.createComment(type, entityId, text, reference),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteComment = (
  commentId: number | string,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): IAction => ({
  type: DELETE_COMMENT,
  promise: API.deleteComment(commentId),
  meta: {
    commentId,
    onSuccess,
    onFailure,
  },
});

export const updateComment = (
  commentId: number | string,
  text: string,
  reference?: CommentReference,
  onSuccess?: () => void,
  onFailure?: () => void
): IAction => ({
  type: UPDATE_COMMENT,
  promise: API.updateComment(commentId, text, reference),
  meta: {
    onSuccess,
    onFailure,
  },
});
//#endregion

//#region SELECTORS
export const commentsState = (state) => state.data.comments;

export const getCommentsMap = createSelector(
  commentsState,
  (state) => get(state, "comments") || {}
);

export const getCommentIds = createSelector(
  commentsState,
  (state) => get(state, "commentIds") || []
);

export const getComments = createSelector(
  getCommentsMap,
  getCommentIds,
  (comments, ids) => {
    return denormalize(ids, commentListSchema, { comments });
  }
);

export const getCommentsStatus = createSelector(
  commentsState,
  (state) => state.commentsStatus
);

export const isLoadingComments = createSelector(
  getCommentsStatus,
  (status) => status === DataStatus.Loading
);

export const isSubmittingComment = createSelector(
  getCommentsStatus,
  (status) => status === DataStatus.Submitting
);

export const hasCommentsError = createSelector(
  getCommentsStatus,
  (status) => status === DataStatus.Error
);

export const hasCommentsLoaded = createSelector(
  getCommentsStatus,
  (status) => status === DataStatus.Done
);

export const getCommentsKey = createSelector(
  commentsState,
  (state) => state.key
);

//#endregion

//#region  HOOKS
export const useComments = (type: string, entityId: string | number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (entityId) {
      const status = runSelector(getCommentsStatus);
      const key = runSelector(getCommentsKey);
      if (isDataNotLoaded(status) || key !== getCommentKey(type, entityId)) {
        dispatch(loadComments(type, entityId));
      }
    }
  }, [type, entityId, dispatch]);

  const comments = useSelector(getComments);
  const status = useSelector(getCommentsStatus);
  const isLoading = isDataLoading(status);
  const hasError = hasDataError(status);
  const hasLoaded = hasDataLoaded(status);
  const isSubmitting = isDataSubmitting(status);

  return [comments, { isLoading, hasError, hasLoaded, isSubmitting }];
};
//#endregion

///#region REDUCER

export interface CommentsState {
  key: string;
  comments: Record<number, Comment>;
  commentIds: number[];
  commentsStatus: DataStatus;
}

export const initialState: CommentsState = {
  key: null,
  comments: null,
  commentIds: [],
  commentsStatus: DataStatus.NotLoaded,
};

export default handleActions(
  {
    [LOAD_COMMENTS]: (state: CommentsState, action: any) => {
      return handle(state, action, {
        start: (s: CommentsState): CommentsState => ({
          ...s,
          key: action.meta.key,
          commentsStatus: DataStatus.Loading,
        }),
        failure: (s: CommentsState): CommentsState => ({
          ...s,
          key: null,
          commentsStatus: DataStatus.Error,
        }),
        success: (s: CommentsState): CommentsState => {
          const comments = get(action, "payload.data") || [];
          const { entities, result } = normalize(comments, commentListSchema);
          return {
            ...s,
            commentsStatus: DataStatus.Done,
            comments: entities.comments as Record<number, Comment>,
            commentIds: result,
          };
        },
      });
    },
    [CREATE_COMMENT]: (state, action) => {
      return handle(state, action, {
        start: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Submitting,
        }),
        failure: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Done,
        }),
        success: (s: CommentsState): CommentsState => {
          const comment: Comment = get(action, "payload.data");
          return {
            ...s,
            commentsStatus: DataStatus.Done,
            comments: {
              ...s.comments,
              [comment.id]: comment,
            },
            commentIds: [...s.commentIds, comment.id as number],
          };
        },
      });
    },
    [UPDATE_COMMENT]: (state, action) => {
      return handle(state, action, {
        start: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Submitting,
        }),
        failure: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Done,
        }),
        success: (s: CommentsState): CommentsState => {
          const comment: Comment = get(action, "payload.data");

          return {
            ...s,
            commentsStatus: DataStatus.Done,
            comments: {
              ...s.comments,
              [comment.id]: comment,
            },
          };
        },
      });
    },
    [DELETE_COMMENT]: (state, action) => {
      return handle(state, action, {
        start: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Submitting,
        }),
        failure: (s: CommentsState): CommentsState => ({
          ...s,
          commentsStatus: DataStatus.Done,
        }),
        success: (s: CommentsState): CommentsState => {
          const id: number = get(action, "meta.commentId");

          const comments = {
            ...s.comments,
          };

          delete comments[id];
          return {
            ...s,
            commentsStatus: DataStatus.Done,
            comments,
            commentIds: without(s.commentIds, id),
          };
        },
      });
    },
    [RESET_COMMENTS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
