import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import get from "lodash/get";
import {
  LOAD_FILING_DOCUMENT,
  LOAD_FILING_METADATA,
  RESET_FILING_WIZARD,
  UPDATE_DOCUMENT_ACCESS,
  UPDATE_DOCUMENT_FOLDER,
  UPDATE_FILING_DOCUMENT_TYPE,
  RELATE_DOCUMENT,
  REFRESH_DOCUMENT,
} from "../actions/types";

export interface State {
  document: any;
  documentStatus: string;
  metadata: any[];
  metadataStatus: string;
}

export const initialState: State = {
  document: null,
  documentStatus: null,
  metadata: [],
  metadataStatus: null,
};

const handleUpdate =
  (documentPath = "payload.data") =>
  (state, action) => {
    return handle(state, action, {
      success: (s) => {
        return {
          ...s,
          document: get(action, documentPath),
        };
      },
    });
  };

export default handleActions(
  {
    [LOAD_FILING_DOCUMENT]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          documentStatus: "loading",
        }),
        failure: (s) => ({
          ...s,
          documentStatus: "error",
        }),
        success: (s) => {
          return {
            ...s,
            documentStatus: "completed",
            document: get(action, "payload.data", null),
          };
        },
      });
    },
    [LOAD_FILING_METADATA]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          metadataStatus: "loading",
        }),
        failure: (s) => ({
          ...s,
          metadataStatus: "error",
        }),
        success: (s) => {
          return {
            ...s,
            metadataStatus: "completed",
            metadata: get(action, "payload.data", null),
          };
        },
      });
    },
    [UPDATE_FILING_DOCUMENT_TYPE]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          documentStatus: "updating",
        }),
        failure: (s) => ({
          ...s,
          documentStatus: null,
        }),
        success: (s) => {
          return {
            ...s,
            documentStatus: "completed",
          };
        },
      });
    },
    [UPDATE_DOCUMENT_ACCESS]: handleUpdate(),
    [RELATE_DOCUMENT]: handleUpdate(),
    [UPDATE_DOCUMENT_FOLDER]: handleUpdate("payload.data.document"),
    [REFRESH_DOCUMENT]: handleUpdate(),
    [RESET_FILING_WIZARD]: () => {
      return { ...initialState };
    },
  },
  initialState
);
