import { JSONSchema7 } from "json-schema";

export enum IntegrationName {
  AdobeSign = "ADOBESIGN",
  DocuSign = "DOCUSIGN",
  HubSpot = "HUBSPOT",
  OpenId = "OPENID",
  Salesforce = "SALESFORCE",
  LegalSifterReview = "LEGALSIFTER_REVIEW",
}

export enum IntegrationType {
  Crawler = "CRAWLER",
  CRM = "CRM",
  Signature = "SIGNATURE",
  SSO = "SSO",
}

export interface Integration {
  id: number;
  name: IntegrationName;
  type: IntegrationType;
  description: string;
  options: JSONSchema7;
}

export interface Configuration {
  companyId: number;
  id: number;
  integrationId: number;
  integrationName: IntegrationName;
  integrationType: IntegrationType;
  options: any;
}

export function getIntegrationName(configuration: Configuration) {
  return configuration?.integrationName;
}
