import { createStore as createReduxStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootStore from "./combineReducers";
import { middleware as reduxPackMiddleware } from "redux-pack";
import { isDevelopment } from "@app/utils/helpers";

export const ReduxStore = {
  store: null,
};

export const createStore = (initialState?: any) => {
  const middlewares = [thunk, reduxPackMiddleware];

  if (isDevelopment) {
    middlewares.push(createLogger());
  }

  const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  });

  const store = createReduxStore(
    rootStore,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  ReduxStore.store = store;
  return store;
};
