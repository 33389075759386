import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Dashboard } from "@app/entities/dashboard";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { get, orderBy, without } from "@app/utils/lodash";
import { denormalize, normalize } from "normalizr";
import { dashboardListSchema } from "./schemas";
import { NOOP } from "@app/utils/helpers";
import { UPDATE_DASHBOARD } from "./dashboard-details";

//#region TYPES
export const LOAD_DASHBOARDS = "AKORDA::LOAD_DASHBOARDS";
export const CREATE_DASHBOARD = "/dashboard/create";
export const DELETE_DASHBOARD = "/dashboard/delete";
//#endregion

//#region  ACTIONS
export const loadDashboards = (userId: number) => ({
  type: LOAD_DASHBOARDS,
  promise: API.loadEntities("DASHBOARD"),
  meta: {
    userId,
  },
});

export const createDashboard = (
  dashboard: {
    name: string;
    description?: string;
  },
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: CREATE_DASHBOARD,
  promise: API.createEntity("DASHBOARD", dashboard),
  meta: {
    onSuccess,
    onFailure,
  },
});

export const deleteDashboard = (
  dashboardId: string,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: DELETE_DASHBOARD,
  promise: API.deleteEntity(dashboardId),
  meta: {
    dashboardId,
    onSuccess,
    onFailure,
  },
});
//#endregion

/* #region  SELECTORS */
export const dashboardsState = (state) => state.data.dashboard.dashboardList;

export const getDashboards = createSelector(
  dashboardsState,
  (state) => get(state, "dashboards") || {}
);

export const getDashboardIds = createSelector(
  dashboardsState,
  (state) => state.dashboardIds
);

export const getDashboardList = createSelector(
  getDashboards,
  getDashboardIds,
  (dashboards, ids) => {
    return orderBy(
      denormalize(ids, dashboardListSchema, {
        dashboards,
      }),
      ["createdDate"],
      ["desc"]
    );
  }
);

export const getDashboardsStatus = createSelector(
  dashboardsState,
  (state) => state.dashboardsStatus
);

export const isDashboardListLoading = createSelector(
  getDashboardsStatus,
  (status) => status === DataStatus.Loading
);

export const isDashboardListSubmitting = createSelector(
  getDashboardsStatus,
  (status) => status === DataStatus.Submitting
);

export const hasDashboardListError = createSelector(
  getDashboardsStatus,
  (status) => status === DataStatus.Error
);

export const hasDashboardListLoaded = createSelector(
  getDashboardsStatus,
  (status) => status === DataStatus.Done
);

export const getActiveUserId = createSelector(
  dashboardsState,
  (state) => state.key
);

//#endregion

//#region HOOKS
export const useDashboards = (userId: number) => {
  const dispatch = useDispatch();

  const dashboards = useSelector(getDashboardList);
  const dashboardMap = useSelector(getDashboards);
  const isLoading = useSelector(isDashboardListLoading);
  const hasError = useSelector(hasDashboardListError);
  const hasLoaded = useSelector(hasDashboardListLoaded);
  const isSubmitting = useSelector(isDashboardListSubmitting);

  useEffect(() => {
    const status = runSelector(getDashboardsStatus);
    const key = runSelector(getActiveUserId);
    if (isDataNotLoaded(status) || (!!key && key !== userId)) {
      dispatch(loadDashboards(userId));
    }
  }, [userId, dispatch]);

  return [
    dashboards,
    dashboardMap,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
  ];
};
//#endregion

/* #region  REDUCER  */
export interface DashboardsState {
  dashboards: { [key: string]: Dashboard };
  dashboardIds: string[];
  dashboardsStatus: DataStatus;
  key: number;
}

export const initialState: DashboardsState = {
  // map of dashboards available to the user
  dashboards: {},
  // ordered list of ids for dashboards
  dashboardIds: [],
  // status of the dashboards: loading, error, completed
  dashboardsStatus: DataStatus.NotLoaded,
  // the resource id of the current user (userId)
  key: null,
};

export default handleActions(
  {
    [LOAD_DASHBOARDS]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          dashboardsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          dashboardsStatus: DataStatus.Error,
        }),
        success: (s) => {
          const { entities, result } = normalize(
            get(action, "payload.data.entities", []),
            dashboardListSchema
          );
          const { dashboards } = entities;

          return {
            ...s,
            key: get(action, "meta.userId", null),
            dashboardsStatus: DataStatus.Done,
            dashboardIds: result,
            dashboards,
          };
        },
      }),
    [CREATE_DASHBOARD]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          dashboardsStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          dashboardsStatus: DataStatus.Done,
        }),
        success: (s) => {
          const newDashboard: any = get(action, "payload.data");

          return {
            ...s,
            dashboardsStatus: DataStatus.Done,
            dashboardIds: [...s.dashboardIds, newDashboard.id],
            dashboards: { ...s.dashboards, [newDashboard.id]: newDashboard },
          };
        },
      }),
    [DELETE_DASHBOARD]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          dashboardsStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          dashboardsStatus: DataStatus.Done,
        }),
        success: (s) => {
          const dashboardId: string = get(action, "meta.dashboardId") || "";
          const dashboards = {
            ...s.dashboards,
          };
          delete dashboards[dashboardId];
          return {
            ...s,
            dashboardsStatus: DataStatus.Done,
            dashboardIds: without(s.dashboardIds, dashboardId),
            dashboards,
          };
        },
      }),
    [UPDATE_DASHBOARD]: (state, action) =>
      handle(state, action, {
        success: (s) => {
          const dashboard: any = get(action, "payload.data");
          const dashboards = {
            ...s.dashboards,
            [dashboard.id]: dashboard,
          };
          return {
            ...s,
            dashboards,
          };
        },
      }),
  },
  initialState
);
/* #endregion */
