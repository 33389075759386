import playbookSummary from "./playbook-summary";
import playbookSearch from "./playbook-search";
import playbookList from "./playbook-list";
import { combineReducers } from "redux";

export default combineReducers({
  playbookSummary,
  playbookSearch,
  playbookList,
});
