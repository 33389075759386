import company from "./company-metadata";
import playbook from "./playbook-metadata";
import playbookList from "./playbook-list";

import { combineReducers } from "redux";

export default combineReducers({
  company,
  playbook,
  playbookList,
});
