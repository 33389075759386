import { get } from "@app/utils/lodash";
import { handleActions } from "redux-actions";
import { handle } from "redux-pack";

import * as API from "@app/API";
import { resolveCompanyId } from "@app/utils/helpers";
import { createSelector } from "reselect";
import { RESET_PLAYBOOK_LIST } from "./playbook-list";
import { useSelector } from "react-redux";

type Status = "loading" | "error" | "completed";

export interface PlaybookStatsState {
  stats: any;
  statsStatus: Status;
}

//#region ACTION TYPES
export const LOAD_PLAYBOOK_STATS = "PLAYBOOK::LOAD_PLAYBOOK_STATS";
//#endregion

//#region ACTIONS
export const loadPlaybookStats = (): any => {
  return (dispatch, getState) => {
    const companyId = resolveCompanyId(getState());
    return dispatch({
      type: LOAD_PLAYBOOK_STATS,
      promise: API.getPlaybookStats(companyId),
    });
  };
};
//#endregion

//#region SELECTORS
export const playbookStatsState: (state: any) => PlaybookStatsState = (state) =>
  state.data.playbook.playbookStats;

export const getPlaybookStats = createSelector(playbookStatsState, (state) =>
  get(state, "stats", null)
);

export const getStatsStatus = createSelector(playbookStatsState, (state) =>
  get(state, "statsStatus", null)
);

export const isPlaybookStatsLoading = createSelector(
  getStatsStatus,
  (status) => !["completed", "error"].includes(status)
);

export const hasPlaybookStatsError = createSelector(
  getStatsStatus,
  (status) => status === "error"
);

export const hasPlaybookStatsLoaded = createSelector(
  getStatsStatus,
  (status) => status === "completed"
);

//#endregion

//#region HOOKS
export const usePlaybooksStats = () => {
  const playbooksStats = useSelector(getPlaybookStats);

  const isLoading = useSelector(isPlaybookStatsLoading);
  const hasError = useSelector(hasPlaybookStatsError);
  const hasLoaded = useSelector(hasPlaybookStatsLoaded);

  const actions = {
    loadPlaybookStats,
  };

  return [playbooksStats, { isLoading, hasError, hasLoaded }, actions];
};
//#endregion

//#region REDUCER

export const initialState: PlaybookStatsState = {
  // the document statistics for each playbook
  stats: null,
  // status of the stats request: loading, completed, error
  statsStatus: null,
};

export default handleActions(
  {
    [LOAD_PLAYBOOK_STATS]: (state, action) => {
      return handle(state, action, {
        start: (s: PlaybookStatsState): PlaybookStatsState => ({
          ...s,
          statsStatus: "loading",
        }),
        failure: (s: PlaybookStatsState): PlaybookStatsState => ({
          ...s,
          statsStatus: "error",
        }),
        success: (s: PlaybookStatsState): PlaybookStatsState => {
          return {
            ...s,
            statsStatus: "completed",
            stats: get(action, "payload.data", null),
          };
        },
      });
    },
    [RESET_PLAYBOOK_LIST]: () => ({
      ...initialState,
    }),
  },
  initialState
);
//#endregion
