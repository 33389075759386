import React from "react";
import ReactDOM from "react-dom";
import App from "./_App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import constants from "@app/utils/constants";
import { get } from "@app/utils/lodash";
import { createStore } from "@app/redux/store";
import "./index.scss";
import "@app/i18next";
import { getLoginRedirectUrl } from "./_Login/components/Auth/utils";

const { DEFAULT_LOGIN_URL } = constants;

const store = createStore();

// set up axios response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = get(error, "response.status");
    if (statusCode === 401 && window.location.pathname !== DEFAULT_LOGIN_URL) {
      window.location.href = getLoginRedirectUrl();
    } else {
      return Promise.reject(error);
    }
  }
);

const Routes = React.lazy(() => import("./_App/routes"));

ReactDOM.render(
  <React.StrictMode>
    <App store={store}>
      <Routes />
    </App>
  </React.StrictMode>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
