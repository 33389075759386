import { handleActions } from "redux-actions";
import {
  TOGGLE_SELECT_CLAUSE,
  SELECT_CLAUSE,
  DESELECT_CLAUSE,
} from "../actions/types";

export interface PeviousVersionsReduxState {
  selectedClauseId: number;
}

export const initialState: PeviousVersionsReduxState = {
  // id of the selected clause
  selectedClauseId: null,
};

export default handleActions(
  {
    [TOGGLE_SELECT_CLAUSE]: (state: any, action: any) => {
      return {
        ...state,
        selectedClauseId:
          state.selectedClauseId === action.payload ? null : action.payload,
      };
    },
    [SELECT_CLAUSE]: (state, action) => ({
      ...state,
      selectedClauseId: action.payload,
    }),
    [DESELECT_CLAUSE]: (state) => ({
      ...state,
      selectedClauseId: null,
    }),
  },
  initialState
);
