import assembly from "./assembly";
import config from "./config";
import counterparty from "./counterparty";
import search from "./search";
import playbook from "./playbook";
import metadata from "./metadata";
import users from "./users";
import integrations from "./integrations";
import dashboard from "./dashboard";
import preferences from "./preferences";
import orderTables from "./ordertables";
import review from "./review";
import insights from "./insights";
import comments from "./comments";
import ticket from "./ticket";
import settings from "./settings";
import backups from "./backups";

import { combineReducers } from "redux";

export default combineReducers({
  assembly,
  backups,
  comments,
  config,
  counterparty,
  dashboard,
  insights,
  integrations,
  metadata,
  orderTables,
  playbook,
  preferences,
  review,
  search,
  settings,
  ticket,
  users,
});
