import { schema } from "normalizr";

export const playbookSchema = new schema.Entity("playbooks");

export const playbooksSchema = [playbookSchema];

export const contractTypeSchema = new schema.Entity(
  "types",
  {},
  { idAttribute: "type" }
);
export const contractTypesSchema = [contractTypeSchema];

export const fallbacksSchema = new schema.Entity("fallbacks");

export const issuesSchema = new schema.Entity("issues", {
  fallbacks: [fallbacksSchema],
});

export const clausesSchema = new schema.Entity("clauses", {
  issues: [issuesSchema],
});

const clauses = new schema.Array(clausesSchema);
clausesSchema.define({ clauses });

export const playbookSummarySchema = new schema.Entity("playbookSummary", {
  clauses,
});
