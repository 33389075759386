import { Backup, CreateBackupRequest } from "@app/entities/backups";
import { Api, ApiResponse } from "./_base";
import { ISortInfo } from "@app/types/IPageable";
import { get } from "@app/utils/lodash";

export const loadBackups = (
  companyId: number,
  page: number = 0,
  pageSize: number = 50,
  sortInfo?: ISortInfo
): Promise<ApiResponse<any>> => {
  const orderBy = get(sortInfo, "hasSort", false)
    ? `${sortInfo.sortBy} ${sortInfo.isDescending ? "DESC" : "ASC"}`
    : "createdDate DESC";
  return Api.execute({
    url: "/api/backups",
    params: {
      companyId,
      page,
      pageSize,
      orderBy,
    },
  });
};

export const loadBackup = (backupId: string): Promise<ApiResponse<Backup>> => {
  return Api.execute({
    url: `/api/proxy/automator/v1/backup/${backupId}`,
  });
};

export const downloadBackup = (
  backupId: string,
  part: number = 1,
  onDownloadProgress?: (progressEvent: any) => void
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/proxy/automator/v1/backup/${backupId}/download?part=${part}`,
    config: {
      responseType: "blob",
      onDownloadProgress,
    },
  });
};

export const createBackup = (
  request?: CreateBackupRequest
): Promise<ApiResponse<any>> => {
  return Api.execute({
    url: `/api/proxy/automator/v1/backup`,
    method: "POST",
    data: request,
  });
};
