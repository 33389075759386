export enum DataStatus {
  NotLoaded = "not-loaded", // null
  Loading = "loading",
  Error = "error",
  Submitting = "submitting",
  Done = "completed",
}

export const isDataLoading = (status: DataStatus) =>
  status === DataStatus.Loading;
export const hasDataLoaded = (status: DataStatus) => status === DataStatus.Done;
export const hasDataError = (status: DataStatus) => status === DataStatus.Error;
export const isDataSubmitting = (status: DataStatus) =>
  status === DataStatus.Submitting;
export const isDataNotLoaded = (status: DataStatus) =>
  status === DataStatus.NotLoaded;

/**
 * Utility that returns the redux state. Using `require` here is important to load
 * the state at run-time.
 * @returns redux state
 */
export const getState = () => {
  const state = require("@app/redux/store").ReduxStore.store.getState();
  return state || {};
};

/**
 * Utility that runs a redux selector. **Should only be used in special cases where `useSelector`
 * or thunks (that have their own `getState` param) are not available.
 * @param selector
 */
export const runSelector = (selector: any) => selector(getState());
