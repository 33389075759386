import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import {
  UPDATE_APPROVAL_SUMMARY_ACTIVE_CARD_TAB_ID,
  SELECT_CLAUSE,
  DESELECT_CLAUSE,
  TOGGLE_SELECT_CLAUSE,
} from "../actions/types";
import { find, get } from "@app/utils/lodash";
import { isAwaitingApproval } from "@app/entities/document/clause";
import constants from "@app/utils/constants";
import {
  PROCESS_APPROVAL,
  RESET_APPROVALS_STATE,
} from "@app/redux/data/review/approvals";
const { APPROVALS_TABS } = constants;

export interface ApprovalSummaryReduxState {
  activeCardTabId: string;
  selectedClauseId: number;
  processingAction: string;
}

export const initialState: ApprovalSummaryReduxState = {
  // the active tab in the card header
  activeCardTabId: null,
  // selected clause id
  selectedClauseId: null,
  // processing action
  processingAction: null,
};

const handleProcessApproval = (state, action: any) => {
  return handle(state, action, {
    start: (s) => ({
      ...s,
      processingAction: get(action, "meta.action"),
    }),
    failure: (s) => ({
      ...s,
      selectedClauseId: null,
      processingAction: null,
    }),
    success: (s) => ({
      ...s,
      selectedClauseId: null,
      processingAction: null,
    }),
  });
};

export default handleActions(
  {
    [UPDATE_APPROVAL_SUMMARY_ACTIVE_CARD_TAB_ID]: (state: any, action) => ({
      ...state,
      activeCardTabId: action.payload,
    }),
    [TOGGLE_SELECT_CLAUSE]: (state: any, action) => ({
      ...state,
      selectedClauseId:
        action.payload === state.selectedClauseId ? null : action.payload,
    }),
    [SELECT_CLAUSE]: (state, action) => {
      const approval = find(
        state.approvals,
        (approval) => approval.issueId === action.payload
      );
      return {
        ...state,
        selectedClauseId: action.payload,
        activeCardTabId: isAwaitingApproval(approval)
          ? APPROVALS_TABS.AWAITING_APPROVAL
          : APPROVALS_TABS.PROCESSED,
      };
    },
    [DESELECT_CLAUSE]: (state) => ({
      ...state,
      selectedClauseId: null,
    }),
    [PROCESS_APPROVAL]: handleProcessApproval,
    [RESET_APPROVALS_STATE]: () => ({ ...initialState }),
  },
  initialState
);
